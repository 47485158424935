.carousel-block {
  display: flex;
  flex-direction: column-reverse;

  &__content {
    margin-top: $spacing--s;

    @include breakpoint-m {
      display: flex;
      margin-top: $spacing--sm;
    }
  }

  &__title {
    line-height: 1.3;
    @include breakpoint-m {
      width: 52.3%; // NOTE: Magic number to align the right column with the carousel count text
      flex-shrink: 0;
    }

    @include breakpoint-l {
      padding-right: 20%;
    }
  }

  &__text {
    margin-top: $spacing--s;

    @include breakpoint-m {
      margin-top: 0;
    }
  }

  &__carousel {
    @include breakpoint-s {
      padding-left: $spacing--l;
    }

    @include breakpoint-m {
      padding-left: $spacing--xxl;
    }
  }
}
