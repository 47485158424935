.contact-block {
  background-color: $color--beige;
  padding-top: $spacing--s;
  padding-bottom: $spacing--s;

  @include breakpoint-s {
    padding-top: $spacing--l;
    padding-bottom: $spacing--l;
  }

  &__form {
    margin-top: $spacing--m;
  }

  &__contacts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include breakpoint-m {
      display: block;
      text-align: right;
    }
  }

  &__card {
    width: 100%;
    margin-bottom: $spacing--m;

    &:last-child(2) {
      margin-bottom: 0;
    }

    @include breakpoint-m {
      width: 70%;
      text-align: left;
      margin-bottom: $spacing--l;
    }
  }
}
