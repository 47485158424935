.two-images-block {
  @include breakpoint-m {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
  figcaption {
    width: 100%;
  }
}
