@import '../../scss/shared-styles/modal';

.news-modal {
  $self: &;

  .ReactModalPortal &__modal-overlay {
    @include portal-overlay;
    padding: 90px $spacing--s $spacing--s;
    justify-content: flex-start;

    @include breakpoint-m {
      padding: 10px;
    }

    @include breakpoint-m {
      justify-content: center;
    }

    #{$self}__modal {
      @include modal;
      outline: none;

      @include breakpoint-s-max {
        overflow-y: auto;
      }
    }
  }

  #{$self}__modal &__modal-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding: $spacing--l $spacing--s $spacing--s;

    @include breakpoint-s {
      padding: $spacing--xl $spacing--sm $spacing--m;
    }
  }

  &__close-button {
    @include modal-close-button;

    &-icon {
      @include modal-close-button-icon;
    }

    @include underline-animation(expand, '.news-modal__close-button-text');


    &-text {
      @include responsive-font-size(16px, 20px);
      color: $color--slate;
      margin-right: $spacing--xs;
      align-self: center;
    }
  }

  &__heading {
    @include responsive-font-size(30px, 50px);
    line-height: 1.23;
    font-weight: 300;
    color: $color--green-dark;
    margin: 0 0 $spacing--s;

    @include breakpoint-s {
      text-align: center;
      margin-bottom: $spacing--sm;
    }
  }

  &__image {
    max-height: 273px;
    max-width: 100%;
    display: block;
    margin: 0 auto $spacing--m;
  }

  &__intro {
    font-size: convert-to-rem(20px);
    line-height: 1.5;
    color: $color--green-dark;
    font-weight: 300;
    margin: 0 0 $spacing--m;

    @include breakpoint-s {
      color: $color--black;
      text-align: center;
      margin-bottom: $spacing--m;
    }
  }

  &__button-container {
    display: flex;
    justify-content: center;
  }

  &__button {
    border: none;
    border-radius: 20px;
    text-align: center;
    padding: 0 20px;
    min-height: 40px;
    min-width: 100px;
    font-size: convert-to-rem(16px);
    letter-spacing: 1px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: $font-family;
    cursor: pointer;
    @include underline-animation(expand, '.news-modal__button-text');
    display: inline-flex;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    background: $color--green;
    color: white;

    @include breakpoint-s {
      padding: 0 30px;
    }

    &-text {
      align-self: center;
    }
  }
}