.housing-project-contact-form {
  $self: &;

  @include housing-project-block-wrapper;
  &--dark,
  &--green {
    color: $color--beige-dark;
  }

  &__inputs {
    max-width: 600px;
    margin: auto;
  }

  &__button-container {
    display: flex;
    justify-content: center;
    margin-top: $spacing--xs;
  }

  &__button {
    width: fit-content !important;
  }

  &__error-text {
    color: $color--red-dark;
    margin-bottom: $spacing--xs;
  }

  &__checkbox {
    .checkbox__text-container {
      padding-top: 0;
    }
    .checkbox__icon-container {
      width: 20px;
      height: 20px;
      flex: 0 0 20px;
      margin-top: 5px;
    }
    #{$self}--dark & {
      .rich-text {
        color: $color--beige-dark;
      }
      .checkbox__icon-container {
        border-color: $color--beige-dark;
      }
    }
    #{$self}--green & {
      .rich-text {
        color: $color--beige-dark;
      }
      .checkbox__icon-container {
        border-color: $color--beige-dark;
      }
    }
    #{$self}--light & {
      .rich-text {
        color: $color--black;
      }
      .checkbox__icon-container {
        border-color: $color--black;
      }
    }
  }
}
