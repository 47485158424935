.footer {
  background-color: $color--green-dark;
  color: $color--beige;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: $spacing--xl;
  padding-top: $spacing--m;
  padding-bottom: $spacing--xl;

  @include breakpoint-s {
    padding-top: $spacing--l;
    padding-bottom: $spacing--xxl;
  }

  &__content {
    width: convert-to-rem($content-max-width);
  }

  &__logo-container {
    padding-bottom: $spacing--l;
  }

  &__menu,
  &__contacts,
  &__social-media-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include breakpoint-s-max {
      display: block;
    }
  }

  &__contacts-container {
    display: flex;

    @include breakpoint-m-max {
      display: block;
    }

    @include breakpoint-s-max {
      padding: 0;
    }
  }

  &__contacts {
    padding-bottom: $spacing--l;
    width: 50%;
    @include breakpoint-m-max {
      width: 100%;
      padding-bottom: $spacing--m;

      & :last-child {
        padding-bottom: 0;
      }
    }
  }

  &__contact-form-button {
    @include button-style-reset;
    @include responsive-font-size(23px, 25px);
    @include underline-animation('shrink', '.footer__contact-form-button-span');
    padding: 0;
    background: none;
    color: $color--yellow;
    padding-top: $spacing--s;
    display: flex;
  }

  &__contact-form-container {
    z-index: 99;
    position: fixed;
    color: $color--black;
  }

  &__contact-item {
    @include responsive-font-size(23px, 25px);
    display: flex;
    flex-direction: column;
  }

  &__contact {
    margin: 0;
    list-style: none;
    padding-bottom: $spacing--m;
    width: 50%;

    &:last-child {
      padding-bottom: 0;
    }

    @include breakpoint-s-max {
      width: 100%;
    }

    &-text {
      font-size: convert-to-rem(18px);
    }
    &-title {
      padding-bottom: $spacing--xs;
    }
  }

  &__social-media {
    width: 50%;
  }

  &__social-media-container {
    width: 50%;
    justify-content: flex-end;
    padding-bottom: $spacing--s;

    @include breakpoint-m-max {
      display: block;
      width: 100%;
    }
  }

  &__social-media-icons {
    padding-top: $spacing--s;
    display: flex;
  }

  &__housing-project {
    margin-top: 0;
  }
}
