.menu {
  $self: &;

  width: 100%;
  display: flex;
  justify-content: center;
  background: $color--yellow;
  color: $color--green-dark;
  font-size: convert-to-rem(16px);
  height: calc(100dvh - 4rem);
  overflow-y: auto;

  @include breakpoint-s {
    height: calc(100dvh - 5.5rem);
  }

  @include breakpoint-mm {
    min-height: 384px;
    height: auto;
    max-height: calc(100dvh - 5.5rem);
  }

  &__container {
    position: relative;
    width: $content-max-width;

    @include breakpoint-mm {
      padding-top: 5rem;
    }
  }

  &__close-button {
    @include underline-animation(expand, '.menu__close-button-text');
    display: none;
    font-family: $font-family;

    @include breakpoint-mm {
      position: absolute;
      top: 2rem;
      right: 60px;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      background-color: transparent;
      border: none;
      padding: 0;
      @include font-size(16px);
      font-weight: 400;
      line-height: 1.25;
    }

    @include breakpoint-m {
      right: 120px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__close-button-icon {
    width: 13px;
    height: 13px;
  }

  &__login-links {
    display: block;
    padding: 2rem 1.5rem 2rem 2.125rem;
    margin: 0;

    @include breakpoint-s {
      padding: 2rem 3rem;
    }
    @include breakpoint-mm {
      display: none;
    }
  }

  &__content {
    padding: 0 20px 2rem 20px;

    @include breakpoint-s {
      padding: 0 60px 2rem 60px;
    }

    @include breakpoint-l {
      padding: 0 120px 2rem 120px;
    }
  }

  &__group-container {
    visibility: visible;
    height: auto;
    display: block;

    @include breakpoint-mm {
      visibility: hidden;
      height: 0;
      display: none;
    }

    &--active {
      @include breakpoint-mm {
        visibility: visible;
        height: auto;
        display: block;
      }
    }
  }

  &__groups {
    padding: 0 10px;
    margin: 0;
  }
}
