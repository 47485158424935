.global-warning-block {
  $self: &;

  background: $color--yellow-strong;
  position: relative;
  display: none;
  max-height: 100dvh;
  overflow: auto;

  &--active {
    display: block;
  }

  &--placeholder {
    visibility: hidden;
  }

  &__content-wrapper {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: $spacing--s;
    height: 100%;
    max-width: $layout-max-width;
    padding: 24px 24px;

    @include breakpoint-s {
      padding: 24px 32px;
    }
  }

  &__text-wrapper {
    display: flex;
    align-items: center;
    height: fit-content;
  }

  &__button-wrapper {

  }

  &__button {
    color: black;
    border: none;
    height: fit-content;
    text-decoration: none;
    @include underline-animation(expand, '.global-warning-block__button__text');
    font-family: $font-family;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: transparent;
    padding: 0;
    @include font-size(16px);
    font-weight: 400;
    line-height: 1.25;

    &:hover {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }

    &__text {
      display: none;

      @include breakpoint-s {
        display: block;
      }
    }

    &__icon {
      width: 13px;
      height: 13px;
    }
  }
}
