.housing-project-image-with-text {
  $self: &;
  @include housing-project-block-wrapper;

  &--dark,
  &--green {
    color: $color--beige;
  }
  &__content {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    #{$self}--dark &,
    #{$self}--green & {
      background-color: black;
    }

    #{$self}--light &,
    .article-page__blocks &{
      background-color: $color--beige;
    }

    @include breakpoint-m {
      flex-direction: row-reverse;

      #{$self}--left & {
        flex-direction: row;
      }
    }
  }

  &__text-container {
    text-align: center;
    margin-bottom: $spacing--m;
    @include breakpoint-m {
      width: 50%;
      margin-bottom: 0;
    }
  }

  &__title,
  &__text,
  &__link {
    margin: 0 $spacing--m;
  }

  &__title {
    margin-top: $spacing--s;

    #{$self}--dark &,
    #{$self}--green & {
      color: $color--beige;
    }

    #{$self}--light & {
      color: $color--black;
    }

    @include breakpoint-m {
      margin-top: 0;
    }
  }

  &__link {
    width: fit-content;
    margin: auto;
    margin-top: $spacing--sm;
  }

  &__text {
    margin-top: $spacing--xs;
    line-height: 1.4;
  }

  &__image-container {
    width: 100%;
    @include breakpoint-m {
      width: 50%;
    }
  }
}
