.rich-text {
  $self: &;
  @include responsive-font-size(16px, 18px);
  line-height: 1.83;
  word-break: break-word;

  color: $color--green-dark;

  &--narrow {
    @include breakpoint-m {
      max-width: 50%;
    }
  }

  &--compact {
    p {
      margin: 0;
      line-height: 1.4;
    }
  }

  &--bigger-text {
    @include responsive-font-size(16px, 20px);
  }

  a {
    color: inherit;
  }

  &--red-links {
    a {
      color: $color--red;
    }
  }

  > h2,
  > h3,
  > h4,
  > h5,
  > h6,
  > p,
  > ul,
  > ol,
  > table {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    padding-left: $spacing--s;

    li {
      line-height: 1.55;
      padding-bottom: $spacing--xs;
    }
  }

  h2 {
    @include responsive-font-size(17px, 20px);
    line-height: 1.55;
    font-weight: 500;
    margin: 2em 0 1em;
  }

  h3 {
    @include responsive-font-size(20px, 25px);
    line-height: 1.6;
    font-weight: 200;
    margin: 2em 0 0;
  }

  h4 {
    @include responsive-font-size(17px, 18px);
    line-height: 1.55;
    font-weight: 500;
    margin: 2em 0 1em;
  }

  h5 {
    @include responsive-font-size(14px, 16px);
    font-family: 'National2Condensed';
    line-height: 1.4;
    letter-spacing: 0.125em;
    font-weight: 500;
    text-transform: uppercase;
    margin: 2em 0 1em;
  }

  h6 {
    @include responsive-font-size(13px, 16px);
    font-weight: normal;
    line-height: 1.4;
    text-transform: uppercase;
    letter-spacing: 0.0625em;
    margin: 2em 0 1em;
  }

  img {
    height: 100%;
    max-width: 100%;
  }

  &--footer {
    color: white;
  }

  &--footer,

  &--expander-list {
    p {
      line-height: 1.83;
    }
  }

  &--housing-project-faq-block,
  &--housing-project-contact-form,
  &--housing-project-image-with-text {
    p {
      line-height: 1.83;
    }

    a {
      padding-bottom: 2px;
      text-decoration: none;
      background-image: linear-gradient(currentColor, currentColor);
      background-repeat: no-repeat;
      transition: background-size 0.5s;
      background-position: 0% 100%;
      background-size: 100% 1px;

      &:hover,
      &:focus {
        background-size: 0% 1px;
      }
    }
  }

  &--housing-project-image-with-text,
  &#{&}--inspo-full-width-media-intro-block {
    color: inherit;
  }

  &--housing-project-faq-block,
  &--housing-project-image-with-text {
    a {
      background-image: linear-gradient($color--grey-green, $color--grey-green);
    }
  }

  &--housing-project-image-with-text {
    p {
      font-size: convert-to-rem(18px);
      line-height: convert-to-rem(25px);
    }
  }

  &--housing-project-contact-form {
    font-size: convert-to-rem(16px);

    p {
      line-height: convert-to-rem(22px);
    }
  }

  &--in-housing-project-scrolling-images,
  &--in-housing-project-teaser-block {
    font-size: convert-to-rem(20px);
    line-height: convert-to-rem(28px);
    font-weight: 200;
  }

  &--event-header {
    margin-top: $spacing--l;
  }

  &--in-carousel-teaser {
    color: inherit;
    line-height: 1.3;

    * {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  &--global-warning-block {
    p {
      line-height: 1.4;
      @include breakpoint-s {
        line-height: 1.83;
      }
    }
  }
}
