.glossary-page {
  &__content {
    margin-top: $spacing--l;
  }

  &__text,
  &__link {
    margin-top: $spacing--s;
  }

  &__main {
    @include breakpoint-m {
      padding-right: $spacing--l;
    }
  }

  &__blocks {
    margin-top: $spacing--xxl;
  }
}
