.dropdown-filter {
  position: relative;
  width: 100%;
  padding: 0;
  cursor: pointer;
  $self: &;

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &--active {
    z-index: 2;
  }

  &__select {
    @include responsive-font-size(16px, 18px);
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: fade-in 0s 1s both;
  }

  &--is-mounted {
    select {
      z-index: 0;
      opacity: 0;
      animation-fill-mode: none;
    }
  }

  &--has-touch {
    select {
      z-index: 2;
    }
  }

  &--has-search {
    select {
      pointer-events: none;
    }
  }

  &--is-disabled {
    opacity: 0.5;
  }

  &__fake,
  &__element {
    color: $color--green-dark;
    font-weight: normal;
    @include responsive-font-size(16px, 18px);
  }

  &__fake {
    position: relative;
    user-select: none;
  }

  &__element {
    overflow: hidden;
    background-color: white;
    border: 1px solid $color--grey-green;
    box-sizing: border-box;
    cursor: pointer;
    padding: 10px 10px 10px 13px;
    line-height: 18px;
    min-height: 40px;
    font-weight: 500;
    display: flex;

    @include breakpoint-s {
      font-weight: normal;
      padding-left: 15px;
    }

    #{$self}--has-one-option & {
      cursor: default;
    }

    #{$self}--error & {
      border-color: $color--red-dark;
      color: $color--red-dark;
    }

    #{$self}--active & {
      border-bottom: none;
      background-color: $color--beige-light;
    }

    #{$self}--grey-bg & {
      background-color: $color--beige-light;
    }

    &-text{
      flex: 0 0 calc(100% - 50px);
      max-width: calc(100% - 50px);
    }
  }

  &__dropdown {
    list-style: none;
    position: absolute;
    width: 100%;
    margin-top: -1px;
    background-color: white;
    border-top: none;
    border: 1px solid $color--grey-green;
    border-radius: 0;
    padding: 0;
    font-size: inherit;
    max-height: 200px;
    overflow: auto;

    #{$self}--has-search & {
      margin-top: 43px;
      max-height: 220px;
    }

    #{$self}--in-portal-dinner-order-form & {
      background: linear-gradient(
        to left,
        $color--beige-light 1px,
        $color--beige-light 100%
      );
    }

    #{$self}--active & {
      border-top: none;
      background-color: $color--beige-light;
    }

    #{$self}--grey-bg & {
      background-color: $color--beige-light;
    }
  }

  &__option {
    cursor: pointer;
    font-weight: normal;
    padding-left: 6px;
    padding-right: 6px;
    color: $color--green-dark;

    &:first-child {
      #{$self}__option-text {
        border-top: 1px solid #d5d7d7;
      }
    }
    &:last-child {
      border: none;
    }

    &:hover,
    &:focus {
      &:first-child {
        #{$self}__option-text {
          border-top: 1px solid $color--slate;
        }
      }
      #{$self}__option-text {
        border-bottom: 1px solid $color--slate;
      }

      background-color: $color--slate;
      color: white;
    }
  }

  &__option-text {
    display: block;
    padding: 10px 4px 10px 9px;
    border-bottom: 1px solid #d5d7d7;
  }

  &__icon {
    transition: transform 0.4s ease-in-out;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    border: 2px solid $color--green-dark;
    border-top: none;
    border-left: none;
    transition: all 0.3s linear;
    margin-left: auto;
    margin-top: 2px;
    margin-right: 5px;
    transform: rotate(45deg);

    &--active {
      transform: rotate(225deg);
      margin-top: 5px;
    }

    #{$self}--error & {
      color: inherit;
    }
  }

  #{$self}__select:focus + #{$self}__fake #{$self}__element {
    background-color: rgba($color--grey-green, 0.1);

    .mouse-user * {
      background: none;
    }
  }

  &__search {
    border: none;
    font-size: 18px;
    font-weight: 600;
    color: $color--slate;
    margin-left: $spacing--s;
    background-color: transparent;
    margin-top: 2px;
  }

  &__search-container {
    width: 100%;
    display: flex;
    height: convert-to-rem(44px);
    border: 1px solid $color--grey-green;
    background-color: white;
    position: absolute;
    z-index: 99;
  }

  &__search-icon {
    margin-left: 16px;
  }
}
