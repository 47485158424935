.logo {
  width: $logo-width;
  height: convert-to-rem(40px);
  background-repeat: no-repeat;
  background-image: url('../../assets/icons/usbl_logo_multi.svg');

  &--white {
    background-image: url('../../assets/icons/usbl_logo_hvit.svg');
  }

  &--menu {
    background-image: url('../../assets/icons/usbl_logo_hvit.svg');

    width: $logo-width;
    height: convert-to-rem(26px);

    @include breakpoint-mm {
      width: $logo-width;
      height: convert-to-rem(40px);
    }
  }
}
