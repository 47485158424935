.map {
  height: 80vw;
  max-height: calc($content-max-width / 2);

  @include breakpoint-s {
    height: 60vw;
  }

  @include breakpoint-m {
    height: 40vw;
  }
  &-title {
    margin-bottom: $spacing--m;
  }
}
