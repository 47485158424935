.image-list-block {
  position: relative;

  @include breakpoint-m {
    display: flex;
    flex-wrap: wrap;
  }

  &__text {
    margin-top: $spacing--s;

    @include breakpoint-s-max {
      margin-bottom: $spacing--m !important;
    }
  }

  &__item {
    @include responsive-margin($spacing--s, $spacing--l, $spacing--xxl);

    &:nth-child(3n + 2) {
      .image-list-block__image {
        padding-top: calc((12 / 9) * 100%);
      }
    }

    &:nth-child(3n + 3) {
      .image-list-block__image {
        padding-top: calc((9 / 16) * 100%);
      }
    }

    @include breakpoint-m {
      &:nth-child(1),
      &:nth-child(2) {
        margin-top: 0;
      }

      &:nth-child(3n + 1) {
        width: 60%;
        padding-right: $spacing--xxl;
        padding-top: 20%;

        &:last-child {
          padding-top: 0;
        }
      }

      &:nth-child(3n + 2) {
        width: 40%;
        padding-right: $spacing--xxl;
        margin-left: auto;
      }

      &:nth-child(3n + 3) {
        width: 70%;
        margin-left: auto;
        margin-top: $spacing--xxl;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    padding-top: calc((3 / 4) * 100%);
  }
}
