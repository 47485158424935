.carousel-teaser-block {
    $self: &;
    width: 100%;
    color: black;
    position: relative;

    &--red {
        background-color: $color--red-light;
    }
    &--yellow {
        background-color: $color--yellow;
        color: $color--green-dark;
    }
    &--green {
        background-color: $color--green-dark;
        color: $color--yellow;
    }

    &--no-image{
        padding-top: 90px;
        padding-bottom: 90px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__image {
        &-container {
            position: relative;
            width: 100%;

            &:after {
                content: "";
                display: block;
                width: 100%;
                padding-top: 55.7%;
            }
        }
    }

    &__text-container{
        padding: 15px 20px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__link {
        display: inline-flex;
        @include btn-tertiary;
        min-height: 40px;
        height: auto;
        padding: 10px 37px;
        line-height: 20px;
        width: auto;
        justify-content: center;
        text-decoration: none;
        text-align: center;
        background: none;
        color: inherit;
        border-color: currentColor;

        @include underline-animation(expand, ".carousel-teaser-block__link-text");

        &:hover {
            border-color: currentColor;
            background: none;
            text-decoration: none;
        }

        #{$self}--red & {
            border-color: $color--red-strong;

            &:hover {
                border-color: $color--red-strong;
            }
        }
    }

    #{$self}__title,
    #{$self}__text,
    #{$self}__link{
        color: inherit;
        text-align: center;
        margin-bottom: 15px;
        align-self: center;
    }

    #{$self}__title{
        line-height: 1;
    }

    #{$self}__link{
        color: inherit !important;
    }
    
}
