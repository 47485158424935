@mixin modal {
  align-self: center;
  background-color: #fff;
  width: 100%;
  max-width: $dialog-width;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.3);
  max-height: 100%;
  overflow-y: auto;
  position: relative;
  word-break: break-word;
}

@mixin portal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
}

@mixin modal-close-button {
  border: none;
  background: none;
  position: absolute;
  top: 24px;
  right: 20px;
  z-index: 100;
  cursor: pointer;
  margin: 0;
  padding: 0;
  color: inherit;
  display: flex;
  flex-wrap: nowrap;
}


@mixin modal-close-button-icon {
  width: 15px;
  height: 15px;

  @include breakpoint-s {
    margin-top: 3px;
  }

  @include breakpoint-m {
    margin-top: -1px;
    width: 24px;
    height: 24px;
  }
}

@mixin modal-close-button-text {
  @include responsive-font-size(16px, 20px);
  color: $color--slate;
  margin-right: $spacing--xs;
  align-self: center;
}