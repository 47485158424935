.page-list {
  &__heading {
    margin: 0 0 $spacing--l 0;
    @include breakpoint-s-max {
      margin: 0 0 $spacing--s 0;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
  }

  &__missing-text {
    margin-bottom: $spacing--l;
  }

  &__item-container {
    width: 45%;
    justify-content: center;
    color: $color--black;
    text-decoration: none;
    margin-right: 5%;
    margin-bottom: $spacing--xl;

    @include breakpoint-l {
      width: 30%;
      &:nth-child(3n) {
        margin-right: 0rem;
      }
    }

    @include breakpoint-s-max {
      display: block;
      width: 100%;
      margin-right: 0;
    }
  }

  &__item {
    @include underline-animation(expand, '.page-list__item-heading-span');
    width: 100%;
    cursor: pointer;
    margin-bottom: $spacing--l;

    &-image {
      padding-bottom: calc((225 / 325) * 100%);
    }

    &-image-container {
      position: relative;

      &--border {
        border-bottom: 1px solid #cdcdcd;
        border-top: 1px solid #cdcdcd;
      }
    }

    &-published {
      font-size: convert-to-rem(16px);
      color: $color--slate;
      font-family: $font-family-secondary;
      font-weight: 500;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin-top: $spacing--s;
    }

    &-heading {
      color: $color--red;
      margin-top: $spacing--s;
      margin-bottom: 0;
      text-decoration: none;
    }

    &-text {
      margin-top: $spacing--xs;
      line-height: 1.35 !important;
    }

    &-tag {
      font-size: convert-to-rem(14px);
      font-family: $font-family-secondary;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.0625em;
      position: absolute;
      right: 20px;
      top: -25px;
      z-index: 1;
      width: 100px;
      height: 100px;
      padding: $spacing--xs;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;

      &--red {
        background-color: $color--red;
        color: white;
      }

      &--yellow {
        background-color: $color--yellow-strong;
        color: $color--green-dark;
      }

      &--green {
        background-color: $color--green-dark;
        color: white;
      }
    }

    &-bonus-link {
      margin-top: -30px;
    }
  }
}
