.frontpage-header {
  $self: &;

  width: 100%;
  background-color: $color--green-dark;
  display: flex;
  flex-direction: column-reverse;

  @include breakpoint-mm {
    min-height: 512px;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__left,
  &__right {
    width: 100%;
    position: relative;
    height: 100%;
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @include breakpoint-mm {
      width: 45%;
      min-height: 512px;
      align-items: flex-end;
      padding: 6rem 0;
    }
  }

  &__right {
    min-height: 500px;
    @include breakpoint-mm {
      height: auto;
      width: 55%;
    }

    @include breakpoint-mm {
      width: calc(0.55 * $content-max-width + (100vw - $content-max-width) / 2);
    }
  }

  &__text-container {
    max-width: calc($layout-max-width * 0.45);
    padding: 32px 16px;
    word-break: break-word;

    @include breakpoint-mm {
      padding: 0 50px 0 60px;
      margin-bottom: 100px;
    }

    @include breakpoint-xm {
      padding: 0 110px 0 123px;
    }

    @include breakpoint-l {
      padding: 0 110px 0 160px;
    }
  }

  &__link {
    margin-top: 30px;

    @include breakpoint-mm {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  &__login-link {
    margin-top: 30px;
    color: $color--yellow;

    @include breakpoint-mm {
      display: none;
    }
  }

  &__title,
  &__title-secondary {
    color: $color--yellow;
    font-weight: 350;
    font-size: convert-to-rem(30px);
    margin: 0;
    line-height: 1.2;

    @include breakpoint-mm {
      font-size: convert-to-rem(50px);
    }
  }

  &__title-secondary {
    font-weight: 500;
    margin-top: 0.8em;

    @include breakpoint-mm {
      margin-top: 0.8em;
    }
  }

  &--color-yellow {
    background-color: $color--yellow;
  }

  &--color-green-dark {
    background-color: $color--green-dark;
  }

  &--color-pink {
    background-color: $color--pink-light;
  }

  &--color-orange {
    background-color: $color--orange-light;
  }
}
