.frontpage {
  &__links {
    margin: 20px 0 0 0;
    @include breakpoint-ms {
      margin: $spacing--m 0 0 0;
    }
  }

  &__blocks {
    @include responsive-margin($spacing--l, $spacing--l);
  }
}
