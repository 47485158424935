.layout {
  $self: &;
  width: 100%;
  min-height: 50vh;
  padding-top: 64px;

  @include breakpoint-s {
    padding-top: 88px;
  }
  @include breakpoint-mm {
    padding-top: 130px;
  }
  @include breakpoint-l {
    padding-top: 80px;
  }

  &--no-min-height {
    min-height: 0;
  }

  &__content {
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: convert-to-rem($content-max-width);

    #{$self}--housing-project & {
      max-width: none;
    }
  }

  &__buttons {
    position: absolute;
    right: 0;
  }
}

:root {
  --reach-skip-nav: 1;
}

[data-reach-skip-link] {
  @include visually-hidden;
}

[data-reach-skip-link]:focus {
  left: auto;
  top: auto;
  width: 30%;
  z-index: 999;
  opacity: 1;
  clip: auto;
  margin: 10px 35%;
  text-align: center;
  font-size: 1.2em;
  color: $color--black;
  padding: convert-to-rem(15px);
  background-color: $color--orange;
  text-transform: uppercase;
  text-decoration: none;
}
