.housing-project-list-block {
  $self: &;
  @include housing-project-block-wrapper;
  &--dark,
  &--green {
    color: $color--beige-dark;
  }

  &__plx-wrapper {
    width: auto !important;
    position: relative !important;

    div {
      width: calc(min(90%, 100% - 40px));
    }
  }

  &__items {
    list-style: none;
    display: block;
    padding: 0;
    margin-top: $spacing--m;
    margin-bottom: 0;

    @include breakpoint-ms {
      display: grid;
      grid-template-columns: auto auto auto auto;
      grid-column-gap: $spacing--m;
      grid-row-gap: $spacing--l;
      margin-top: $spacing--l;
    }

    &--2 {
      grid-template-columns: auto auto;
    }

    &--3,
    &--6 {
      grid-template-columns: auto auto auto;
    }

    &--5 {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  &__item {
    margin-bottom: $spacing--m;
    &:last-of-type {
      margin-bottom: 0;
    }
    @include breakpoint-ms {
      margin-bottom: 0;
    }
    &--5 {
      grid-column: span 2;

      &:nth-last-child(2) {
        grid-column: 2 / 4;
      }

      &:last-child {
        grid-column: 4 / 6;
      }
    }

    &-title {
      margin: $spacing--s 0 $spacing--xs 0;

      @include breakpoint-sm {
        margin: $spacing--sm 0 $spacing--xs 0;
      }
      .heading{
        font-size: convert-to-rem(25px);
        line-height: convert-to-rem(30px);
      }
    }

    &-text {
      margin-bottom: $spacing--xs;
      font-weight: 200;
      font-size: convert-to-rem(18px);
      line-height: convert-to-rem(25px);

      @include breakpoint-sm {
        margin-bottom: $spacing--s;
      }
      @include breakpoint-ms {
        font-size: convert-to-rem(20px);
        line-height: convert-to-rem(28px);
      }
      #{$self}--dark &,
      #{$self}--green & {
        color: $color--grey-green;
      }
    }

    &-link {
      .link__text{
        font-size: convert-to-rem(18px);
        line-height: convert-to-rem(25px);
      }
      @include breakpoint-ms {
        .link__text{
          font-size: convert-to-rem(20px);
          line-height: convert-to-rem(28px);
        }
      }


      #{$self}--dark & {
        color: $color--grey-green;
        .link__text {
          background-image: linear-gradient($color--grey, $color--grey);
        }
      }
      #{$self}--green & {
        color: $color--housing-project-green;
      }
      #{$self}--light & {
        color: $color--black;
        .link__text {
          background-image: linear-gradient(
            $color--grey-green,
            $color--grey-green
          );
        }
      }
    }
  }
}
