.corner-wrapper {
  position: relative;
  padding-left: $corner-width-s;
  padding-top: $corner-width-s;
  padding-right: $spacing--s;

  @include breakpoint-m {
    padding-left: $corner-width-l;
    padding-top: $corner-width-l;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color--beige;
    width: 60px * 3;
    height: 60px * 3;

    @include breakpoint-m {
      padding-top: 0;
      height: $corner-width-l * 3;
      width: $corner-width-l * 3;
    }
  }

  &--right {
    padding-right: $spacing--l;
    padding-left: $spacing--s;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: auto;
      right: 0;
    }
    @include breakpoint-m {
      padding-right: $spacing--xxl;
    }
  }

  &--right-small,
  &--left-small {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: auto;
      right: 0;
      width: 123px;
      height: 123px;
    }

    @include breakpoint-l {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: auto;
        right: 0;
        width: 183px;
        height: 183px;
      }
    }
  }

  &--right-small {
    padding-top: 40px;
    padding-left: 0;
    padding-right: 40px;

    @include breakpoint-l {
      padding-top: 60px;
      padding-left: 0;
      padding-right: 60px;
    }
  }

  &--left-small {
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 0;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
    }

    @include breakpoint-l {
      padding-top: 60px;
      padding-left: 60px;
      padding-right: 0;
    }
  }

  &--orange {
    &:before {
      background-color: $color--orange;
    }
  }

  &--blue {
    &:before {
      background-color: $color--aqua;
    }
  }

  &--pink {
    &:before {
      background-color: $color--pink;
    }
  }
}
