.link-list {
  &--big {
    @include responsive-font-size(23px, 25px);
    line-height: 1.5;

    li {
      &:not(:last-child) {
        @include breakpoint-s {
          margin-bottom: $spacing--s;
        }
      }
    }
  }

  &--columns {
    ul {
      @include breakpoint-s {
        column-count: 2;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: $spacing--s 0 0 0;
  }

  li {
    &:not(:last-child) {
      margin-bottom: $spacing--xs;
    }
  }
}
