.quote {
    $self: &;

    position: relative;
    margin-left: 0;

    &__text {
        font-weight: 350;
        margin-bottom: 24px;
    }

    &__author {
        font-weight: 400;
        line-height: 25px;
    }

    .rich-text {
        color: $color--black;
    }

    p {
        color: $color--black;
    }

    &--green {
        .rich-text {
            color: white;
        }

        p {
            color: white;
        }

        background-color: $color--slate;
    }

    &--beige {
        background-color: $color--beige;
    }

    &--yellow {
        background-color: $color--yellow;
    }

    &--half {
        padding: 32px;
        width: 100%;

        @include breakpoint-sm {
            width: 50%;
        }

        & img {
            display: none;
        }

        &:before {
            content: '';
            border-top: 15px solid $color--slate;
            border-left: 15px solid $color--slate;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 45px;
            height: 45px;
        }

        &#{$self}--green:before {
            border-top: 15px solid $color--yellow;
            border-left: 15px solid $color--yellow;
        }

        &#{$self}--yellow:before {
            border-top: 15px solid $color--dark-orange;
            border-left: 15px solid $color--dark-orange;
        }
    }

    &--full {
        display: grid;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 0;
        padding-right: 28px;
        width: 100%;

        &#{$self}--green {
            border-image: conic-gradient($color--slate 0 0) fill 0/0/0 100vw
        }

        &#{$self}--beige {
            border-image: conic-gradient($color--beige 0 0) fill 0/0/0 100vw
        }

        &#{$self}--yellow {
            border-image: conic-gradient($color--yellow 0 0) fill 0/0/0 100vw
        }

        & #{$self}__text {
            grid-column: span 2;
            grid-row: 1;
        }

        & #{$self}__author {
            grid-column: 1;
            grid-row: 2;
            align-content: center;
        }

        & #{$self}__headshot {
            grid-column: 2;
            grid-row: 2;
        }

        @include breakpoint-sm {
            padding-top: 54px;
            padding-right: 20px;
            padding-bottom: 54px;
            grid-template-columns: 50% auto;

            & #{$self}__text {
                grid-column: 1;
                grid-row: 1;
            }

            & #{$self}__headshot {
                grid-row: span 2;
                margin-left: 80px;
            }
        }
    }

    & img {
        border-radius: 50%;
        width: 114px;
        height: 114px;
        max-width: none;

        @include breakpoint-sm {
            width: 199px;
            height: 199px;
        }
    }
}