.expander-list {
  $self: &;

  &--narrow {
    @include breakpoint-m {
      max-width: 50%;
    }
  }

  &--beige {
    #{$self}__item-text {
      padding-bottom: 40px;
    }
  }

  &__title {
    padding-bottom: $spacing--s;

    @include breakpoint-s {
      padding-bottom: $spacing--m;
    }
  }

  &__item-text {
    padding-bottom: 10px;
    margin-right: 25px;

    >p {
      margin: 0;
      line-height: 1.83;
    }
  }

  & .button {
    margin-top: 32px;
  }
}