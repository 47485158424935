.carousel-button {
  $self: &;

  @include button-style-reset;
  position: absolute;
  top: 0px;
  height: 100%;
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  background-color: transparent;
  padding: 0;
  color: white;
  cursor: none;
  z-index: 10;

  .touchevents {
    display: none;
  }

  &--full-width {
    width: 100%;
    top: 0px;
  }

  &:disabled {
    opacity: 0;
  }

  html.no-touchevents.mouse-user & {
    opacity: 0;

    &:not(:disabled):hover {
      opacity: 1;
    }
  }

  &--next {
    right: 0px;

    .carousel-button__icon {
      right: 10px;
      transform: translate(-50%, -50%) translate3d(0, 0, 0);

      html.touchevents & {
        right: 10px !important;
        left: auto !important;
      }
    }
  }

  &--previous {
    @include breakpoint-s {
      left: 0px;
    }
    left: -20px;

    .carousel-button__icon {
      left: 50px;
      transform: translate(-50%, -50%) rotate(180deg) translate3d(0, 0, 0);

      html.touchevents & {
        left: 50px !important;
      }
    }
  }

  &__icon {
    width: 23px;
    position: absolute;
    top: 50%;
    filter: drop-shadow(0 3px 5px rgba(black, 0.4));

    html.touchevents & {
      top: 50% !important;
    }

    @include breakpoint-s {
      width: 50px;
    }
  }
}
