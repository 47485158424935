.article-info {
    display: grid;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;

    &--green {
        .article-info__text>.rich-text {
            color: white;
        }

        .article-info__title {
            color: white;
        }

        background-color: $color--slate;
    }

    &--beige {
        background-color: $color--beige;
    }

    &--yellow {
        background-color: $color--yellow;
    }


    &--half {
        gap: 24px;
        padding-right: 28px;
        padding-left: 28px;

        @include breakpoint-m {
            padding: 32px;
            width: 50%;
        }
    }

    &--full {
        gap: 16px;
    }

    &--full#{&}--green {
        border-image: conic-gradient($color--slate 0 0) fill 0/0/0 100vw
    }

    &--full#{&}--beige {
        border-image: conic-gradient($color--beige 0 0) fill 0/0/0 100vw
    }

    &--full#{&}--yellow {
        border-image: conic-gradient($color--yellow 0 0) fill 0/0/0 100vw
    }
}