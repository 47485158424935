.housing-project-rich-text-block {
  &__content {
    max-width: 600px;
    margin: auto;
  }

  &__link {
    width: fit-content;
    margin-top: $spacing--sm;
  }
}
