.colored-frame {
  padding: $spacing--s;

  &__title {
    margin: $spacing--xs 0;
  }

  &--red {
    background-color: $color--pink;
  }

  &--yellow {
    background-color: $color--yellow;
  }
}
