.fancy-date {
  font-size: convert-to-rem(40px);
  color: $color--orange;
  font-weight: 200;
  margin-left: auto;

  &__day,
  &__separator {
    display: inline;

    @include breakpoint-m {
      font-size: convert-to-rem(200px);
      writing-mode: vertical-lr;
      line-height: 26vw; // NOTE: Magic number to horizontally align number and text
    }

    @include breakpoint-l {
      line-height: 1.75; // NOTE: Magic number to horizontally align number and text
    }
  }

  &__month {
    display: inline;
    margin-left: 0.3em;

    @include breakpoint-m {
      display: block;
      margin-left: 0;
      font-size: convert-to-rem(50px);
      text-align: center;
    }
  }

  &__separator {
    margin: 0 0.3em;

    @include breakpoint-m {
      margin: 0.2em 0;
    }
  }
}
