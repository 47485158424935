.housing-project-contact-block {
  $self: &;
  &--dark,
  &--green {
    color: $color--beige-dark;
  }

  &__items {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin-top: $spacing--l;
    max-width: 900px;
    margin: auto;

    @include breakpoint-s-max {
      padding: 0 convert-to-rem(60px);
    }

    @include breakpoint-ms {
      display: grid;
      align-items: start;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: $spacing--m;
      grid-row-gap: $spacing--l;
    }

    &--1 {
      grid-template-columns: 1fr;
      @include breakpoint-ms {
        max-width: 270px;
      }
    }

    &--2 {
      grid-template-columns: 1fr 1fr;
      max-width: 600px;
    }

    &--3,
    &--6 {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &--5 {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  &__item {
    margin-bottom: $spacing--m;
    word-break: break-word;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @include breakpoint-ms-max {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    @include breakpoint-ms {
      margin-bottom: 0;
    }
    &--5 {
      grid-column: span 2;

      &:nth-last-child(2) {
        grid-column: 2 / 4;
      }

      &:last-child {
        grid-column: 4 / 6;
      }
    }

    &-image {
      width: 350px;
      height: 230px;
      object-fit: cover;
      @include breakpoint-s-max {
        height: 170px;
        width: 260px;
      }
      @include breakpoint-ms {
        width: 270px;
        height: 180px;
      }
    }

    &-title {
      margin: 0 0 $spacing--s 0;

      @include breakpoint-sm {
        margin-bottom: $spacing--s;
      }

      #{$self}__item-image + & {
        margin-top: $spacing--xs;

        @include breakpoint-sm {
          margin-top: $spacing--s;
        }
      }
    }

    &-sub-title {
      font-size: convert-to-rem(16px);
    }

    &-logo {
      margin-top: $spacing--s;
    }

    &-contact {
      display: flex;
      margin-bottom: 5px;
      display: flex;
      align-items: center;

      &-container {
        margin-top: auto;
      }

      &-icon {
        width: 13px;
        margin-right: $spacing--xs;

        &--mail {
          margin-top: 3px;
        }
      }

      &-link {
        @include underline-animation(
          expand,
          '.housing-project-contact-block__item-contact-link-text'
        );
        text-decoration: none;
        font-size: 16px;
        font-weight: 400;
        color: inherit;

        #{$self}--dark &,
        #{$self}--green & {
          color: $color--beige-dark;
        }

        #{$self}--light & {
          color: $color--black;
        }
      }
    }

    &-link {
      #{$self}--dark & {
        color: $color--grey-green;
      }
      #{$self}--green & {
        color: $color--housing-project-green;
      }
      #{$self}--light & {
        color: $color--grey;
      }
    }
  }
}
