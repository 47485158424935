.housing-project-menu {
  $self: &;
  --global-warning-height: 0px;

  position: fixed;
  z-index: 60;
  left: 0;
  width: 100%;
  top: calc(var(--global-warning-height) + 64px);
  transition: top 0.3s linear;

  @include breakpoint-s {
    top: calc(var(--global-warning-height) + 88px);
  }
  @include breakpoint-mm {
    top: calc(var(--global-warning-height) + 130px);
  }
  @include breakpoint-l {
    top: calc(var(--global-warning-height) + 80px);
  }

  &--should-hide-menu {
    top: 0;
    z-index: 101;
  }

  &--is-open {
    width: 100%;

    #{$self}__content {
      height: calc(100dvh - 4rem);
      justify-content: start;
    }

    #{$self}__mobile-list {
      border-top: 1px solid white;
      padding: 8px 24px;
      height: 100%;

      @include breakpoint-s {
        padding: 20px 32px;
      }
    }
  }

  &--is-article-page {
    animation-delay: 1s;
  }

  &--dark {
    #{$self}__back-to-frontpage-link {
      color: white;
    }
  }

  &--light {
    #{$self}__content-container {
      background: $color--beige;
    }

    #{$self}__mobile-list {
      border-top-color: $color--black;
      background-color: $color--beige;
    }

    #{$self}__mobile-button {
      color: $color--black;
    }

    #{$self}__logo--mobile {
      color: white;
    }
  }

  &__menu-anim-temp {
    z-index: 101;
  }

  &__content-container {
    background: black;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    max-width: $layout-max-width;
    margin: 0 auto;
    padding: 0;
    overflow: auto;

    @include breakpoint-mm {
      height: auto;
      overflow: hidden;
      padding: 20px 32px;
      flex-direction: row;
      align-items: center;
    }

    & > div {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      overflow: auto;

      @include breakpoint-mm {
        overflow: hidden;
        flex-direction: row;
        align-items: center;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    padding: 8px 24px;

    @include breakpoint-s {
      padding: 20px 32px;
    }

    @include breakpoint-mm {
      width: auto;
      padding: 0;
    }
  }

  &__main-link-wrapper {
    flex-shrink: 0;
    margin-right: 1rem;
  }

  &__main-link {
    font-size: 1.5rem;
  }

  &__logo {
    width: 34px;
    height: 40px;
    display: none;

    @include breakpoint-mm {
      display: block;
    }

    &--mobile {
      width: 22px;
      display: block;

      @include breakpoint-mm {
        display: none;
      }
    }
  }

  &__list-wrapper {
    display: none;
    @include breakpoint-mm {
      display: block;
      width: 100%;
    }
  }

  &__list-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    padding: convert-to-rem(24px);

    @include breakpoint-mm {
      height: auto;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      padding: 0;
    }
  }

  &__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0 auto;
    padding: 0;
    padding-bottom: convert-to-rem(24px);

    @include breakpoint-mm {
      flex-direction: row;
      height: auto;
      padding: 0;
      justify-content: center;
      overflow: hidden;
    }

    &-item {
      margin-bottom: convert-to-rem(24px);

      @include breakpoint-mm {
        display: block;
        margin-right: 25px;
        margin-top: 2px;
        margin-bottom: 2px;
      }
    }

    &-link {
      font-size: 25px !important;
      font-weight: 400 !important;

      @include breakpoint-mm {
        font-size: 18px !important;
      }
    }
  }

  &__button {
    padding: 0.5rem 1.5rem calc(0.5rem - 2px);
    font-size: convert-to-rem(14px);
    min-height: auto;
    width: auto;
  }

  &__mobile-button {
    @include button-style-reset;
    background: transparent;
    color: white;
    height: 50px;
    padding: 0 30px;
    align-items: center;
    cursor: pointer;

    @include breakpoint-mm {
      display: none;
    }

    &__icon {
      width: 26px;

      &-is-open {
        rotate: 180deg;
      }
    }
  }

  &__mobile-list {
    display: block;
    width: 100%;
    background-color: $color--black;
    overflow: auto;
    border: none;

    #{$self}__list {
      margin: 0;
    }

    @include breakpoint-mm {
      display: none;
    }
  }

  &__back-to-frontpage-link {
    @include underline-animation(
        expand,
        '.housing-project-menu__back-to-frontpage-link-text'
    );
    text-decoration: none;
    color: $color--black;
    font-weight: 400;
    align-items: center;
    display: flex;
    margin-bottom: convert-to-rem(32px);
    cursor: pointer;

    @include breakpoint-mm {
      display: none;
    }

    &-icon {
      rotate: 90deg;
      width: 22px;
      margin: 8px 8px 8px 0;
    }
  }

  &-text {
    margin-left: $spacing--s;
  }
}
