.no-items-message {

    &__heading{
        font-weight: 300;
        @include responsive-font-size(23px, 25px);
        line-height: 1.3;
        color: $color--black;
        margin: 0 0 13px;

        @include breakpoint-s {
            line-height: 1.44;
            margin-bottom: 20px;
        }
    }

    &__text{
        font-weight: normal;
        @include responsive-font-size(16px, 18px);
        line-height: 1.8;
        color: $color--green-dark;
        margin: 0;

        @include breakpoint-s {
            line-height: 1.39;
        }
    }
}