.related-pages-block {
  @include responsive-font-size(18px, 30px);
  line-height: 1.5;
  $self: &;

  &--green {
    background-color: $color--green-dark;

    @include breakpoint-ms {
      background-color: inherit;
    }
  }

  &__title {
    margin-bottom: $spacing--s;
    @include breakpoint-s {
      margin-bottom: $spacing--m;
    }
  }

  &__items {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    margin-bottom: $spacing--m;
    flex: 0 0 calc((100% - 40px)/3);
    margin-left: 20px;
    flex-direction: column;

    @include breakpoint-l {
      flex: 0 0 calc((100% - 100px)/3);
      margin-left: 50px;
    }

    @include breakpoint-sm {
      flex-direction: row;
    }
    
    &:first-child{
      margin-left: 0;
    }

    &-image-container {
      position: relative;
      height: convert-to-rem(45px);
      width: convert-to-rem(45px);
      margin-bottom: 10px;

      @include breakpoint-m {
        height: convert-to-rem(100px);
        width: convert-to-rem(100px);
        margin-bottom: 0;
      }
     
      @include breakpoint-sm {
        align-self: center;
      }
    }

    &-link-container {
      font-size: convert-to-rem(16px);
      line-height: convert-to-rem(26px);
      
      @include breakpoint-s {
        font-size: convert-to-rem(18px);
        line-height: convert-to-rem(28px);
      }
      
      @include breakpoint-l {
        font-size: convert-to-rem(25px);
        line-height: convert-to-rem(40px);
      }

      @include breakpoint-sm {
        flex: 0 0 calc(100% - 65px);
        
        margin-left: 20px;
        align-self: center;
      }

      @include breakpoint-m {
        flex: 0 0 calc(100% - 120px);
      }
    }
  }
}
