.housing-project-teaser-block {
  @include housing-project-block-wrapper;

  &--dark,
  &--green {
    .heading,
    .rich-text {
      color: $color--beige-dark;
    }
  }

  &--light {
    .heading,
    .rich-text {
      color: $color--black;
    }
  }

  &__wrapper {
    @include breakpoint-m {
      display: flex;
    }
  }

  &__text-content {
    text-align: center;
  }

  &__heading {
    margin-bottom: $spacing--xs;
    @include breakpoint-m {
      margin-top: $spacing--m;
    }
  }

  &__text {
    margin-bottom: $spacing--sm;
  }

  &__link {
    margin-bottom: $spacing--m;
    @include breakpoint-m {
      margin-bottom: 0;
    }
  }

  &__left {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: $spacing--s;
    @include breakpoint-m {
      margin-right: $spacing--l;
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  &__plx-wrapper {
    width: auto !important;
    position: relative !important;
  }
}
