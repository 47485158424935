@import 'functions';
// COLORS //
$color--red: #d24348;
$color--red-strong: #e23142;
$color--red-dark: #be3c40;
$color--orange: #ff8367;
$color--red-light: #fecfc1;
$color--dark-orange: #ee6445;
$color--orange-light: #ffab99;
$color--pink: #f7d1c3;
$color--pink-light: #fbe8e1;
$color--pink-ultra-light: #fbece6;
$color--yellow: #fcf1d3;
$color--yellow-strong: #ffe584;
$color--yellow-light: #fdf8e9;

$color--black: #262626;
$color--grey-light: #ecebea;
$color--grey-ultra-light: #f5f4f3;
$color--grey-medium-light: #f7f6f5;
$color--grey: #6c6c6c;
$color--grey-dark: #696969;
$color--beige-light: #f8f7f6;
$color--beige-medium: #f8f6f6;
$color--beige: #f5f2ec;
$color--beige-medium: #f5f3f1;
$color--beige-yellow: #fcf8ee;
$color--beige-dark: #e7e5df;
$color--green-dark: #343e3e;
$color--slate: #637878;
$color--grey-green: #979c9c;
$color--green: #2a836b;
$color--green-confirmation: #2a836b;
$color--disabled: #b1b0ab;
$color--blue: #9ebacc;
$color--blue-dark: #132222;
$color--aqua: #a0c3c3;
$color--housing-project-green: #aed9c0;

// SPACING //
$spacing--xxxl: convert-to-rem(160px);
$spacing--xxl: convert-to-rem(120px);
$spacing--xl: convert-to-rem(80px);
$spacing--l: convert-to-rem(60px);
$spacing--m: convert-to-rem(40px);
$spacing--sm: convert-to-rem(30px);
$spacing--s: convert-to-rem(20px);
$spacing--xs: convert-to-rem(10px);

$spacing--top-of-page: convert-to-rem(90px);

$content-max-width: 1360px;
$layout-max-width: 1440px;

$portal-desktop-header-width: 270px;
$portal-mobile-header-height: 70px;
$portal-rich-text-width: 600px;
$portal-page-header-content-width: calc(#{$portal-rich-text-width} + 30px);
$dialog-width: 640px;
$portal-content-max-width: $content-max-width - $portal-desktop-header-width;

$spacing--page-top: calc(3 * $spacing--xl);
$medium-contact-basis: 0 0 calc(50% - #{$spacing--sm} / 2);
$desktop-contact-basis: 0 0 calc(100% / 3 - #{$spacing--sm} * 2 / 3);

//TODO remove old corner-widths and rename when other corners are adjusted aswellmi
$corner-width-s: 60px;
$corner-width-m: 90px;
$corner-width-l: 120px;

$new-corner-width-s: 50px;
$new-corner-width-m: 75px;
$new-corner-width-l: 100px;

$breakpoint-xs: 365px;
$breakpoint-s: 500px;
$breakpoint-sm: 650px;
$breakpoint-ms: 800px;
$breakpoint-mm: 900px;
$breakpoint-m: 1000px;
$breakpoint-xm: 1120px;
$breakpoint-l: 1360px;
$breakpoint-ll: 1440px;
$breakpoint-xl: 1920px;

// FONTS//

$font-family: 'National2', Arial, Helvetica, sans-serif;
$font-family-secondary: 'National2Condensed', Impact, Charcoal, sans-serif;

$button-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

$logo-width: convert-to-rem(78px);
$logo-left-spacing: viewport-dependent(55);
$left-spacing: calc(#{$logo-width} + #{$logo-left-spacing} + 20px);
