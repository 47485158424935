.filters {
  @include breakpoint-s {
    display: flex;
  }

  &__item {
    @include breakpoint-s {
      width: 46.5%;
      max-width: 350px;

      &:first-child {
        margin-right: 7%;
      }
    }
  }
}
