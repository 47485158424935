.inspo-list-page {
  &__filter-wrapper {
    background-color: $color--beige-light;
    @include breakpoint-s {
      background-color: transparent;
    }
  }

  &__blocks {
    margin-top: $spacing--xl;

    @include breakpoint-m {
      margin-top: $spacing--xxl;
    }
  }
}
