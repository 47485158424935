// NOTE: This component receives some predefined form markup from the server and tries its best to style it. See example markup in /source/static-site/pages/residence/form.html

.form {
  @include responsive-font-size(16px, 18px);
  line-height: 1.83;
  font-weight: 200;

  .waf-form-field {
    margin-top: $spacing--s;

    &.waf-form-validationerror {
      color: $color--red;
    }
  }

  .waf-form-validationtext {
    font-weight: normal;
  }

  .waf-form-field-required {
    label:before {
      content: "* ";
    }
  }

  // NOTE: This targets the general error message header only
  .waf-form-validationerror:not(.waf-form-field) {
    background-color: $color--pink;
    padding: $spacing--xs $spacing--s;
    margin-top: $spacing--m;
  }

  h2 {
    @include responsive-font-size(25px, 30px);
    font-weight: 200;
    margin: $spacing--l 0 0;
    line-height: 1.2;
  }

  h3 {
    @include responsive-font-size(20px, 25px);
    font-weight: 200;
    margin: $spacing--m 0 0;
    line-height: 1.2;
  }

  h4 {
    @include responsive-font-size(17px, 18px);
    font-weight: 500;
  }

  a {
    color: inherit;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    font-family: inherit;
    font-size: inherit;
    appearance: none;
    background-color: transparent;
    width: 100%;
    border: none;
    border-bottom: 1px solid $color--grey;
    padding: 0 0 0.5em;
    border-radius: 0;
    color: $color--slate;
  }

  label {
    font-size: convert-to-rem(16px);
    text-transform: uppercase;
    letter-spacing: 0.0625em;
    font-weight: normal;
  }

  input[type="checkbox"],
  input[type="radio"] {
    margin: 0 1em 0 0;

    + label {
      font-size: inherit;
      text-transform: none;
      letter-spacing: 0;
    }
  }

  input[type="radio"] + label {
    margin-right: $spacing--m;
  }

  input[type="submit"] {
    @include responsive-font-size(13px, 16px);
    appearance: none;
    display: block;
    appearance: none;
    font-family: inherit;
    font-size: inherit;
    width: 100%;
    border: none;
    background-color: $color--slate;
    text-align: center;
    padding: convert-to-rem(25px);
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.0625em;
    color: white;
    cursor: pointer;
    border-radius: 0;
    margin-top: 20px;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  textarea {
    width: 100%;
    min-height: convert-to-rem(200px);
    resize: none;
    font-size: inherit;
    font-family: inherit;
    padding: $spacing--xs;
  }

  input[type="file"] {
    font-size: inherit;
    font-family: inherit;
    font-weight: 200;
    margin-top: $spacing--xs;
  }
}
