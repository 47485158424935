.checkbox {
  $self: &;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 20px;
  justify-content: flex-start;
  cursor: pointer;

  &--disabled {
    cursor: default;
  }

  &__input {
    @include visually-hidden;

    &:hover ~ #{$self}__icon-container,
    &:focus ~ #{$self}__icon-container {
      border-width: 2px;
      border-color: $color--green-dark;
    }
  }

  &__icon-container {
    box-sizing: border-box;
    flex: 0 0 30px;
    align-self: flex-start;
    align-items: center;
    width: 30px;
    height: 30px;
    border: 1px solid $color--grey;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;

    #{$self}--disabled & {
      background-color: transparent !important;
      border-color: $color--grey-dark !important;
      border-width: 1px !important;
    }

    #{$self}--in-voting-block & {
      background-color: white;
      border-color: $color--black;

      &_checked {
        border-color: $color--green !important;
        #{$self}--disabled & {
          border-color: $color--grey-dark !important;
        }
      }
    }

    &_checked {
      border-color: $color--green;
      border-width: 2px;
    }
  }

  &__icon {
    align-self: center;
    width: 30px;
    height: 30px;
    display: block;

    path {
      fill: none;
      stroke: $color--green;
      stroke-width: 2;
      stroke-dasharray: 23;
      stroke-dashoffset: 23;
      animation: draw 0.5s ease-out forwards;
    }

    path {
      stroke: $color--green;
    }

    #{$self}--disabled & {
      path {
        stroke: $color--grey-dark;
      }
    }
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  &__spinner {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border-top: 2px solid $color--green;
    border-right: 2px solid transparent;
    animation: spinner 1s linear infinite;
    flex: 0 0 20px;
  }

  &__text-container {
    flex: auto;
    padding-top: 6px;
  }

  &__label,
  &__text {
    display: block;
    font-size: convert-to-rem(16px);
    line-height: 1.25;
    font-weight: 300;
    color: $color--green-dark;
  }

  &__label {
    font-weight: 500;
    #{$self}--disabled & {
      color: $color--grey-dark;
    }
  }

  &__text {
    padding-top: 4px;
  }
}
