.footer-group {
  width: 25%;
  margin: 0;
  padding-bottom: 0;

  @include breakpoint-m-max {
    width: 50%;
    padding-top: $spacing--m;
  }

  @include breakpoint-s-max {
    width: 100%;
  }

  &__items {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-right: $spacing--sm;

    & :first-child {
      padding-top: $spacing--xs;
    }
  }

  &__link {
    color: $color--yellow;

    &:hover,
    &:focus {
      color: white;
      text-decoration: none;
    }
  }

  &__item {
    @include responsive-font-size(23px, 25px);
    padding: $spacing--xs 0;
  }

  &__text {
    font-size: convert-to-rem(18px);
  }
}
