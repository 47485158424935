.inspo-article-list {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;

  @include breakpoint-ms {
    display: grid;
    grid-template-columns:
      calc(25% - #{$spacing--sm}) calc(25% - #{$spacing--sm})
      calc(25% - #{$spacing--sm}) calc(25% - #{$spacing--sm});
    grid-gap: $spacing--xl $spacing--m;
    @include content-wrapper-padding;
  }

  &__link {
    text-decoration: none;
    color: $color--green-dark;
    flex-direction: column-reverse;
    display: flex;

    @include breakpoint-ms {
      flex-direction: column;
      width: 100%;
    }
  }

  &__item {
    margin: $spacing--l;
    display: block;

    @include underline-animation(expand, '.inspo-article-list__title-span');

    &:first-child .inspo-article-list__ingress {
      display: block;
    }

    &:first-child .inspo-article-list__title,
    &:nth-child(10n + 10) .inspo-article-list__title,
    &:nth-child(10n + 11) .inspo-article-list__title,
    &:first-child .inspo-article-list__ingress,
    &:nth-child(10n + 10) .inspo-article-list__ingress,
    &:nth-child(10n + 11) .inspo-article-list__ingress,
    &:first-child .inspo-article-list__tags,
    &:nth-child(10n + 10) .inspo-article-list__tags,
    &:nth-child(10n + 11) .inspo-article-list__tags {
      margin: $spacing--s $spacing--l $spacing--s $spacing--s;

      @include breakpoint-s {
        margin: $spacing--m $spacing--l $spacing--m $spacing--m;
      }

      @include breakpoint-ms {
        margin: $spacing--s 0 0 0;
      }
    }

    &:first-child .inspo-article-list__title,
    &:nth-child(10n + 10) .inspo-article-list__title,
    &:nth-child(10n + 11) .inspo-article-list__title {
      @include responsive-font-size(30px, 50px);
      color: $color--green-dark;
      line-height: 1.3;
      margin: 0 $spacing--l $spacing--s $spacing--s;

      @include breakpoint-s {
        margin: 0 $spacing--l $spacing--s $spacing--m;
      }

      @include breakpoint-ms {
        margin: 0;
      }
    }

    &:first-child .inspo-article-list__tags {
      margin-bottom: $spacing--xs;

      @include breakpoint-ms {
        margin-bottom: $spacing--s;
      }
    }

    &:nth-child(10n + 10) .inspo-article-list__tags,
    &:nth-child(10n + 11) .inspo-article-list__tags {
      margin-bottom: $spacing--xs;
    }

    &:first-child .inspo-article-list__ingress,
    &:nth-child(10n + 10) .inspo-article-list__ingress,
    &:nth-child(10n + 11) .inspo-article-list__ingress {
      margin-top: 0 !important;

      @include breakpoint-ms {
        margin-top: $spacing--s !important;
      }
    }

    &:first-child {
      grid-column: 1 / 3;
      grid-row: 1 / 3;
      margin: $spacing--sm 0 $spacing--l 0 !important;

      @include breakpoint-ms {
        margin: 0 !important;
      }
    }

    &:nth-child(10n + 10) {
      grid-column: 1 / 3;
      margin: 0 !important;
    }

    &:nth-child(10n + 11) {
      grid-column: 3 / 5;
      margin: $spacing--m 0 0 0;
    }

    @include breakpoint-ms {
      margin: 0 !important;
    }
  }

  &__image-container {
    position: relative;
    padding-bottom: 70%;
  }

  &__video-container {
    overflow: hidden;
    position: relative;
    padding-bottom: 70%;
    display: flex;
    justify-content: center;
  }

  &__tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    font-family: 'National2Condensed';
    font-size: convert-to-rem(14px);
    color: $color--slate;
    margin: $spacing--s 0 5px 0;
    order: 3;

    @include breakpoint-ms {
      order: 1;
      margin: $spacing--s 0 7px 0;
    }
  }

  &__title {
    @include responsive-font-size(23px, 25px);
    line-height: 1.4;

    &-span {
      background-image: linear-gradient(
        $color--grey-green,
        $color--grey-green
      ) !important;
    }

    order: 2;
    margin-bottom: $spacing--s;

    @include breakpoint-ms {
      margin-bottom: 0;
    }
  }

  &__ingress {
    display: none;
    font-size: convert-to-rem(20px);
    font-weight: 300;
    margin-top: $spacing--s;
    line-height: 1.6;
    order: 1;

    @include breakpoint-ms {
      order: 3;
    }
  }
}
