@import 'source/scss/vendor/slick';

.carousel {
  $self: &;
  position: relative;
  overflow: hidden;

  &--centered {
    @include breakpoint-m {
      .carousel__count {
        text-align: center;
      }
    }
  }

  &--left-aligned {
    .carousel__count {
      margin-left: $spacing--l;
    }

    .carousel__count-container {
      display: block;
    }
    @include breakpoint-m {
      .carousel__count {
        margin-left: 0;
        text-align: left;
      }
    }

    @include breakpoint-s-max {
      .carousel__count {
        margin-left: 0;
      }
    }
  }

  &--wide {
    @include content-wrapper-padding;
    padding-right: 0;

    @include breakpoint-s {
      // NOTE: Overriding content-wrapper-padding mixin
      padding-left: 0;
      padding-right: 0;
    }

    @include breakpoint-s-max {
      @include content-wrapper-padding;
    }
  }

  &--tall {
    padding: 0;
  }

  &__track {
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
    transition: transform 0.5s;

    &:before {
      content: '';
      display: block;
      padding-top: calc(calc(9 / 16) * 100%);
    }

    #{$self}--tall & {
      &:before {
        content: '';
        display: block;
        padding-top: 65%;
      }
    }
  }

  &__slide {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  &__picture-area {
    position: relative;
    overflow: hidden;
  }

  &__buttons {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__next,
  &__previous {
    @include button-style-reset;
    position: absolute;
    height: 100%;
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    background-color: transparent;
    padding: 0;

    transition: opacity 0.3s;
    opacity: 0;
    color: white;

    &--active {
      opacity: 1;
    }
  }

  &__next {
    right: 0;
  }

  &__previous {
    left: 0;
    transform: scaleX(-1);
  }

  &__count-container {
    display: flex;
    justify-content: center;

    @include breakpoint-m {
      justify-content: flex-end;
    }
  }

  &__count {
    margin-top: $spacing--xs;

    font-family: $font-family-secondary;
    font-feature-settings: 'tnum';
    letter-spacing: 0.1em;
    color: $color--slate;
    @include breakpoint-m {
      flex: 0 0 50%;

      margin-top: $spacing--s;
    }
  }

  &__pagination,
  &__circle-counter-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: $spacing--s;
  }

  .swiper-pagination-bullet,
  &__circle-counter {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid $color--slate;
    margin: 5px;
    opacity: 1;

    &-active,
    &--hasCurrent {
      background-color: $color--dark-orange;
      border: 1px solid $color--dark-orange;
    }
  }

  #{$self}__swiper-container &__button {
    &.swiper-button-disabled {
      display: none;
    }
  }

  &__dot {
    width: 10px;
    max-width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid $color--slate;
    opacity: 1;
    display: block;
    overflow: hidden;
    text-indent: 20px;
    white-space: nowrap;
    padding: 0;
    margin: 5px;
    outline: none;

    &:hover,
    &:focus {
      background-color: rgba($color--dark-orange, 0.5);
    }

    &--active,
    &--active:hover,
    &--active:focus {
      background-color: $color--dark-orange;
      border: 1px solid $color--dark-orange;
      pointer-events: none;
    }
  }

  .slick-slider * {
    //extra padding fix
    font-size: 0;
  }

  &__content-item {
    margin-top: $spacing--s;

    @include breakpoint-m {
      display: flex;
      margin-top: $spacing--sm;
    }
  }

  &__title {
    line-height: 1.3;
    display: block;
    @include breakpoint-m {
      width: 50%;
      flex-shrink: 0;
    }

    @include breakpoint-l {
      padding-right: 20%;
    }
  }

  &__text {
    @include responsive-font-size(18px, 20px);
    margin-top: $spacing--s;
    line-height: 1.6;
    font-weight: 300;
    color: $color--black;

    @include breakpoint-m {
      margin-top: 0;
    }
  }
}
