.inspo-related-articles {
  &__list {
    max-width: 100%;
    padding: 0;
    list-style: none;

    @include breakpoint-m {
      max-width: 188px;
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: convert-to-rem(16px);
    font-weight: 500;
    letter-spacing: 2px;
    font-family: 'National2Condensed';
    line-height: 1.13;
    color: $color--green-dark;
    margin-bottom: $spacing--sm;
  }

  &__item {
    margin-bottom: $spacing--sm;

    @include breakpoint-m {
      margin-bottom: $spacing--m;
    }
    @include underline-animation(
      expand,
      '.inspo-related-articles__article-title-span'
    );
  }

  &__content-container {
    display: flex;

    @include breakpoint-m {
      display: block;
    }
  }

  &__content {
    width: 50%;
    margin-left: $spacing--s;

    @include breakpoint-m {
      width: 100%;
      margin: 0;
    }
  }

  &__link {
    text-decoration: none;
  }

  &__tag {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    font-family: 'National2Condensed';
    font-size: convert-to-rem(14px);
    color: $color--slate;
    margin: 0 0 5px 0;

    @include breakpoint-m {
      margin: $spacing--s 0 5px 0;
    }
  }

  &__image {
    position: relative;
    padding-bottom: 35%;
    width: 50%;

    @include breakpoint-m {
      width: 100%;
      padding-bottom: 70%;
    }
  }

  &__article-title {
    @include responsive-font-size(16px, 18px);
    color: $color--green-dark;
    font-weight: 400;
    line-height: 1.45;

    &-span {
      background-image: linear-gradient(
        $color--grey-green,
        $color--grey-green
      ) !important;
    }
  }
}
