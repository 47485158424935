.estimated-reading-time {
  font-size: convert-to-rem(16px);
  color: $color--slate;
  font-family: $font-family-secondary;
  line-height: 1;
  letter-spacing: 1px;

  &__number{
    font-weight: bold;
  }
}
