.housing-project-information-list {
  $self: &;

  &__items {
    display: grid;
    grid-template-columns: 35% 65%;
    @include breakpoint-sm {
      grid-template-columns: 25% 75%;
    }
    @include breakpoint-xm {
      grid-template-columns: 35% 65%;
    }
    grid-row-gap: convert-to-rem(7px);
    margin: 0 0 $spacing--sm 0;
  }

  &__label {
    #{$self}--dark & {
      color: $color--grey-green;
    }

    #{$self}--light & {
      color: $color--grey;
    }
  }
}
