.article-page {
  &__text {
    margin-top: 0;
    margin-bottom: $spacing--m;

    @include breakpoint-s {
      margin-top: $spacing--l;
      margin-bottom: $spacing--l;
    }
  }

  &__blocks {
    margin-bottom: $spacing--xl;
  }
}
