.login-link {
  $self: &;

  font-size: 1rem;
  padding: 0;
  color: $color--yellow;
  white-space: nowrap;

  @include breakpoint-mm {
    padding: 5px 10px;
    &:last-child {
      padding-right: 0;
    }
  }

  a {
    font-weight: 400;

    @include breakpoint-s {
      font-weight: 200;
    }
  }

  &__icon {
    height: 19px;
    width: 15px;
    fill: $color--yellow;
    margin-right: $spacing--xs;
  }

  &--black {
    #{$self}__icon {
      fill: $color--black;
    }
  }
}
