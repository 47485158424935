.pagination {
  color: $color--grey;
  display: flex;
  justify-content: center;
  font-weight: 200;

  &__links {
    display: flex;
    font-size: convert-to-rem(25px);
  }

  &__link-group {
    display: flex;
  }

  &__link {
    margin: 0 $spacing--xs 0 $spacing--xs;
  }

  &__prev {
    transform: scaleX(-1);
    margin-right: $spacing--s;
  }

  &__next {
    margin-left: $spacing--s;
  }

  &__icon {
    & path {
      fill: $color--grey;
    }
    & circle {
      stroke: $color--grey;
    }

    &--active {
      & path {
        fill: $color--red;
      }
      & circle {
        stroke: $color--red;
      }
    }
  }
}
