.contact-card {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  &__image {
    position: relative;
    width: 175px;
    height: 175px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: $spacing--s;

    image {
      object-fit: cover;
    }

    &--grayscale {
      filter: grayscale(1);
    }
  }

  &__text {
    margin-bottom: 16px;
  }

  &__title {
    margin-bottom: 16px;
  }

  &__job-title {
    @include responsive-font-size(13px, 16px);
    font-family: $font-family-secondary;
    color: $color--slate;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.0625em;
  }

  &__description {
    margin-top: 16px;
    & > p {
      margin: 0;
    }
  }

  &__link {
    margin-bottom: 16px;
    width: 100%;

    .link__icon-container {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0 0.625rem 0 0;
    }
  }
}
