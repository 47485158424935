.text-input {
  $self: &;

  &--required {
    #{$self}__heading {
      position: relative;

      &::after {
        content: '*';
        position: absolute;
        display: inline-block;
        top: 0;
        right: -1rem;
      }
    }
  }

  &--number {
    #{$self}__input {
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        display: none;
      }
    }
  }

  &--search {
    #{$self}__input {
      padding-right: 2.5rem;
      box-sizing: border-box; //legacy fix
    }
  }

  &--in-faq-link {
    #{$self}__input {
      color: $color--slate;
    }
  }

  &--in-housing-project-contact-form {
    &--light {
      color: $color--grey-green;

      #{$self}__input {
        color: $color--grey-green;
      }

      label,
      .text-input__input {
        color: $color--black;
      }
    }

    &--dark,
    &--green {
      .text-input__input {
        color: $color--beige-dark;
      }
    }

    #{$self}__input {
      background: transparent;
    }
  }

  &--in-faq-answer {
    margin-top: 20px;
  }

  &--in-edit-board-news-title,
  &--in-edit-board-news-text,
  &--in-add-board-news-title,
  &--in-add-board-news-text,
  &--in-news-link,
  &--in-news-link-text,
  &--in-faq-link,
  &--in-faq-link-text {
    width: 100%;
    margin-top: $spacing--sm;
  }

  &--textarea {
    #{$self}__input {
      min-height: 10rem;
      max-width: 100%;
      min-width: 100%;
      font-family: inherit;
    }
  }

  &--error {
    @include placeholder {
      color: $color--red-dark;
    }
  }

  &__label {
    #{$self}--in-handymen-contact & {
      @include visually-hidden;
    }

    &--hidden {
      @include visually-hidden;
    }

    &--simple {
      margin: 0;
    }

    @include breakpoint-m {
      &--simple {
        margin: 0;
      }
    }

    #{$self}--in-add-comment & {
      color: $color--green-dark;
      font-size: convert-to-rem(16px);
      line-height: 1.25;
      font-weight: normal;

      &.label--error {
        color: $color--red-dark;
      }
    }

    #{$self}--in-add-credit-card & {
      font-weight: bold;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }

  &__description {
    margin-top: 1rem;
  }

  &__input {
    font-family: $font-family;
    padding: 5px 12px 5px;
    width: 100%;
    border: 1px solid $color--grey-green;
    font-size: convert-to-rem(16px);
    font-weight: normal;
    line-height: 1;
    color: $color--green-dark;
    margin-bottom: $spacing--s;
    height: 40px;
    border-radius: 0;
    -webkit-appearance: none;

    @include breakpoint-s {
      padding: 10px 12px 10px;
      font-size: convert-to-rem(18px);
      margin-bottom: $spacing--sm;
    }

    &:focus,
    &:active {
      border: 2px solid $color--slate;
    }

    &:focus:required:invalid,
    &--error {
      border: 1px solid $color--red-dark !important;
      color: $color--red-dark !important;

      &:focus,
      &:active {
        border: 2px solid $color--red-dark !important;
      }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
      -webkit-text-fill-color: $color--green-dark !important;
      transition: background-color 5000s ease-in-out 0s;
      font-weight: normal;
      font-size: convert-to-rem(16px);

      @include breakpoint-s {
        font-size: convert-to-rem(20px);
      }
    }

    &--confirmation-input {
      width: 137px;
      border: 2px solid $color--green;
      padding-bottom: 3px;
      padding-top: 6px;

      #{$self}--error & {
        border-color: $color--red-dark;
      }
    }

    #{$self}--in-add-credit-card &,
    #{$self}--in-keys-payment &,
    #{$self}--in-order-keys & {
      margin-bottom: 0;
    }

    &--simple {
      border-width: 0 0 1px 0;
      border-color: $color--grey-green;
      margin-bottom: $spacing--s;
      padding: 0;
      height: 35px;

      @include breakpoint-m {
        margin-bottom: $spacing--sm;
      }
    }

    #{$self}--in-handymen-contact & {
      margin: 0 0 20px;
      border: 1px solid $color--green;
      resize: none;
      min-height: 40px;
      font-family: $font-family;
      font-weight: normal;
      height: 40px;
      padding: 0 20px;
      line-height: 36px;
      display: block;
      @include responsive-font-size(20px, 22px);
      overflow: hidden;
      color: $color--green;
      transition: height 1s ease-in-out;

      &:focus,
      &:active {
        border: 2px solid $color--green;
      }

      @include breakpoint-l {
        margin: 0;
      }
    }

    #{$self}--in-faq-question & {
      line-height: 1.4;
    }

    #{$self}--in-handymen-contact-expanded & {
      height: 136px;
      overflow-y: auto;
      line-height: 1.2;
      padding-top: 10px;
      padding-bottom: 10px;

      @include breakpoint-l {
        height: 166px;
        line-height: 1.23;
      }
    }

    #{$self}--in-edit-board-news-title &,
    #{$self}--in-edit-board-news-text &,
    #{$self}--in-add-board-news-title &,
    #{$self}--in-add-board-news-text &,
    #{$self}--in-news-link &,
    #{$self}--in-news-link-text &,
    #{$self}--in-faq-answer &,
    #{$self}--in-faq-link &,
    #{$self}--in-faq-link-text & {
      font-family: $font-family;
      font-weight: 400;
      color: $color--slate;
      margin-bottom: $spacing--xs;
    }

    #{$self}--in-news-link-text &,
    #{$self}--in-faq-link-text & {
      margin-bottom: $spacing--s;
    }

    #{$self}--in-edit-board-news-title &,
    #{$self}--in-add-board-news-title &,
    #{$self}--in-faq-question & {
      @include responsive-font-size(18px, 22px);
      line-height: 1.4;
      min-height: convert-to-rem(85px);

      @include breakpoint-s {
        min-height: convert-to-rem(120px);
      }
    }

    #{$self}--in-edit-board-news-text &,
    #{$self}--in-faq-answer & {
      line-height: 1.81;
      min-height: convert-to-rem(300px);

      @include breakpoint-s {
        min-height: convert-to-rem(400px);
      }
    }

    #{$self}--in-add-board-news-title & {
      min-height: convert-to-rem(100px);
      padding: 5px 10px;
      line-height: 1.6;

      @include breakpoint-s {
        padding: 10px 20px;
        min-height: convert-to-rem(55px);
      }
    }

    #{$self}--in-add-board-news-text & {
      min-height: convert-to-rem(300px);
      padding: $spacing--xs;
      line-height: 1.6;

      @include breakpoint-m {
        padding: $spacing--s;
        min-height: convert-to-rem(150px);
      }
    }

    #{$self}--in-faq-link-text &,
    #{$self}--in-news-link-text & {
      @include breakpoint-s {
        max-width: 242px;
      }
    }

    #{$self}--in-faq-link-text & {
      color: $color--slate;
    }

    #{$self}--in-faq-answer & {
      max-width: 213px;

      @include breakpoint-s {
        max-width: 242px;
      }
    }

    #{$self}--in-add-comment & {
      @include responsive-font-size(16px, 18px);
      line-height: 1.44;
      color: $color--grey;
      border: 1px solid $color--grey-green;
      background-color: $color--grey-ultra-light;
      padding: 20px 45px 20px 15px;
      border-radius: 10px;
      overflow: hidden;
      margin: 0;
      display: block;
      min-height: 138px;
    }

    #{$self}--in-add-agenda-item & {
      line-height: 1.44;
      padding-right: 40px;
    }
  }

  &--textarea#{$self}--in-faq-answer {
    #{$self}__input {
      padding-top: 10px;
      margin-bottom: $spacing--xs;
      color: $color--slate;
    }
  }

  &--textarea#{$self}--in-faq-question {
    #{$self}__input {
      margin-bottom: $spacing--xs;
      overflow: hidden;
      resize: none;
      padding-top: 10px;
      padding-bottom: 10px;
      color: $color--slate;
    }
  }

  &__input-and-info-container {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  &__input-container {
    flex: 0 0 calc(100% - 40px);
    #{$self}--in-keys-payment & {
      @include order-key-breakpoint {
        flex: 0 0 100%;
      }
    }
  }

  &__note-container {
    margin-left: auto;
    align-self: flex-start;

    #{$self}--in-keys-payment & {
      @include order-key-breakpoint {
        margin-right: -40px;
      }
    }
  }

  &__error {
    #{$self}--in-order-keys & {
      margin-bottom: 0;
    }
  }

  &--in-order-keys {
    margin-bottom: 20px;

    @include order-key-breakpoint {
      margin-bottom: 0;
    }
  }

  &__input-wrapper {
    position: relative;
  }
}
