.select {
  position: relative;

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &--active {
    z-index: 2;
  }

  select {
    // NOTE: Makes the select element overlap the entire fake select to be accessible with touch screens
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 16px; // NOTE: Prevent iPhone zoom on focus
    animation: fade-in 0s 1s both; // NOTE: Prevents flash of native select element before mount
  }

  &--is-mounted {
    // NOTE: Makes real select invisible and puts it behind fake select
    select {
      z-index: 0;
      opacity: 0;
      animation-fill-mode: none;
    }
  }

  &--has-touch {
    // NOTE: Puts the invisible real select on top of the fake select to bring up the native select menu on touch
    select {
      z-index: 2;
    }
  }

  &--is-disabled {
    opacity: 0.5;
  }

  &__fake,
  &__element {
    text-transform: uppercase;
    letter-spacing: 0.0625em;
    color: $color--green-dark;
  }

  &__fake {
    position: relative;
    user-select: none;

    // NOTE: Outline for keyboard users
    .select:not(.select--has-touch) select:focus + & {
      outline: 1px dotted currentColor;
      outline: 4px solid black;
    }
  }

  &__element {
    background-color: white;
    padding: 15px 0;
    border-top: 1px solid $color--slate;
  }

  &__dropdown {
    list-style: none;
    position: absolute;
    width: 100%;
    margin-top: -1px;
    background-color: $color--beige;
    border-top: none;
    border-radius: 0;
    padding: 0;
    font-size: inherit;
    max-height: 200px;
    overflow: auto;
  }

  &__option {
    cursor: pointer;
    padding: 10px 20px;

    &:first-child {
      padding-top: 20px;
    }

    &:last-child {
      padding-bottom: 20px;
    }

    &:hover,
    &:focus {
      background-color: $color--beige-dark;
      color: $color--black;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: $color--slate;

    &--active {
      transform: translateY(-50%) rotate(-180deg);
    }
  }
}
