.quote-block {
  blockquote {
    @include responsive-font-size(30px, 50px);
    font-weight: 200;
    margin: 0;
    padding: 0;

    p {
      margin: 0;

      &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 2px;
        width: 40px;
        background-color: currentColor;
        margin-right: $spacing--s;
      }
    }

    footer {
      color: $color--slate;
    }
  }

  &__description {
    max-width: 600px;
    margin-top: $spacing--sm;
    margin-bottom: 0;
  }

  &__link {
    margin-top: $spacing--sm;
  }
}
