.video-list-block {

  &__content {
    padding: 0;
    margin: 0;

    @include breakpoint-s {
      margin-top: convert-to-rem(30px);
    }
  }

  &__heading {
    margin-bottom: $spacing--s;
    @include breakpoint-s {
      margin-bottom: $spacing--m;
    }
  }

  &__items {
    display: grid;
    align-items: start;
    justify-content: center;
    gap: $spacing--s;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    overflow: hidden;
  }

  &__link {
    margin-top: $spacing--sm;
    @include breakpoint-s {
      margin-top: $spacing--m;
    }
  }
}
