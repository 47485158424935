.column-image-block {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  figure {
    margin: 0;
  }

  figcaption {
    width: 100%;
  }

  &--left {
    justify-content: flex-start;
  }

  @include breakpoint-m {
    flex: 0 0 50%;
  }
}
