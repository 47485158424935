.video-list-item {
  all: unset;
  width: 100%;

  &__iframe{
    border: none;
    aspect-ratio: 16/9;
    width: 100%;
    margin-bottom: $spacing--xs;
  }

  &__caption {
    color: #5e706f;
    font-weight: normal;
    font-size: convert-to-rem(16px);
    line-height: 1.25;

    @include breakpoint-s {
      font-size: convert-to-rem(18px);
      line-height: 1.39;
    }
    @include breakpoint-m {
      width: 50%;
    }
  }
}
