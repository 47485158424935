.support-links {
  padding: $spacing--l;

  &__links {
    margin-top: $spacing--l;

    @include breakpoint-s {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__link-list {
    @include breakpoint-s {
      width: 45%;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }

  &__text {
    margin-top: $spacing--m;
  }

  &__cta {
    margin-top: $spacing--s;
    display: flex;
  }
}
