.contact-list {
  &__contacts {
    margin-top: $spacing--m;
    display: grid;
    grid-template-columns: 1fr;
    gap: $spacing--m;

    @include breakpoint-s {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint-ms {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
