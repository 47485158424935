.swiper-button {
  $self: &;

  @include button-style-reset;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  background-color: transparent;
  padding: 0;
  color: $color--slate;
  z-index: 10;

  &--full-width {
    width: 100%;
  }

  &--previous {
    .swiper-button__icon {
      transform: rotate(180deg);
    }
  }

  &__icon {
    height: 50px;
  }
}
