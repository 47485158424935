.menu-group {
  padding-bottom: 0.5rem;

  &__heading {
    margin-bottom: 1rem;
    color: $color--green-dark;
    display: block;

    @include breakpoint-mm {
      display: none;
    }
  }

  &__button {
    @include button-style-reset;
    display: flex;
    width: 100%;
    padding: 0;
    background-color: transparent;
    text-align: left;
    font-weight: 350;
    font-size: convert-to-rem(23px);
    line-height: 1.3;
    color: $color--black;

    &:hover {
      cursor: pointer;
    }
  }

  &__arrow {
    margin-top: 8px;
    margin-right: 1rem;

    &:before {
      content: '';
      display: block;
      width: 9px;
      height: 9px;
      transform: rotate(45deg);
      border: 2px solid $color--black;
      border-top: none;
      border-left: none;
      transition: all 0.3s linear;
    }
    &--active {
      margin-top: 12px;
      &:before {
        transform: rotate(-135deg);
      }
    }
  }

  &__title {
    margin-bottom: convert-to-rem(30px);
  }

  &__expanded-list {
    display: none;

    @include breakpoint-mm {
      display: flex;
      gap: 80px;
    }

    @include breakpoint-m {
      gap: 100px;
    }
  }

  &__dropdown-list {
    display: block;
    @include breakpoint-mm {
      display: none;
    }
  }

  &__items {
    list-style: none;
    margin: 0;
    margin-left: 2rem;
    padding: 0;
    max-width: 220px;
    min-width: 150px;

    @include breakpoint-mm {
      margin-left: 0;
    }
  }

  &__item {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 1rem;
    }

    @include breakpoint-mm {
      margin-bottom: 1rem;
    }
  }

  &__link {
    line-height: 1.3;
  }
}
