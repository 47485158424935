.image-with-caption {
  $self: &;
  margin: 0;

  @include breakpoint-m {
    flex: 0 0 50%;
    padding-right: 40px;

    &:last-child,
    &--in-portal-booking {
      padding-right: 0;
    }
  }

  & + #{$self} {
    margin: 30px 0 0;

    @include breakpoint-m {
      margin: 0;
      padding-right: 0;
    }
  }

  &__image {
    width: 100%;
    margin-bottom: 20px;
  }

  &__image-caption {
    color: #5e706f;
    font-weight: normal;
    font-size: convert-to-rem(16px);
    line-height: 1.25;

    @include breakpoint-s {
      font-size: convert-to-rem(18px);
      line-height: 1.39;
    }
    @include breakpoint-m {
      width: 50%;
    }

    #{$self}--in-portal-booking & {
      width: 100%;
    }
  }
}
