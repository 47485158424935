.icon-link-list {
  &__title {
    margin-bottom: $spacing--s;
  }
  &__items {
    @include responsive-font-size(23px, 25px);
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 1.5;

    & + & {
      margin-top: $spacing--m;
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;

    &:not(:first-child) {
      margin-top: $spacing--xs;
    }
    &-icon {
      margin-right: $spacing--xs;
      margin-bottom: 0.2em;
      margin-top: 0.25em;
      flex-shrink: 0;
      color: $color--red;
    }
  }
}
