.promoted-perk-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: $spacing--l;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @include breakpoint-l-max {
    display: none;
  }

  &__missing-text {
    margin-bottom: $spacing--s;

    @include breakpoint-sm {
      margin-bottom: $spacing--l;
    }
  }
}

.promoted-perk-slides {
  position: relative;
  @include breakpoint-l {
    display: none;
  }

  .swiper-slide {
    padding-top: 25px;
  }
}

.promoted-perk {
  @include underline-animation(expand, '.promoted-perk__heading-span');

  margin-top: 2rem;
  padding-top: 2rem;

  text-decoration: none;
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;

  &__image {
    padding-bottom: calc((225 / 325) * 100%);
  }

  &__image-container {
    position: relative;

    &-border {
      border-bottom: 1px solid #cdcdcd;
      border-top: 1px solid #cdcdcd;
    }
  }

  &__published {
    font-size: convert-to-rem(16px);
    color: $color--slate;
    font-family: $font-family-secondary;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: $spacing--s;
  }

  &__heading {
    color: $color--red;
    margin-top: $spacing--s;
    margin-bottom: 0;
    text-decoration: none;
    hyphens: auto;
  }

  &__text {
    margin-top: $spacing--xs;
    line-height: 1.35 !important;
  }

  &__tag {
    font-size: convert-to-rem(14px);
    font-family: $font-family-secondary;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.0625em;
    position: absolute;
    right: -10px;
    top: -25px;
    z-index: 1;
    width: 100px;
    height: 100px;
    padding: $spacing--xs;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    @include breakpoint-ms {
      right: 20px;
    }

    &--red {
      background-color: $color--red;
      color: white;
    }

    &--yellow {
      background-color: $color--yellow-strong;
      color: $color--green-dark;
    }

    &--green {
      background-color: $color--green-dark;
      color: white;
    }
  }

  &__bonus-link {
    margin-top: -30px;
  }

  &__navigation-wrapper {
    padding: 0.5rem 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
  }

  &__navigation-button {
    position: relative;
    width: auto;
    height: auto;
    margin: 0;
    left: auto;
    right: auto;

    &:after {
      content: none;
    }
  }

  &__navigation-text {
    color: $color--black;
    font-family: $font-family-secondary;
  }
}
