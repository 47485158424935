.housing-project-documents {
  $self: &;
  &--green,
  &--dark {
    color: $color--beige-dark;
  }

  &__items,
  &__documents {
    list-style: none;
    text-align: center;
    padding: 0;
  }

  &__items {
    margin: 0 $spacing--s;
    @include breakpoint-sm {
      max-width: 600px;
      margin: 0 auto;
    }
    @include breakpoint-m {
      max-width: 900px;
    }
  }

  &__item {
    border-top: 1px solid $color--beige-dark;
    padding: $spacing--l 0;
    &:last-of-type {
      border-bottom: 1px solid $color--beige-dark;
    }

    #{$self}--light & {
      border-color: $color--grey-green;
    }

    .content-wrapper {
      padding: 0;
    }
    .button {
      @include breakpoint-sm-max {
        min-height: 30px;
        padding: 0 convert-to-rem(24px);
      }
    }
  }

  &__item-content {
    @include housing-project-block-wrapper;
  }

  &__item-title {
    margin-bottom: 5px;
    #{$self}--dark &,
    #{$self}--green & {
      color: $color--beige-dark;
    }
    #{$self}--light & {
      color: $color--green-dark;
    }
  }

  &__item-text {
    max-width: 600px;
    margin: auto;
    line-height: 1.4;
    font-size: 16px;

    @include breakpoint-sm {
      font-size: 18px;
    }

    #{$self}--dark &,
    #{$self}--green & {
      color: $color--grey-green;
    }
  }

  &__documents {
    margin-top: $spacing--sm;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__document {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: $spacing--s;
    .button{
      @include breakpoint-m-max{
        font-size: convert-to-rem(14px);
        line-height: convert-to-rem(20px);
      }
    }
  }
}
