.alert-block {
  background: $color--beige;
  position: relative;

  &__content {
    display: flex;
    flex-wrap: wrap;
    padding: $spacing--s $spacing--m;
    @include breakpoint-s {
      padding: $spacing--sm $spacing--l;
    }
  }

  &__title,
  &__link,
  &__text-before-link,
  &__text-after-link {
    @include responsive-font-size(16px, 18px);
    color: $color--green-dark;
    line-height: 1.4;
  }

  &__link {
    @include underline-animation(shrink, '.alert-block__link-text');
    text-decoration: none;
    margin-right: $spacing--xs;
    margin-bottom: 3px;

    &-text {
      background-image: linear-gradient(
        $color--grey-green,
        $color--grey-green
      ) !important;
    }
  }

  &__title {
    font-weight: 500 !important;
    margin-right: $spacing--xs !important;
  }

  &__text-before-link {
    margin-right: $spacing--xs;
  }

  &__text-after-link {
    line-height: 1.33;
    @include breakpoint-s {
      line-height: 1.44;
    }
  }

  &__corner--bottom {
    &:before {
      bottom: 0;
      right: 0;
    }

    &:after {
      right: 10px;
      bottom: -10px;

      @include breakpoint-s {
        right: 13px;
        bottom: -13px;
      }
    }
  }

  &__corner {
    &:before {
      top: 0;
      left: 0;
    }

    &:after {
      top: -10px;
      left: 10px;

      @include breakpoint-s {
        top: -13px;
        left: 13px;
      }
    }
  }

  &__corner,
  &__corner--bottom {
    &:before {
      content: '';
      position: absolute;
      background: $color--orange;
      height: 30px;
      width: 10px;

      @include breakpoint-s {
        height: 40px;
        width: 14px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      background: $color--orange;
      height: 30px;

      width: 10px;
      transform: rotate(90deg);

      @include breakpoint-s {
        height: 40px;
        width: 14px;
      }
    }
  }
}
