.perk-list-page {
  &__perk-list,
  &__promoted,
  &__filters {
    margin-top: $spacing--xl;
  }

  &__promoted-perk-list {
    background-color: $color--beige;
    padding-block: $spacing--l;
    overflow: hidden;
  }

  &__perk-list {
    margin-bottom: $spacing--xl;
  }

  &__heading {
    margin-bottom: $spacing--l;
  }

  &__header {
    margin-top: $spacing--m;
    padding-right: $spacing--l;

    @include breakpoint-m {
      margin-top: $spacing--top-of-page;
      padding-right: 0;
    }
  }
}
