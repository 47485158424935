.inspo-header {
  position: relative;
  display: flex;
  flex-direction: column-reverse;

  @include breakpoint-mm {
    flex-direction: row;
    height: calc(80dvh - 130px);
    align-items: flex-start;
  }

  @include breakpoint-l {
    height: calc(80dvh - 80px);
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &__title{
    margin-bottom: $spacing--l;

    @include breakpoint-s {
      margin-bottom: $spacing--xxl;
    }
  }

  &__video-container {
    display: flex;
    justify-content: center;
  }

  &__image,
  &__video-container {
    animation: 1.5s fade-in;
    position: relative;
    height: calc(90dvh - 64px);
    width: 100%;
    overflow: hidden;

    @include breakpoint-s {
      height: calc(90dvh - 80px);
    }

    @include breakpoint-mm {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        180deg,
        rgba(0, 0, 20, 0.8),
        rgba(0, 0, 20, 0.001) 60%
          /* NOTE: rgba(black, 0) is translated into 'transparent' when compiling, which doesn't work in every browser */
      );
    }
  }

  &__content {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-top: convert-to-rem(42px);
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &--space-between {
      justify-content: space-between;
    }
  }

  &__page-link-wrapper {
    z-index: 10;
    width: 100%;
    max-width: $layout-max-width;
    margin: 0 auto;
    padding: 0 24px;

    @include breakpoint-s {
      padding: 0 32px;
    }
  }

  &--is-article-page {
    @include breakpoint-mm {
      flex-direction: row;
      height: calc(100dvh - 130px);
      align-items: flex-start;
    }

    @include breakpoint-l {
      height: calc(100dvh - 80px);
    }
  }
}
