.content-wrapper {
  @include content-wrapper-padding;

  .article-page__blocks .component-list-item:not(.component-list-item--wide) &{
    padding-left: 0;
    padding-right: 0;
  }

  &--in-housing-project-header{
    @include breakpoint-m {

      @media (max-height: 1000px){
        padding-left: $left-spacing;
      }
    }
  }

  &--small-screen-only {
    @include breakpoint-m {
      padding: 0;
    }
  }

  &--big-screen-only {
    @include breakpoint-m-max {
      padding: 0;
    }
  }
}
