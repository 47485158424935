.housing-project-image-gallery {
  @include housing-project-block-wrapper;
  padding-bottom: $spacing--m;
  @include breakpoint-m {
    padding-bottom: $spacing--l;
  }

  &__links {
    max-width: 600px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    padding: 0;
  }

  &__link {
    color: $color--grey-green !important;
    margin: 0 15px 15px 15px;
    font-size: 20px;

    @include breakpoint-m {
      margin: 0 $spacing--s;
    }
  }

  &__items {
    padding: 0;
    margin: 0;
  }
}
