.image-and-carousel {
  position: relative;

  @include breakpoint-m {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: $spacing--xxl;
  }

  &__left {
    width: 100%;

    @include breakpoint-m {
      width: 54.5%;
    }
  }

  &__image {
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-top: calc(
        (750 / 675) * 100%
      ); // NOTE: Magic dimensions from graphic design sketch
    }
  }

  &__image-text {
    @include responsive-font-size(23px, 25px);
    margin: 0;
    margin-top: $spacing--s;
    margin-left: $spacing--s;
    font-weight: 200;
    line-height: 1.6;

    @include breakpoint-s {
      margin-left: $spacing--l;
    }

    @include breakpoint-m {
      margin-top: $spacing--s;
      margin-left: $spacing--xxl;
    }
  }

  &__right {
    width: 100%;
    margin-top: $spacing--l;

    @include breakpoint-m {
      width: 40%;
      margin-top: 0;
    }
  }

  &__text {
    font-size: convert-to-rem(20px);
    margin-top: $spacing--s;
  }
}
