.promo-block {
  display: flex;
  flex-direction: column-reverse;
  text-decoration: none;

  @include underline-animation(expand, '.promo-block__title');
  @include underline-animation(expand, '.promo-block__cta');

  $padding-for-colored-box: 9%;

  @include breakpoint-s {
    align-items: center;
    flex-direction: row;

    &--left {
      flex-direction: row-reverse;

      .promo-block__content {
        padding-left: $spacing--s;
        padding-right: $spacing--s;
        @include breakpoint-m {
          padding-left: $spacing--m;
          padding-right: $spacing--xxl;
        }
      }
    }

    &--right {
      .promo-block__content {
        padding-right: $spacing--m;
        padding-left: $spacing--l;

        @include breakpoint-m {
          padding-left: $spacing--xxl;
          padding-right: $spacing--m;
        }
      }
    }
  }

  &--orange {
    .promo-block__corner-wrapper:before {
      background-color: $color--orange;
    }
  }

  &__content {
    word-break: break-word;
    hyphens: auto;
    @include content-wrapper-padding;
    margin-top: convert-to-rem(20px);

    @include breakpoint-s {
      width: 50%;
      padding-top: $spacing--xxl;
      margin-top: 0;
    }
  }

  &__ingress {
    font-size: convert-to-rem(40px);
    @include breakpoint-m {
      font-size: convert-to-rem(50px);
    }
    @include breakpoint-s-max {
      font-size: convert-to-rem(30px);
    }
    line-height: 1.24;
    color: $color--black;
    font-weight: 200;
    margin: 0;
  }

  &__cta-container {
    margin-top: convert-to-rem(30px);
  }

  &__cta {
    font-size: convert-to-rem(16px);
    font-family: $font-family-secondary;
    text-transform: uppercase;
    letter-spacing: 0.0625em;
    color: $color--black;
  }

  &__icon {
    display: inline;
    margin-left: $spacing--xs;
    color: $color--orange;
  }

  &__image-wrapper {
    @include breakpoint-s {
      width: 50%;
    }
  }

  &__image {
    position: relative;
    width: 100%;
    padding-top: 100%;
  }
}
