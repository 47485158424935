.inspo-article-page {
  &__header {
    margin-top: $spacing--sm;

    &--has-media-blocks {
      position: absolute;
      z-index: 99;
    }
  }

  &__link-container,
  &__content {
    width: 100%;
    margin-top: $spacing--l;
    @include breakpoint-m {
      width: 50%;
      margin-top: $spacing--xl;
    }
  }

  &__link {
    @include button-style-reset;
    @include btn-inspo;
    @include underline-animation(expand, '.inspo-article-page__link-span');
    width: max-content;
    padding: 5px $spacing--s;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    text-decoration: none;
    display: flex;

    @include breakpoint-s {
      padding: $spacing--xs $spacing--sm;
    }

    &-span {
      margin-top: 2px;
      background-image: linear-gradient(
        $color--grey-green,
        $color--grey-green
      ) !important;
    }

    &-icon {
      transform: rotate(180deg);
      margin-right: $spacing--s;
    }
  }
}
