.contact-info {
  @include breakpoint-s {
    display: flex;
    flex-wrap: wrap;
  }

  &--beige {
    padding: $spacing--m $spacing--l 0;
    background-color: $color--beige;
  }

  &--vertical {
    display: block;

    .contact-info__group {
      width: 100%;
    }
  }

  &__title {
    margin-bottom: $spacing--xs;
    color: $color--slate;
  }

  &__group {
    margin-bottom: $spacing--m;

    @include breakpoint-s {
      width: 33.3%;
    }
  }
}
