.link-list-block {
  @include responsive-font-size(23px, 25px);
  display: block;

  @include breakpoint-m {
    display: flex;
  }

  &__groups {
    display: block;
    @include breakpoint-s {
      display: flex;
      width: 50%;
    }
    @include breakpoint-m-max {
      width: 100%;
      justify-content: flex-start;
    }
  }

  &__heading-container {
    width: 100%;

    @include breakpoint-m {
      width: 50%;
    }
  }

  &__heading {
    width: 100%;
    @include breakpoint-m {
      width: 80%;
    }
  }

  &__group {
    margin-top: $spacing--s;
    line-height: 1.5;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-top: $spacing--m;
      @include breakpoint-s {
        margin-left: $spacing--l;
        margin-top: $spacing--s;
        &:first-child {
          margin-left: 0;
        }
      }
    }
    &--without-heading {
      margin-top: 0;
    }
  }
}
