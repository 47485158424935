.video__container {
  .video {
    height: 100vh;
    @include breakpoint-m {
      height: auto;
    }

    &--in-inspo-article-list {
      max-height: 500px;
    }

    &--in-inspo-header,
    &--in-inspo-full-width-media-block,
    &--in-housing-project-media-block {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      height: 100vh;
      max-height: none;
      max-width: none;
      min-height: 100%;
      min-width: 100%;
      transform: translate(-50%, -50%);

      @include breakpoint-s {
        height: auto;
      }
    }
  }

  &--in-inspo-article-list {
    position: absolute;
  }

  &--in-inspo-full-width-media-block,
  &--in-housing-project-media-block {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
