.membership-benefits-bonus-link {
  margin-bottom: $spacing--m;
  margin-top: -20px;

  @include breakpoint-m {
    margin-bottom: $spacing--m;
  }
  &__link {
    display: flex;
    align-items: flex-end;
    color: $color--green-dark;
    text-decoration: none;
    @include underline-animation(
      shrink,
      '.membership-benefits-bonus-link__link-text'
    );

    @include breakpoint-m {
      justify-content: center;
    }

    &-text {
      background-image: linear-gradient(
        $color--grey-green,
        $color--grey-green
      ) !important;
    }
  }

  &__icon {
    height: 20px;
    color: $color--slate;
    margin-left: $spacing--xs;
  }
}
