.columns {
  @include breakpoint-m {
    display: flex;
    justify-content: space-between;
  }

  & + & {
    margin-top: $spacing--l;
  }

  &--compact-mobile {
    @include breakpoint-m-max {
      .columns__main + .columns__sidebar,
      .columns__sidebar + .columns__main {
        margin-top: $spacing--m;
      }

      .columns + & {
        margin-top: $spacing--m;
      }
    }
  }

  &__main + &__sidebar {
    @include breakpoint-m-max {
      margin-top: $spacing--m;
    }
  }

  &__main {
    @include breakpoint-m {
      width: 50%;
    }
  }

  &__sidebar {
    @include breakpoint-m {
      width: 30%;
    }

    + .columns__main {
      @include breakpoint-m-max {
        margin-top: $spacing--xl;
      }
    }
  }

  &--wide-content {
    @include breakpoint-m {
      .columns__sidebar {
        width: 22%;
      }
      .columns__main {
        width: 67%;
      }
    }
  }
}
