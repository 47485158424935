.heading {
  margin: 0;
  font-weight: 200;

  &--color-white {
    color: white;
  }

  &--color-black {
    color: $color--black;
  }

  &--color-green-dark {
    color: $color--green-dark;
  }

  &--color-red {
    color: $color--red;
  }
  &--color-slate {
    color: $color--slate;
  }

  &--inherit {
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
  }

  &--h1 {
    font-size: convert-to-rem(40px);
    @include breakpoint-m {
      font-size: convert-to-rem(50px);
    }
    @include breakpoint-s-max {
      font-size: convert-to-rem(30px);
    }
    line-height: 1.24;
  }

  &--h2 {
    @include responsive-font-size(25px, 30px);
  }

  &--h3 {
    @include responsive-font-size(20px, 25px);
  }

  &--h3-portal,
  &--h3-portal-bold {
    @include responsive-font-size(23px, 25px);
    line-height: 1.375;

    @include breakpoint-s {
      line-height: 1.4;
    }
  }

  &--h3-portal-bold {
    font-weight: 500;
    color: $color--green-dark;
  }

  &--h4 {
    @include responsive-font-size(17px, 18px);
    font-weight: 500;
  }

  &--h5 {
    font-size: convert-to-rem(16px);
    font-family: $font-family-secondary;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.0625em;
  }

  &--h6 {
    @include responsive-font-size(13px, 16px);
    font-weight: normal;
    line-height: 1.4;
    text-transform: uppercase;
    letter-spacing: 0.0625em;
  }
}
