.residence-list-page {
  &__header {
    margin-top: $spacing--m;
    padding-right: $spacing--l;

    @include breakpoint-m {
      margin-top: $spacing--top-of-page;
      padding-right: 0;
    }
  }

  &__title {
    margin-bottom: $spacing--m;
    max-width: 700px;
    padding-right: $spacing--m;

    @include breakpoint-m {
      padding-right: 0;
    }
  }

  &__ingress {
    margin-top: $spacing--sm;
  }

  &__filters {
    margin-top: $spacing--xl;

    @include breakpoint-m {
      margin-top: $spacing--xxl;
    }
  }

  &__residences,
  &__blocks {
    margin-top: $spacing--l;
  }
}
