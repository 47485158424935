.image {
  max-width: 100%;
  height: auto;

  &--stretch {
    width: 100%;
  }

  &__background-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: 50% 50%;

    &--blend-mode {
      background-color: $color--beige-light;
      background-blend-mode: multiply;
    }
  }

  &--background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
  }
}
