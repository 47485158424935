.cognito-form {
  background-color: $color--beige !important;

  padding: $spacing--l;

  &__container {
    background-color: inherit !important;
    max-width: 600px;
  }

  .cog-form {
    background-color: inherit !important;

    &__container {
      background-color: inherit !important;
      padding: 0 !important;
    }

    &__content {
      background-color: inherit !important;
    }
  }

  .cog-body {
    background-color: inherit !important;
  }

  * {
    font-family: $font-family !important;
    font-weight: normal;
  }

  &__title {
    margin-bottom: $spacing--xs;
  }

  h2 {
    font-weight: 200 !important;
  }

  .cog-page {
    padding: 0 !important;

    .cog-row {
      margin-top: $spacing--s !important;
    }
  }

  button[type="submit"] {
    width: fit-content !important;
    height: 40px !important;
    border-radius: 20px !important;
    padding: 0 20px !important;
  }
}
