.page-list-page {
  &__page-list,
  &__promoted,
  &__filters {
    margin-top: $spacing--xl;
  }

  &__page-list {
    margin-bottom: $spacing--xl;
  }

  &__header {
    margin-top: $spacing--m;
    padding-right: $spacing--l;

    @include breakpoint-m {
      margin-top: $spacing--top-of-page;
      padding-right: 0;
    }
  }

  &__promoted {
    @include breakpoint-m {
      padding-right: $spacing--xl;
    }
  }
}
