.residence-list {
  @include breakpoint-m {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {
    @include responsive-margin($spacing--l, $spacing--l, $spacing--xxl);
    @include underline-animation(shrink, '.residence-list__item-title-span');
    display: flex;
    flex-direction: column-reverse;
    text-decoration: none;

    &:first-child {
      margin-top: 0;
    }

    @include breakpoint-m {
      width: 46.5%;

      &:nth-child(2) {
        margin-top: 0;
      }
    }
  }

  &__item-title {
    @include responsive-font-size(25px, 30px);
    @include responsive-margin(25px, $spacing--m);
    color: $color--slate;
    line-height: 1.4;

    &-icon {
      margin-left: 15px;
    }
  }

  &__item-subtitle,
  &__item-tag {
    @include responsive-font-size(13px, 16px);
    text-transform: uppercase;
    letter-spacing: 0.0625em;
    color: $color--green-dark;
  }

  &__item-subtitle {
    margin-top: 15px;
    margin-bottom: 0;
  }

  &__item-image-wrapper {
    position: relative;
    width: 100%;
    padding-top: calc(
      (440 / 630) * 100%
    ); // NOTE: Magic numbers from design sketches
  }

  &__item-image {
    width: 100%;
    display: block;
  }

  &__item-tag {
    position: absolute;
    width: 100px;
    height: 100px;
    right: 3.2%; // NOTE: Magic number. The designer wanted ~20px on desktop
    bottom: 30px;
    padding: $spacing--xs;
    transform: translateY(50%);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: white;
    border: 1px solid $color--slate;
    font-size: convert-to-rem(14px);
    font-family: $font-family-secondary;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1em;
    color: $color--slate;
    line-height: 1.25em;
  }
}
