@mixin breakpoint-xs {
  @media screen and (min-width: $breakpoint-xs) {
    @content;
  }
}

@mixin breakpoint-s {
  @media screen and (min-width: $breakpoint-s) {
    @content;
  }
}

@mixin breakpoint-m {
  @media screen and (min-width: $breakpoint-m) {
    @content;
  }
}

@mixin breakpoint-mm {
  @media screen and (min-width: $breakpoint-mm) {
    @content;
  }
}

@mixin breakpoint-xm {
  @media screen and (min-width: $breakpoint-xm) {
    @content;
  }
}

@mixin breakpoint-l {
  @media screen and (min-width: $breakpoint-l) {
    @content;
  }
}

@mixin breakpoint-ll {
  @media screen and (min-width: $breakpoint-ll) {
    @content;
  }
}

@mixin breakpoint-xl {
  @media screen and (min-width: $breakpoint-xl) {
    @content;
  }
}

@mixin breakpoint-s-max {
  @media screen and (max-width: $breakpoint-s - 1px) {
    @content;
  }
}

@mixin breakpoint-m-max {
  @media screen and (max-width: $breakpoint-m - 1px) {
    @content;
  }
}

@mixin breakpoint-l-max {
  @media screen and (max-width: $breakpoint-l - 1px) {
    @content;
  }
}

@mixin breakpoint-ms-max {
  @media screen and (max-width: $breakpoint-ms - 1px) {
    @content;
  }
}

@mixin breakpoint-sm-max {
  @media screen and (max-width: $breakpoint-ms - 1px) {
    @content;
  }
}

// breakpoint for the frontpage block to break to mobile early
@mixin breakpoint-ms {
  @media screen and (min-width: $breakpoint-ms) {
    @content;
  }
}

// breakpoint to make blocks fit better on ipad sizes (portal)
@mixin breakpoint-sm {
  @media screen and (min-width: $breakpoint-sm) {
    @content;
  }
}

@mixin responsive-font-size($mobile-size, $desktop-size) {
  font-size: convert-to-rem($mobile-size);

  @include breakpoint-s {
    font-size: convert-to-rem($desktop-size);
  }
}

@mixin font-size($size) {
  font-size: convert-to-rem($size);
}

@mixin responsive-margin($mobile-size, $medium-size, $desktop-size: false) {
  margin-bottom: $mobile-size;

  @include breakpoint-s {
    margin-bottom: $medium-size;
  }

  @if ($desktop-size) {
    @include breakpoint-m {
      margin-bottom: $desktop-size;
    }
  }
}

@mixin responsive-padding($mobile-size, $medium-size, $desktop-size: false) {
  padding-top: $mobile-size;
  padding-bottom: $mobile-size;

  @include breakpoint-s {
    padding-top: $medium-size;
    padding-bottom: $medium-size;
  }

  @if ($desktop-size) {
    @include breakpoint-m {
      padding-top: $desktop-size;
      padding-bottom: $desktop-size;
    }
  }
}

@mixin content-wrapper-padding {
  padding-left: $spacing--s;
  padding-right: $spacing--s;

  @include breakpoint-s {
    padding-left: $spacing--l;
    padding-right: $spacing--l;
  }

  @include breakpoint-m {
    padding-left: $spacing--xxl;
    padding-right: $spacing--xxl;
  }
}

@mixin full-width-block {
  $desktop-margin: calc((100vw - 100% - 8px) / 2 * -1);

  margin-left: -$spacing--s;
  margin-right: -$spacing--s;
  width: calc(100% + #{$spacing--s * 2});

  @include breakpoint-s {
    margin-left: -$spacing--l;
    margin-right: -$spacing--l;
    width: calc(100% + #{$spacing--l * 2});
  }

  @include breakpoint-m {
    margin-left: $desktop-margin;
    margin-right: $desktop-margin;
    width: calc(100% + #{$desktop-margin}* 2 * -1);
    max-width: 100vw;
  }
}

@mixin portal-content-wrapper-padding {
  padding-left: $spacing--s;
  padding-right: $spacing--s;

  @include breakpoint-s {
    padding-left: $spacing--m;
    padding-right: $spacing--m;
  }

  @include breakpoint-m {
    padding-left: $spacing--l;
    padding-right: $spacing--l;
  }
}

@mixin visually-hidden {
  position: absolute;
  left: -999em;
  clip: rect(0, 0, 0, 0);
  opacity: 0;
  overflow: hidden;
}
@mixin clear-visibility-hidden {
  display: block;
  position: static;
  left: 0;
  clip: rect(1, 1, 1, 1);
  opacity: 1;
}

@mixin button-style-reset {
  appearance: none;
  border: none;
  font-family: inherit;
}

@mixin underline-animation(
  $theme,
  $target,
  $useColor: false,
  $alpha: 1,
  $color: rgb(255, 255, 255)
) {
  $colorAlpha: rgba($color, $alpha);

  //Target needs to be a span element for the animation to work correctly
  #{$target} {
    padding-bottom: 2px;
    @if $useColor {
      background-image: linear-gradient($colorAlpha, $colorAlpha);
    } @else {
      background-image: linear-gradient(currentColor, currentColor);
    }
    background-repeat: no-repeat;
    transition: background-size 0.5s;
  }

  @if $theme == expand {
    #{$target} {
      background-size: 0% 1px;
      background-position: 0% 100%;
    }

    &:hover #{$target},
    &:focus #{$target} {
      background-size: 100% 1px;
    }
    &:focus #{$target} {
      .mouse-user & {
        background-size: 0% 1px;
      }
    }
  }

  @if $theme == shrink {
    #{$target} {
      background-position: 0% 100%;
      background-size: 100% 1px;
    }
    &:hover #{$target},
    &:focus #{$target} {
      background-size: 0% 1px;
    }

    &:focus #{$target} {
      .mouse-user & {
        background-size: 100% 1px;
      }
    }
  }
}

@mixin portal-block {
  background-color: white;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
}

@mixin portal-block-padding {
  @include breakpoint-s {
    padding: $spacing--sm $spacing--sm $spacing--m $spacing--sm;
  }
  padding: $spacing--s;
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

@mixin btn {
  width: 100%;
  min-height: 40px;
  border-radius: 25px;
  border: none;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-family: $font-family;
  font-size: convert-to-rem(16px);
  cursor: pointer;

  .mouse-user & {
    outline: none;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

@mixin btn-primary {
  @include btn;
  background: $color--orange;
  color: $color--black;
}

@mixin btn-confirm {
  @include btn;
  background: $color--green;
  color: white;
}

@mixin btn-secondary {
  @include btn;
  background: $color--slate;
  color: white;
}

@mixin btn-tertiary {
  @include btn;
  background: white;
  color: $color--slate;
  border: 1px solid $color--slate;

  &:hover,
  &:focus {
    background: $color--green-dark;
    color: white;
    text-decoration: none;
    border-color: $color--green-dark;
    text-decoration: none;

    @include breakpoint-m {
      text-decoration: underline;
      background: white;
      color: $color--slate;
      border-color: $color--slate;
    }
  }
}

@mixin btn-inspo {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
  font-family: 'National2Condensed';
  font-size: convert-to-rem(14px);
  color: $color--slate;
  border-radius: 25px;
  background-color: white;
}

@mixin portal-popup-spacing {
  padding: $spacing--m $spacing--sm $spacing--sm;
  @include breakpoint-s {
    padding: $spacing--l $spacing--sm $spacing--sm;
  }
}

@mixin cf {
  &:after {
    content: '';
    display: table;
    width: 100%;
    clear: both;
    overflow: hidden;
  }
}

@mixin order-key-breakpoint {
  @media (min-width: 768px) and (max-width: 999px) {
    @content;
  }
  @include breakpoint-xm {
    @content;
  }
}

@mixin booking-breakpoint {
  @media (min-width: 864px) and (max-width: 999px) {
    @content;
  }
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin scrollbar($foreground-color, $background-color, $hover-color) {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $hover-color;
  }
}

@mixin pulsing-dot($width, $height) {
  @keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.75);
      opacity: 0;
    }
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    display: block;
    width: $width;
    height: $height;
    background: $color--orange-light;
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: $width;
    height: $height;
    background: $color--orange;
    border-radius: 50%;
    transition: all 200ms;
  }
}

@mixin block-animation {
  opacity: 0;
  will-change: opacity;
  transform: translateY(10vh);

  &--is-visible {
    opacity: 1;
    transform: none;
    transition: opacity 0.6s ease-out, transform 0.8s ease-out;
  }
}

@mixin block-ingress {
  @include responsive-font-size(16px, 18px);
  line-height: 1.389;
}

@mixin last-expander {
  border-bottom: solid 1px $color--grey-green;
}

@mixin title-gradient($deg, $gradientcolor1, $gradientcolor2) {
  background: linear-gradient($deg, $gradientcolor1, $gradientcolor2);
  background-clip: padding-box;
  background-size: 0%;
}

@mixin housing-project-block-wrapper {
  max-width: $content-max-width;
  margin: auto;
}
