.support {
  $button-size-s: $corner-width-s;
  $button-size-m: $corner-width-m;
  $button-size-l: 100px;
  $button-offset-l: calc(($corner-width-l - $button-size-l) / 2);

  &__buttons {
    position: fixed;
    right: 0;
    z-index: 56;
    bottom: 0;
    transition: transform 0.4s ease-out;

    &--offset {
      transform: translateY(calc($button-size-s / 2));

      @include breakpoint-s {
        transform: translateY(calc($button-size-m / 2));
      }

      @include breakpoint-m {
        transform: translateY(calc($button-size-l / 2));
      }
    }
  }

  @keyframes scale-in {
    from {
      transform: scale(0);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  &__link,
  &__button {
    @include responsive-font-size(13px, 18px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: $button-size-s;
    height: $button-size-s;
    padding: 0 0.5em;
    border-radius: 100%;
    font-weight: 500;
    animation: scale-in 0.7s 1s cubic-bezier(0.3, 1.4, 0.4, 1) both;

    @include breakpoint-s {
      height: $button-size-m;
      width: $button-size-m;
    }

    @include breakpoint-m {
      height: $button-size-l;
      width: $button-size-l;
      padding: 0 1em;
    }

    &:hover span,
    &:focus span {
      text-decoration: underline;
    }
  }

  &__link {
    color: $color--black;
    text-align: center;
    text-decoration: none;

    &--orange {
      background-color: $color--orange;
    }

    &--pink {
      background-color: $color--pink;
    }

    &--pink-light {
      background-color: $color--pink-light;
    }

    &--aqua {
      background-color: $color--aqua;
    }
  }

  &__button {
    appearance: none;
    border: none;
    background-color: $color--red;
    color: white;
    font-family: inherit;
    animation-delay: 1.1s;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &-chat-icon {
      width: 15px;
      margin-bottom: 1px;
      fill: white;

      @include breakpoint-s {
        width: 18px;
        margin-bottom: 3px;
      }
    }
  }

  &__contact-form-container,
  &__support-links-container {
    z-index: 99;
    position: fixed;
  }
}

#zissonWebChat.closed {
  width: 0 !important;
  opacity: 0;
  height: 0 !important;
}
