.housing-project-scrolling-images {
  $self: &;
  display: block;

  @include breakpoint-ms {
    display: flex;
  }
  @include breakpoint-ms-max {
    padding-bottom: $spacing--m;
  }

  &--dark,
  &--green {
    color: $color--beige-dark;
    .rich-text {
      color: $color--beige-dark;
    }
  }

  &--light {
    color: $color--black;
    .rich-text {
      color: $color--black;
    }
  }

  &__text-content {
    width: 100%;

    @include breakpoint-ms {
      width: 50%;
      position: sticky;
      top: 0;
      height: 100%;
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    max-width: 700px;
    margin: auto;
    padding: $spacing--m 0;

    @include breakpoint-ms {
      height: 80vh;
      margin: auto;
      padding: 0;
    }

    .content-wrapper {
      @include breakpoint-m {
        padding: 0 $spacing--l;
      }
    }
  }

  &__title {
    margin-bottom: $spacing--xs;
    #{$self}--dark &,
    #{$self}--green & {
      color: $color--beige-dark;
    }

    #{$self}--light & {
      color: $color--black;
    }
  }

  &__text {
    margin-bottom: $spacing--sm;
  }

  &__link {
    width: fit-content;
    margin: auto;
  }

  &__image-list {
    width: 100%;

    @include breakpoint-ms {
      width: 50%;
    }
    @include breakpoint-ms-max {
      padding: 0 $spacing--s;
    }
  }
  &__image-container {
    width: 100%;
    @include breakpoint-ms-max {
      margin: $spacing--s 0;
      &:first-of-type {
        margin-top: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    padding-top: 100%;
    height: 80vh;
    @include breakpoint-ms-max {
      height: 40vh;
    }
  }
}
