.header {
  $self: &;

  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  transition: transform 0.3s linear;

  &--hide-menu {
    transform: translateY(-75%);
    transition: transform 0.3s linear 0.2s;

    @include breakpoint-mm {
      transform: translateY(-100%);
    }
  }

  &__background-shadow {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    z-index: -1;
    transition: background-color ease-in-out 300ms;
    pointer-events: none;

    &--active {
      background-color: rgba(38, 38, 38, 0.6);
    }
  }

  &__container {
    position: relative;
    width: 100%;
    background-color: $color--green-dark;
  }

  &__content {
    width: 100%;
    max-width: $layout-max-width;
    margin: 0 auto;
    display: flex;
    padding: 8px 24px;

    @include breakpoint-s {
      padding: 20px 32px;
    }

    @include breakpoint-mm {
      display: grid;
      grid-template-areas:
        'logo buttons'
        'menu menu';
    }
    @include breakpoint-l {
      display: flex;
    }
  }

  &__logo-container {
    grid-area: logo;
    display: flex;
    align-items: center;

    @include breakpoint-l {
      margin-right: 3.125rem;
    }
  }

  &__menu-headers-container {
    grid-area: menu;
    justify-self: flex-end;

    @include breakpoint-mm {
      margin-top: 24px;
    }
    @include breakpoint-l {
      margin-top: 0;
    }
  }

  &__action-buttons {
    grid-area: buttons;
    display: flex;
    align-items: center;
    justify-self: flex-end;
    margin-left: auto;
  }

  &__toggle-button-menu {
    display: block;

    @include breakpoint-mm {
      display: none;
    }
  }

  &__login-links {
    display: none;

    @include breakpoint-mm {
      display: block;
    }
  }

  &__menu {
    width: 100%;

    &--open {
      height: auto;
    }
  }

  &__search {
  }

  &__search-wrapper {
    width: 100%;
    padding: 2.5rem 1.5rem;
    background-color: $color--green-dark;

    @include breakpoint-mm {
      padding-top: 2rem;
      min-height: 170px;
    }
  }
}

.header-toggle-button {
  $self: &;

  &__button {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    background-color: transparent;
    padding: 0.75rem;
    border: none;
    border-radius: 20px;
    color: $color--yellow;
    font-family: $font-family;

    @include breakpoint-mm {
      border: 1px solid $color--yellow;
      padding: 0.375rem 1rem;
    }

    &:hover {
      cursor: pointer;

      #{$self}__text {
        border-bottom: 1px solid $color--yellow;
      }
    }
  }

  &__icon {
    width: 24px;
    height: 24px;

    @include breakpoint-mm {
      width: 17px;
      height: 17px;
    }
  }

  &__text {
    display: none;
    font-size: 1rem;

    @include breakpoint-mm {
      display: block;
      border-bottom: 1px solid transparent;
    }
  }

  &--open {
  }
}
