.housing-project-block-header {
  $self: &;

  &__title,
  &__description,
  &__link-container {
    text-align: center;
    margin: auto;
  }

  &__title {
    margin-bottom: $spacing--xs;
  }

  &__description,
  &__link-container {
    max-width: 600px;
    margin-bottom: $spacing--m;
  }

  &__description {
    font-size: convert-to-rem(20px);
    line-height: convert-to-rem(28px);
    font-weight: 200;
  }

  &__description-low-margin {
    margin-bottom: $spacing--s;
  }

  &__link {
    font-size: 20px;
    #{$self}--dark & {
      color: $color--beige-dark;
      .link__text {
        background-image: linear-gradient($color--grey, $color--grey);
      }
    }

    #{$self}--green & {
      color: $color--housing-project-green;
    }

    #{$self}--light & {
      color: $color--black;
      .link__text {
        background-image: linear-gradient($color--grey, $color--grey);
      }
    }
  }
}
