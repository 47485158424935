.radio {
  &--link {
    color: $color--slate;

    input {
      @include visually-hidden;

      &:checked + label {
        color: $color--black;
        border-bottom: 10px solid currentColor;
      }
    }

    label {
      @include underline-animation(shrink, '.radio-label');
    }
  }

  &--button {
    @include btn-inspo;
    margin: 0 15px 35px 0;

    @include breakpoint-s {
      @include underline-animation(expand, '.radio-label');
    }

    input {
      @include visually-hidden;

      &:checked + label {
        background-color: white;
        color: $color--green-dark;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
        border: 1px solid $color--grey-green;
      }
    }

    label {
      border-radius: 25px;
      background-color: white;
      padding: 5px 15px;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid transparent;

      @include breakpoint-s {
        padding: 10px 15px;
      }
    }
  }
}
