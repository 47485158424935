.housing-project-promo-block {
  $self: &;
  @include housing-project-block-wrapper;
  &__content {
    display: flex;
    flex-direction: column;

    @include breakpoint-m {
      flex-direction: row-reverse;
    }
  }
  &__image-container {
    width: 100%;
    @include breakpoint-m {
      width: 50%;
    }
  }

  &__image {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
  }

  &__content {
    @include underline-animation(shrink, '.housing-project-promo-block__link');
    text-decoration: none;
    align-items: center;
  }

  &__text-container {
    margin-left: 0;
    text-align: center;
    margin-bottom: $spacing--m;
    @include breakpoint-m {
      width: 50%;
      margin-left: $spacing--xl;
      text-align: left;
      margin-bottom: 0;
    }
  }

  &__link {
    line-height: convert-to-rem(28px);
    font-size: convert-to-rem(20px);
    #{$self}--dark & {
      color: $color--grey-green;
    }

    #{$self}--light &,
    .article-page__blocks & {
      color: $color--grey;
    }
  }

  &__title {
    line-height: convert-to-rem(36px);
    @include breakpoint-m {
      line-height: convert-to-rem(42px);
    }
    margin-bottom: $spacing--s;

    #{$self}--dark & {
      color: $color--beige-dark;
    }

    #{$self}--light &,
    .article-page__blocks & {
      color: $color--black;
    }
  }
}
