$corner-size: 40px;
$corner-size-mobile: 30px;
$desktop-margin: calc((100vw - 100% - 17px) / 2 * -1);

.full-width-quote-block {
  $self: &;

  &.--green {
    display: flex;
    justify-content: center;
    background-color: $color--green-dark;
    @include content-wrapper-padding;

    padding-top: $spacing--m;
    padding-bottom: $spacing--m;

    @include breakpoint-ms {
      padding-top: $spacing--xl;
      padding-bottom: $spacing--xl;
    }

    .content-wrapper & {
      @include breakpoint-m {
        margin-left: $desktop-margin;
        margin-right: $desktop-margin;
        width: calc(100% + #{$desktop-margin}* 2 * -1);
        max-width: 100vw;
      }
    }
  }

  &__content {
    .--green & {
      margin: 0;
      max-width: 72rem;
    }

    position: relative;
    padding-top: $corner-size-mobile;
    padding-left: $corner-size-mobile;
    color: $color--slate;
    font-weight: 300;
    font-family: $font-family;

    @include breakpoint-s {
      padding-top: $corner-size;
      padding-left: $corner-size;
    }

    .component-list-item + .component-list-item & {
      margin: 0;
    }

    .component-list-item + .component-list-item--is-inspo-article & {
      margin: 0;
      @include breakpoint-s {
        margin-top: -20px;
        margin-bottom: -20px;
      }
    }

    &:before {
      content: '';
      border-top: 10px solid $color--slate;
      border-left: 10px solid $color--slate;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: $corner-size-mobile;
      height: $corner-size-mobile;

      .--green & {
        border-top: 10px solid $color--yellow;
        border-left: 10px solid $color--yellow;
      }

      @include breakpoint-s {
        border-top-width: 13px;
        border-left-width: 13px;
        width: $corner-size;
        height: $corner-size;
      }
    }
  }

  &__text {
    font-size: convert-to-rem(30px);
    line-height: 1.23;
    font-weight: inherit;
    font-family: inherit;
    color: inherit;
    margin: 0;

    .--green & {
      color: $color--yellow;
    }

    @include breakpoint-s {
      font-size: convert-to-rem(50px);
      line-height: 1.24;
    }
  }

  &__author {
    font-weight: inherit;
    font-family: inherit;
    color: inherit;
    font-size: convert-to-rem(23px);
    line-height: 1.3;

    .--green & {
      color: $color--yellow;
    }

    @include breakpoint-s {
      font-size: convert-to-rem(25px);
      line-height: 1.44;
    }

    #{$self}__text + & {
      margin-top: 20px;
    }
  }
}
