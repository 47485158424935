.call-to-action {
  display: flex;

  &--right {
    justify-content: flex-end;
  }

  &__content {
    width: 100%;

    @include breakpoint-m {
      width: 50%;
    }
  }

  &__text {
    @include responsive-font-size(30px, 50px);
    font-weight: 100;
    margin-bottom: $spacing--s;
    line-height: 1.24;
  }

  &__link {
    width: 100%;

    &--green {
      background-color: $color--slate !important;
      color: white !important;
    }

    &--yellow {
      background-color: $color--yellow !important;
      color: $color--green-dark !important;
    }

    &--red {
      background-color: $color--red-strong !important;
      color: white !important;
    }
  }
}


