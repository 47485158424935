@keyframes shrink-animation {
  0% {
    scale: (1.15);
  }
  100% {
    opacity: 1;
    scale: (1);
  }
}

.housing-project-header {
  $self: &;

  &__image {
    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      70% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    animation: 2.5s fade-in;
    width: 100%;
    height: 50vh;

    @include breakpoint-m {
      height: 100vh;
    }

    &-mobile {
      display: block;
      @include breakpoint-m {
        display: none;
      }
    }

    &-desktop {
      display: none;
      @include breakpoint-m {
        display: block;
      }
    }
  }

  &__image-container {
    background: black;
    position: relative;
    height: 50vh;

    @include breakpoint-m {
      height: 100vh;
    }
  }

  &__shadow {
    @keyframes fade-in-shadow {
      0% {
        opacity: 0;
      }
      60% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes fade-in-shadow-mobile {
      0% {
        opacity: 0;
      }
      60% {
        opacity: 0;
      }
      100% {
        opacity: 0.5;
      }
    }

    @include breakpoint-m {
      width: 85%;
      height: 100%;
      background: linear-gradient(90deg, #000000 0.2%, #ffffff 85.31%);
      animation: 2.5s fade-in-shadow;
      opacity: 1;
    }

    mix-blend-mode: multiply;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(255, 255, 255, 1) 45%,
      rgba(255, 255, 255, 1) 50%,
      rgba(0, 0, 0, 1) 100%
    );
    mix-blend-mode: multiply;
    height: 50vh;
    width: 100%;
    opacity: 0.5;
    animation: 2.5s fade-in-shadow-mobile;
  }

  &__corner-shadow {
    display: none;
    @include breakpoint-m {
      width: 100%;
      background: linear-gradient(
        30deg,
        rgba(255, 255, 255, 1) 80%,
        rgba(0, 0, 0, 1) 100%
      );
      mix-blend-mode: multiply;
      position: absolute;
      opacity: 0.5;
      height: 100%;
      top: 0;
    }
  }

  &__content {
    position: absolute;
    width: 50%;
    bottom: 0;
  }

  &__logo-container {
    margin-top: $spacing--s;

    width: fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr;

    column-gap: $spacing--s;
    row-gap: $spacing--xs;
    margin-bottom: $spacing--m;

    @include breakpoint-m {
      column-gap: $spacing--sm;
      row-gap: $spacing--s;
      margin-bottom: $spacing--xxl;
    }
  }

  &__logo {
    max-width: 150px !important;
    max-height: 150px;
    object-fit: contain;
    justify-self: center;
    align-self: center;
    opacity: 0;
    //animation: shrink-animation 2s ease-in-out;

    @include breakpoint-m {
      max-width: 200px !important;
    }

    &:nth-child(1) {
      animation: shrink-animation 2s ease-in-out 0ms forwards;
    }
    &:nth-child(2) {
      animation: shrink-animation 2s ease-in-out 500ms forwards;
    }
    &:nth-child(3) {
      animation: shrink-animation 2s ease-in-out 1000ms forwards;
    }
    &:nth-child(4) {
      animation: shrink-animation 2s ease-in-out 1500ms forwards;
    }
  }

  &__title {
    display: none;

    @include breakpoint-m {
      display: block;
    }
  }

  &__title--mobile {
    #{self}--light {
    }
    #{$self}--dark & {
      background: $color--black;
    }

    display: block;
    padding-top: $spacing--sm;

    &-text {
      #{$self}--light & {
        color: $color--black;
      }
      color: $color--grey-green;
      font-size: 23px;
    }

    @include breakpoint-m {
      display: none;
    }
  }
}
