.housing-project-list-with-icons-block {
  $self: &;
  @include housing-project-block-wrapper;
  &--dark,
  &--green {
    color: $color--beige-light;
  }

  &__plx-wrapper {
    width: auto !important;
    position: relative !important;
    display: flex;
    flex-direction: column;
  }

  &__items {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin-top: $spacing--m;

    @include breakpoint-ms {
      margin-top: $spacing--l;
      align-items: flex-start;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: $spacing--m;
      grid-row-gap: $spacing--l;
    }

    &--3 {
      grid-template-columns: repeat(3, 1fr);
    }

    &--2 {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__item {
    text-align: center;
    margin-bottom: $spacing--l;
    display: flex;
    justify-content: center;
    @include breakpoint-ms {
      margin-bottom: 0;
    }
    @include breakpoint-ms-max {
      max-width: 450px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }

    &--5 {
      grid-column: span 2;

      &:nth-last-child(2) {
        grid-column: 2 / 4;
      }

      &:last-child {
        grid-column: 4 / 6;
      }
    }

    &-image {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 130px;
      .image {
        max-height: 80px;
      }

      img.image {
        object-fit: contain;
        #{$self}--light &,
        #{$self}--accent-green & {
          filter: brightness(0) invert(0.2);
        }
        #{$self}--accent-red & {
          filter: brightness(0) invert(1);
        }
      }
    }

    &-text-content {
      flex: 1;
    }

    &-title {
      margin: $spacing--s 0 $spacing--xs 0;
      line-height: convert-to-rem(38px);
    }

    &-text {
      font-weight: 200;
      line-height: convert-to-rem(28px);
      margin-bottom: $spacing--xs;
      font-size: convert-to-rem(18px);
      @include breakpoint-m {
        font-size: convert-to-rem(20px);
      }
      #{$self}--dark &,
      #{$self}--green & {
        color: $color--beige-light;
      }
    }

    &-link {
      justify-self: flex-end;
      #{$self}--dark & {
        color: $color--beige-light;
      }
      #{$self}--green & {
        color: $color--housing-project-green;
      }
      #{$self}--light & {
        color: $color--black;
      }
      .link__text{
        font-size: convert-to-rem(18px);
        @include breakpoint-m{
          font-size: convert-to-rem(20px);
          line-height: convert-to-rem(28px);
        }
      }
    }
  }
}
