.component-list-item {
  width: 100%;

  &--is-portal {
    @include cf;
  }

  &--no-padding {
    padding: 0 !important;
  }

  &--wide {
    width: calc(100% + 2 * #{$spacing--s});
    margin-left: -$spacing--s;

    @include breakpoint-s {
      width: calc(100% + 2 * #{$spacing--l});
      margin-left: -$spacing--l;
    }

    @include breakpoint-m {
      width: calc(100% + 2 * #{$spacing--xxl});
      margin-left: -$spacing--xxl;
    }
  }

  & + &,
  & {
    @include responsive-margin($spacing--l, $spacing--xl);
    &--is-portal {
      @include responsive-margin($spacing--sm, $spacing--l);
    }

    &--is-inspo-article {
      @include responsive-margin($spacing--l, $spacing--xl);
    }

    &--white-background,
    &--grey-background,
    &--beige-background,
    &--black-background,
    &--transparent-background {
      width: 100%;
      margin: 0;
      @include responsive-padding($spacing--m, $spacing--m, $spacing--xl);
    }
  }
  @include breakpoint-m {
    &--white-background + &--white-background,
    &--black-background + &--black-background,
    &--beige-background + &--beige-background,
    &--grey-background + &--grey-background {
      padding-top: 0;
    }
  }

  &:first-child {
    padding-top: 0;
  }

  & + &--small-margin {
    @include responsive-margin($spacing--m, $spacing--l, $spacing--l);
  }

  & + &--no-margin {
    margin-top: 0;

    &:last-child {
      margin-bottom: $spacing--xl;
    }
  }

  &--visible-on-scroll {
    @include block-animation;
  }

  &--grey-background {
    background: $color--black;
  }

  &--white-background {
    background: white;
  }

  &--beige-background {
    background: $color--beige;
  }

  &--black-background {
    background: black;
  }

  &--transparent-background {
    background: transparent;
  }

  > * {
    // Ensures that floating styles in blocks don't cause problems with other blocks. Doing this on the direct children of .component-list-item allows individual blocks to override this behaviour.
    clear: both;
  }

  &--fallback {
    border: 1px solid $color--red;
    padding: 20px;
  }
}
