.inspo-promo-block {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px $color--grey-light;

  &__content {
    padding: $spacing--s;
    display: flex;
    align-items: center;

    @include breakpoint-m {
      display: block;
      padding: $spacing--sm;
    }
  }

  &__image {
    position: relative;
    padding-bottom: 34%;
    width: 50%;

    @include breakpoint-m {
      padding-bottom: 69%;
      width: 100%;
    }
  }

  &__link-container {
    @include underline-animation('expand', '.inspo-promo-block__link-span');
    width: 100%;
    background-color: $color--slate;
    display: flex;
    justify-content: center;
    padding: $spacing--s;
  }

  &__link {
    font-size: convert-to-rem(16px);
    text-decoration: none;
    text-transform: uppercase;
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
  }
}
