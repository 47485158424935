.inspo-bullet-list {
  display: flex;
  justify-content: flex-end;

  &__content {
    background-color: $color--beige-light;
    color: $color--slate;
    width: 100%;
    position: relative;
    padding: $spacing--s;
    font-weight: 500;
    margin-bottom: $spacing--l;

    @include breakpoint-m {
      flex: 0 0 50%;
    }

    @include breakpoint-s {
      padding: $spacing--m;
    }
  }

  &__corner {
    &:before {
      content: '';
      position: absolute;
      background: $color--slate;
      height: 30px;
      width: 10px;
      top: 0;
      left: 0;

      @include breakpoint-s {
        height: 40px;
        width: 14px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      background: $color--slate;
      height: 30px;
      top: -10px;
      left: 10px;
      width: 10px;
      transform: rotate(90deg);

      @include breakpoint-s {
        top: -13px;
        left: 13px;
        height: 40px;
        width: 14px;
      }
    }
  }

  &__title {
    @include responsive-font-size(23px, 30px);
    font-weight: 500;
    line-height: 1.3;
    margin-left: $spacing--xs;

    @include breakpoint-s {
      line-height: 1.4;
      margin-left: 0;
    }
  }

  &__item {
    @include responsive-font-size(16px, 18px);
    line-height: 1.38;
    margin-bottom: $spacing--xs;
    margin-left: -20px;

    @include breakpoint-sm {
      margin-left: auto;
    }
  }

  &__list {
    margin: $spacing--s 0 0 $spacing--xs;
    padding: auto;

    @include breakpoint-sm {
      padding-left: 20px;
      margin: $spacing--sm 0 0 0;
    }
  }
}
