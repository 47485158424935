.article-header {
  $self: &;

  &__full-width {
    height: fit-content;
    margin-bottom: $spacing--sm;

    &__image-container {
      height:520px;
    }

    &__image {
      object-fit:cover;
      object-position: center;
      height: 100% !important;
      width: 100%;
      display: block;
    }

    &__content {
      padding: 0 $spacing--xxl;
      margin: 0 auto;
      max-width: convert-to-rem($content-max-width);
      height: 100%;

      &__left {
        height: 100%;
      }
    }

    &__title {
      word-break: break-word;
      position: relative;
      top: -50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      padding: convert-to-rem(24px) convert-to-rem(32px);
      min-height: convert-to-rem(100px);

      @include breakpoint-m {
        min-height: convert-to-rem(170px);
      }

      &--green {
        background-color: $color--green-dark;
        color: white;
      }

      &--yellow {
        background-color: $color--yellow;
        color: $color--black;
      }
    }
  }

  &__content {
    padding-top: $spacing--m;

    @include breakpoint-m {
      display: flex;
      padding-top: $spacing--xxxl;
    }

    &--no-image {
      padding-top: $spacing--m;

      @include breakpoint-s {
        padding-top: $spacing--top-of-page;
      }

      #{$self}__heading {
        margin-top: 0;
      }
    }
  }

  &__left-content,
  &__right-content {
    width: 50%;

    @include breakpoint-m-max {
      width: 100%;
    }
  }

  &__left-content {
    padding-right: $spacing--m;

    @include breakpoint-m {
      padding-right: 0;
    }
  }

  &__right-content {
    position: relative;
    margin-top: $spacing--m;

    @include breakpoint-m {
      padding-left: $spacing--xl;
      margin-top: 0;
    }
  }

  &__image {
    margin-bottom: $spacing--m;
  }

  &__corner {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;

    @include breakpoint-m {
      display: block;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      right: calc($spacing--s - $new-corner-width-s);
      top: calc($spacing--s - $new-corner-width-s);
      background-color: $color--beige-medium;

      @include breakpoint-m {
        right: calc($spacing--xxl - $new-corner-width-l);
        top: calc($spacing--xxxl - $new-corner-width-l);
      }
    }

    // NOTE: Vertical part
    &:before {
      width: calc($new-corner-width-s * 3);
      height: $new-corner-width-s;

      @include breakpoint-s {
        width: calc($new-corner-width-m * 3);
        height: $new-corner-width-m;
      }

      @include breakpoint-m {
        width: calc($new-corner-width-l * 3);
        height: $new-corner-width-l;
      }
    }

    // NOTE: Horizontal part
    &:after {
      width: $new-corner-width-s;
      height: calc($new-corner-width-s * 3);

      @include breakpoint-s {
        width: $new-corner-width-m;
        height: calc($new-corner-width-m * 3);
      }

      @include breakpoint-m {
        width: $new-corner-width-l;
        height: calc($new-corner-width-l * 3);
      }
    }
  }

  &__heading {
    margin-top: 0;
    margin-bottom: $spacing--xs;

    @include breakpoint-s {
      margin-top: $spacing--sm;
      margin-bottom: $spacing--sm;
    }
  }

  &__sub-heading {
    margin-bottom: $spacing--s;

    @include breakpoint-s-max {
      margin-bottom: $spacing--xs;
    }
  }

  &__readingtime {
    margin-top: $spacing--s;
  }

  &__metadata {
    font-size: convert-to-rem(16px);
    color: $color--slate;
    font-family: $font-family-secondary;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 1;
    margin-top: $spacing--s;

    @include breakpoint-s {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__metadata-item {
    &:not(:last-child) {
      margin-bottom: $spacing--xs;
    }

    @include breakpoint-s {
      &:not(:last-child) {
        margin-right: $spacing--s;
        padding-right: $spacing--s;
        border-right: 1px solid;
      }
    }
  }

  &__ingress {
    margin-top: $spacing--m;
  }
  &__colored-frame {
    @include breakpoint-m {
      margin-bottom: $spacing--m;
    }
  }

  &__links {
    margin-bottom: $spacing--l;

    @include breakpoint-m {
      width: 50%;
    }
  }
}
