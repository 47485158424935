.credits-block {
  margin-top: $spacing--l;
  margin-bottom: $spacing--xxl;
  color: $color--slate;
  display: flex;
  justify-content: flex-end;
  font-weight: normal;
  font-size: convert-to-rem(16px);
  line-height: 1.25;

  @include breakpoint-m {
    font-size: convert-to-rem(18px);
    line-height: 1.39;
  }

  &__text {
    width: 100%;
    @include breakpoint-m {
      flex: 0 0 50%;
    }
  }
}
