.image-with-text-item {
  flex: 1 1 356px;
  background-color: white;
  word-break: break-word;

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__image {
    position: relative;
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;

    @include breakpoint-sm {
      aspect-ratio: 16/9;
    }
  }

  &__text-container {
    padding: $spacing--m $spacing--m $spacing--sm  $spacing--m;
  }

  &__title {
    margin-bottom: $spacing--s;
  }

  &__text {
    margin: 0;
    line-height: 1.5;
  }

  &__link {
    width: fit-content;
    margin: auto auto $spacing--m;

    &--black {
      background-color: $color--black;
    }

    &--red {
      background-color: $color--red;
    }
  }
}
