.residence-page {
  &__header {
    position: relative;
    display: flex;
    flex-direction: column-reverse;

    @include breakpoint-m {
      flex-direction: row;
      height: 50vw;
      align-items: flex-start;
    }
  }

  &__intro {
    @include responsive-margin($spacing--sm, $spacing--m, $spacing--l);
  }

  &__logo {
    margin: 0 auto;
  }

  &__image {
    position: relative;
    height: 100vw;
    width: 100%;
    overflow: hidden;

    @include breakpoint-s {
      height: 70vw;
    }

    @include breakpoint-m {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        180deg,
        rgba(0, 0, 20, 0.5),
        rgba(0, 0, 20, 0.001) 80%
          /* NOTE: rgba(black, 0) is translated into 'transparent' when compiling, which doesn't work in every browser */
      );
    }
  }

  @keyframes slide {
    from {
      transform: translateX(0) scale(1);
    }
    to {
      transform: translateX(-5%) scale(1.05);
    }
  }

  &__image-inner {
    position: absolute;
    height: 100%;
    width: 110%;
    top: 0;
    left: 0;
    animation: slide 15s ease-in-out alternate infinite;
    will-change: transform;
  }

  &__header-text {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 2;
    padding-top: $spacing--s;
    padding-bottom: $spacing--sm;
    background-color: $color--green-dark;
    color: white;

    @include breakpoint-m {
      padding: $spacing--l $spacing--m $spacing--m;
      padding: viewport-dependent(60) viewport-dependent(50);
      min-height: 40vw;
    }

    @include breakpoint-l {
      min-height: 35vw;
    }

    p {
      text-transform: uppercase;
      letter-spacing: 0.0625em;
      color: $color--grey-green;
    }
  }

  &__address {
    margin: $spacing--xs 0 0;
  }

  &__price {
    @include responsive-margin($spacing--s, $spacing--sm, $spacing--m);
    margin-bottom: 0;
  }

  &__square-meters {
    margin: 0;
  }

  &__status {
    font-size: convert-to-rem(16px);
    font-family: $font-family-secondary;
    text-transform: uppercase;
    letter-spacing: 0.0625em;
    color: $color--slate;
    text-align: center;
    padding: $spacing--s 0;
    border: 1px solid $color--grey-green;
    border-left: none;
    border-right: none;
  }

  &__metadata,
  &__showing {
    @include responsive-margin($spacing--sm, $spacing--m);
  }

  &__showing {
    @include breakpoint-m {
      grid-template-columns: 30% 70%;
    }
    display: grid;
    grid-template-columns: 40% 60%;
    color: $color--green-dark;
    @include responsive-font-size(16px, 20px);
  }

  &__showing-title {
    display: flex;
    align-items: flex-start;
    padding-top: 0.2rem;
  }

  &__clock-icon {
    margin-right: $spacing--xs;
    color: $color--slate;
  }

  &__cta {
    @include responsive-margin($spacing--m, $spacing--l);
  }

  &__blocks,
  &__map {
    margin-top: $spacing--xl;
  }
}
