.housing-project-article-header {
  $self: &;
  max-width: 900px;
  margin: 0 auto;
  padding: 150px 0 $spacing--sm 0;

  &--has-lead {
    padding: 150px 0 $spacing--xl 0;
  }

  &__title,
  &__lead {
    #{$self}--dark & {
      color: $color--beige-dark;
    }
    text-align: center;
  }

  &__lead {
    margin: $spacing--sm auto 0 auto;
    max-width: 600px;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.6;
  }
  &--gallery-page {
    padding: 100px 0 20px;
    @include breakpoint-m {
      padding: 150px 0 30px;
    }
  }
}
