.select-option {
  $self: &;

  &__input {
    @include visually-hidden;
  }

  &__label {
    position: relative;
    font-weight: 500;
    border-radius: 20px;
    display: block;
    cursor: pointer;
    padding: 0 17px;
    min-height: 40px;
    background: white;
    text-transform: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: convert-to-rem(14px);
    border: 1px solid transparent;
    border-radius: 20px;
    text-align: center;
    color: $color--green-dark;

    &:hover {
      box-sizing: border-box;
      box-shadow: $button-shadow;
    }
  }

  &__label {
    border: 1px solid $color--grey-green;
    &--checked {
      border: 1px solid $color--green;
      flex-direction: row;
      color: $color--green;
    }

    &-text {
      align-self: center;
      margin-top: 2px;
    }

    &-icon {
      margin-right: 5px;
    }
  }

  #{$self}__input:focus + #{$self}__label {
    border: 2px solid $color--green;
    .mouse-user & {
      border: 1px solid $color--grey-green;
    }
  }
}
