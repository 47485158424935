.info-note {
  $self: &;
  z-index: 1;
  width: 30px;
  height: 35px;
  padding: 5px 0 0;
  position: relative;

  &__button {
    padding: 0;
    border: none;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    background-color: $color--beige-dark;
    overflow: hidden;
    cursor: pointer;
    color: $color--green-dark;
    transition: background-color 0.3s ease-in-out;

    &:hover,
    &:focus {
      background-color: $color--yellow-strong;
    }

    &--show {
      line-height: 30px;
      font-size: 20px;
      position: relative;
      z-index: 1;
    }

    &--hide {
      position: absolute;
      top: 5px;
      right: 0px;
      z-index: 0;

      #{$self}--text-is-shown & {
        z-index: 3;
      }

      @include order-key-breakpoint {
        display: none;
      }
    }
  }

  &__text {
    display: none;
    position: absolute;
    top: 20px;
    right: 15px;
    padding: 20px;
    background: $color--yellow;
    min-width: 200px;
    z-index: 2;
    box-sizing: border-box;

    @media (max-width: 768px) {
      width: calc(100vw - 172px);
    }
    @include breakpoint-s-max {
      width: calc(100vw - 112px);
    }

    @include order-key-breakpoint {
      top: 0;
      left: 0;
      right: auto;
      width: 266px;
      display: none;

      #{$self}--in-keys-payment & {
        left: auto;
        right: 0;
      }

      #{$self}:hover &,
      #{$self}:focus & {
        display: block;
      }
    }

    &--is-shown {
      display: block;
    }
  }

  &__hide-icon {
    width: 15px;
    height: 15px;
    display: block;
    margin: 0 auto;
  }
}
