.metadata {
  @include responsive-font-size(16px, 20px);
  display: grid;
  grid-template-columns: 40% 60%;
  grid-row-gap: 0.5em;
  color: $color--green-dark;
  margin: 0;

  @include breakpoint-m {
    grid-template-columns: 30% 70%;
  }

  dt {
    @include responsive-font-size(13px, 16px);
    text-transform: uppercase;
    letter-spacing: 0.0625em;
    padding-right: 1em;
    padding-top: 0.2rem;
    hyphens: auto;
    word-wrap: break-word;
  }

  dd {
    margin-left: 0;
  }
}
