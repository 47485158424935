.label {
    display: flex;
    flex-direction: row;
    margin-bottom: 6px;
    justify-content: flex-start;
    font-size: convert-to-rem(16px);
    line-height: 1.25;

    @include breakpoint-m {
        margin-bottom: 10px;
    }

    &--error {
        color: $color--red-dark;
    }

    &__text{
        display: block;
        margin: 0;
    }

    &__icon-container {
        flex: 0 0 12px;
        width: 12px;
        margin-top: 6px;
        margin-right: 5px;
    }

    &__error-icon {
        display: block;
    }
}