.housing-project-rich-text {
  @include rich-text-base;

  ul > li {
    &:before {
      content: '';
      top: 0.75em;
      background: currentColor;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      overflow: hidden;
      margin-top: -2.5px;
    }
  }

  &--dark,
  &--green {
    color: $color--beige-dark;

    p,
    ul {
      color: $color--grey-green;
    }

    ul > li {
      &:before {
        background: $color--beige-dark;
      }
    }
  }

  &--light {
    ul > li {
      &:before {
        background: $color--grey;
      }
    }
  }
}
