@import 'source/scss/vendor/slick';
.housing-project-image-gallery-carousel {
  &__image-container {
    display: flex;
    height: 55vh;

    @include breakpoint-l {
      height: 70vh;
    }
  }
  &__image {
    max-height: 600px;
    margin: auto;
  }

  &__button-next,
  &__button-prev {
    @include button-style-reset;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background: black;
    border-radius: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
    flex: none;
    margin-top: $spacing--s;

    &:disabled {
      visibility: hidden;
    }

    &:hover {
      background: $color--beige-dark;

      &:before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        border: 2px solid $color--black;
        transform: rotate(-45deg);
        border-top: none;
        border-left: none;
        transition: all 0.3s linear;
        position: absolute;
        margin-right: 5px;
      }
    }

    @include breakpoint-l {
      margin-top: 0;
    }

    &:before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      border: 2px solid $color--beige-dark;
      transform: rotate(-45deg);
      border-top: none;
      border-left: none;
      transition: all 0.3s linear;
      position: absolute;
      margin-right: 5px;
    }
  }

  &__button-prev {
    order: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    @include breakpoint-l {
      order: 1;
      position: relative;
    }

    &:before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      border: 2px solid $color--beige-dark;
      transform: rotate(135deg);
      border-top: none;
      border-left: none;
      transition: all 0.3s linear;
      position: absolute;
      margin-right: 0px;
      margin-left: 5px;
    }

    &:hover {
      &:before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        border: 2px solid $color--black;
        transform: rotate(135deg);
        border-top: none;
        border-left: none;
        transition: all 0.3s linear;
        position: absolute;
        margin-right: 0px;
        margin-left: 5px;
      }
    }
  }

  &__button-next {
    order: 1;
    @include breakpoint-l {
      order: 3;
    }
  }

  &__meta {
    color: $color--beige-dark;
    max-width: 600px;
    text-align: center;
    margin: auto;
  }

  &__description {
    @include responsive-font-size(16px, 18px);
    line-height: 1.3;
  }

  &__counter {
    margin-top: -35px;
    margin-bottom: $spacing--sm;
    font-size: 20px;
    @include breakpoint-l {
      margin-top: 0;
      margin-bottom: $spacing--s;
    }

    &-total {
      color: $color--grey-green;
    }
  }

  .slick-slider {
    @include breakpoint-l {
      align-items: center;
      flex-direction: row;
    }
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  .slick-list {
    width: 100%;
    order: 1;
    max-height: 380px;
    @include breakpoint-sm {
      max-height: none;
    }
    @include breakpoint-l {
      width: auto;
      order: 2;
    }
  }
}
