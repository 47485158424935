.menu-headers {
  $self: &;

  display: none;
  margin-top: 2px;
  height: 100%;

  @include breakpoint-mm {
    display: flex;
    flex-wrap: wrap;
  }

  &__button {
    @include underline-animation(expand, '.menu-headers__button-text');
    @include font-size(16px);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: transparent;
    text-align: left;
    width: auto;
    border: none;
    border-bottom: 2px solid transparent;
    padding: 0;
    text-transform: none;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: 0 !important;
    white-space: nowrap;
    color: $color--yellow;
    font-family: $font-family;

    &--active {
      #{$self}__button-text {
        border-bottom: 2px solid $color--yellow;
        padding-bottom: 0;
        background-image: none;
      }

      #{$self}__icon {
        rotate: 180deg;
      }
    }

    @include breakpoint-mm {
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }

    @include breakpoint-m {
      margin-right: 40px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__button-text {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__icon {
    transition: rotate ease-in-out 300ms;
  }
}
