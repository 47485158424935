@mixin rich-text-base {
  color: $color--green-dark;
  line-height: 1.83;
  font-size: convert-to-rem(16px);

  @include breakpoint-s {
    font-size: convert-to-rem(18px);
  }

  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }

  a {
    color: inherit;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  p {
    margin: 0 0 1em;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    margin: 40px 0 10px;

    @include breakpoint-s {
      margin: 60px 0 10px;
    }
  }

  h2 {
    font-size: convert-to-rem(23px);
    line-height: 1.3;
    margin-bottom: $spacing--s;

    @include breakpoint-s {
      font-size: convert-to-rem(25px);
      line-height: 1.4;
    }
  }

  h3,
  h4,
  h5,
  h6 {
    font-size: convert-to-rem(16px);
    line-height: 1.38;

    @include breakpoint-s {
      font-size: convert-to-rem(18px);
      line-height: 1.4;
    }
  }

  ul,
  ol {
    margin: 20px 0;
    padding: 0;

    li {
      padding: 0 0 0 24px;
      list-style: none;
      position: relative;

      &:before {
        display: block;
        position: absolute;
        left: 0;
      }
    }
  }

  ul > li {
    &:before {
      content: '';
      top: 0.9em;
      background: currentColor;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      overflow: hidden;
      margin-top: -2.5px;
    }
  }

  ol {
    counter-reset: my-awesome-counter;

    > li {
      counter-increment: my-awesome-counter;

      &:before {
        content: counter(my-awesome-counter);
        top: 0;
      }
    }
  }

  blockquote {
    margin: 0 auto;
    padding: 20px 0;
    text-align: center;
    font-style: italic;
    display: block;
    width: 100%;
    clear: both;

    @include breakpoint-s {
      max-width: 80%;
    }

    &:before {
      content: open-quote;
    }
    &:after {
      content: close-quote;
    }
  }

  table {
    max-width: 100%;
    text-align: left;
    border-collapse: collapse;

    th,
    td {
      border: 1px solid rgba($color--green-dark, 0.2);
      -webkit-background-clip: padding-box; /* for Safari */
      background-clip: padding-box;
      padding: 5px 10px;
    }
  }

  strong {
    font-weight: 500;
  }
}
