.housing-project-information {
  $self: &;

  &--light {
    background: transparent;
    color: $color--black;
  }

  &--dark {
    color: $color--beige-dark;
    background: $color--black;
  }

  &__title {
    @include responsive-font-size(30px, 40px);
    margin-bottom: $spacing--xs;

    #{$self}--dark & {
      color: $color--beige-dark;
    }
  }

  &__link-container {
    display: flex;
    align-items: center;
    margin-bottom: $spacing--sm;

    @include breakpoint-s {
      margin-bottom: $spacing--l;
    }
  }

  &__link {
    @include responsive-font-size(16px, 18px);
    font-weight: 400 !important;
    .link__text {
      #{$self}--dark & {
        color: $color--beige-dark;
      }
      color: $color--grey;
    }
  }

  &__link-icon {
    color: $color--grey;
    #{$self}--dark & {
      color: $color--beige-dark;
    }
    margin-right: $spacing--xs;
  }

  &__content {
    max-width: 1600px;
    margin: 0 auto;
    padding: $spacing--m 0 $spacing--s;
    @include breakpoint-xm {
      display: grid;
      grid-template-columns: 60% 40%;
    }
  }

  &__status {
    margin-top: $spacing--m;

    @include breakpoint-xm {
      margin-top: $spacing--l;
    }
  }

  &__key-information {
    @include breakpoint-xm {
      max-width: 800px;
    }
  }
}
