.housing-project-faq-block {
  max-width: 900px;
  margin: auto;

  &--dark {
    color: $color--beige-dark;
    .expander__title,
    .rich-text {
      color: $color--beige-dark;
    }
    .expander__button::before {
      border-color: $color--beige-dark;
    }
  }
  &--light {
    color: $color--black;
    .expander__title,
    .rich-text {
      color: $color--black;
    }
  }

  .expander {
    border-color: $color--grey;
  }

  .expander:last-of-type {
    border-bottom: 1px solid $color--grey;
  }

  .expander__header {
    min-height: unset;
  }
  .expander--is-active .expander__header {
    padding-bottom: $spacing--s;
  }

  .expander-list__item-text {
    padding-bottom: $spacing--sm;
    width: calc(100% - 50px);
    @include breakpoint-m {
      width: calc(100% - 80px);
    }
    max-width: 550px;
  }

  @include breakpoint-m {
    .content-wrapper {
      padding-left: convert-to-rem(150px);
      padding-right: convert-to-rem(150px);
    }
  }

  &__heading {
    text-align: center;
    margin-bottom: $spacing--m;
  }
}
