.search-page {
  margin-top: $spacing--xxl;
  color: $color--green-dark;

  @include breakpoint-s {
    margin-top: calc($spacing--xl * 2);
  }

  &__search {
    margin-top: 1rem;
    padding-top: 1rem;
    @include responsive-margin($spacing--m, $spacing--xl);

    @include breakpoint-m {
      margin-top: 2rem;
      padding-top: 2rem;
      width: 90%;
    }
  }

  &__results {
    @include responsive-margin($spacing--m, $spacing--xl);
    max-width: calc(0.6 * $content-max-width);

    > p {
      font-weight: 500;
      margin: 0 0 $spacing--l;
    }
  }

  &__result {
    @include responsive-margin($spacing--m, $spacing--l);

    &:last-child {
      margin-bottom: 0;
    }
  }
}
