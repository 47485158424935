$desktop-margin: calc((100vw - 100% - 17px) / 2 * -1);

@mixin heading-background-color($color) {
  box-shadow: -10px 0 0 $color;
  padding: 0 10px 0 0;
  @include breakpoint-s {
    padding: 0 20px 10px 0;
    box-shadow: -20px 0 0 $color;
  }
}
.inspo-full-width-media-block {
  $self: &;
  position: relative;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  height: 100vh;
  position: sticky;
  top: 0;

  &__wrapper {
    height: 150vh;

    @include breakpoint-s {
      height: 150vh;
    }

    &--has-video {
      height: 150vh;
      @include breakpoint-s {
        height: 200vh;
      }
    }
  }

  .content-wrapper & {
    margin-left: -$spacing--s;
    margin-right: -$spacing--s;
    width: calc(100% + #{$spacing--s * 2});

    @include breakpoint-s {
      margin-left: -$spacing--l;
      margin-right: -$spacing--l;
      width: calc(100% + #{$spacing--l * 2});
    }

    @include breakpoint-m {
      margin-left: $desktop-margin;
      margin-right: $desktop-margin;
      width: calc(100% + #{$desktop-margin}* 2 * -1);
      max-width: 100vw;
    }
  }

  &__content {
    max-width: 85rem;
    margin: 0 auto;
    height: 716px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    z-index: 2;
    padding-top: 30px;
    padding-bottom: 30px;

    @include breakpoint-s {
      height: 777px;
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  &__heading {
    padding-left: 10px;
    @include responsive-font-size(30px, 40px);
    line-height: 1.23;
    @include breakpoint-s {
      padding-left: 20px;
      line-height: 1.55;
    }
  }

  &__heading-text {
    white-space: pre-wrap;
    background-clip: padding-box;
    box-decoration-break: clone;

    &--background-green-dark {
      background: $color--green-dark;
      color: $color--beige-light;
      @include heading-background-color($color--green-dark);
    }
    &--background-beige-light {
      background: $color--beige-light;
      @include heading-background-color($color--beige-light);
    }
    &--background-pink {
      background: $color--pink;
      @include heading-background-color($color--pink);
    }
    &--background-pink-light {
      background: $color--pink-light;
      @include heading-background-color($color--pink-light);
    }
  }

  &__intro {
    padding: 10px;
    margin-top: 20px;
    font-weight: 500;
    @include responsive-font-size(16px, 18px);
    line-height: 1.38;

    @include breakpoint-s {
      line-height: 1.44;
      padding: 30px;
    }

    &--background-green-dark {
      background: $color--green-dark;
      color: $color--beige-light;
    }
    &--background-beige-light {
      background: $color--beige-light;
    }
    &--background-pink {
      background: $color--pink;
    }
    &--background-pink-light {
      background: $color--pink-light;
    }
  }

  &__heading,
  &__intro {
    max-width: 90%;
    @include breakpoint-m {
      max-width: 50%;
    }
  }

  &__image {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  .image__background-wrapper {
    background-attachment: auto;
    @include breakpoint-s {
      background-attachment: fixed;
    }
  }
}

.Plx {
  @include breakpoint-s {
    position: absolute;
    width: 90%;
  }
}
