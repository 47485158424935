.error-text {
    &:before{
        content: '*';
        margin-right: 5px;
    }
    color: $color--red-dark;
    font-size: convert-to-rem(16px);
    line-height: 1;
    margin-top: 1rem;
    color: $color--red-dark;
}