.event-list {
  display: flex;
  flex-wrap: wrap;

  &__label {
    @include responsive-font-size(13px, 16px);
    font-weight: normal;
    line-height: 1.4;
    text-transform: uppercase;
    letter-spacing: 0.0625em;
    color: $color--slate;
    border-bottom: 1px solid $color--grey-green;
    padding-bottom: $spacing--xs;

    &:first-child {
      padding-left: $spacing--s;
    }
  }

  &__item {
    color: inherit;
    text-decoration: none;
    width: 100%;
    display: flex;

    &:hover,
    &:focus {
      background-color: $color--beige;
    }
  }

  &__label,
  &__field {
    @include breakpoint-m-max {
      &:first-child {
        width: 40% !important; // NOTE: Override inline style set in jsx
      }

      &:nth-child(2) {
        width: 60% !important;
      }

      &:nth-child(2) ~ & {
        display: none;
      }
    }
  }

  @mixin with-chevron {
    display: flex;
    justify-content: space-between;
    padding-right: 0;

    &:after {
      content: "";
      display: block;
      background-image: url(../../assets/icons/chevron-right-green.svg);
      height: 23px;
      width: 15px;
      flex-shrink: 0;
      margin-left: $spacing--xs;
    }
  }

  &__field {
    padding: $spacing--s 0;
    border-bottom: 1px solid $color--grey-green;
    padding-right: $spacing--s;

    &:first-child {
      padding-left: $spacing--s;
    }

    &:last-child {
      @include with-chevron;
      padding-right: $spacing--s;
    }

    @include breakpoint-m-max {
      &:nth-child(2) {
        @include with-chevron;
      }
    }
  }
}
