.search {
  $self: &;

  display: flex;
  width: 100%;
  border-bottom: 1px solid $color--slate;
  padding-bottom: $spacing--xs;
  align-items: flex-end;
  height: 2.5rem;

  input {
    @include responsive-font-size(18px, 30px);
    appearance: none;
    background-color: transparent;
    border: none;
    font-family: inherit;
    font-weight: 300;
    min-width: 0;
    width: auto;
    flex-grow: 1;

    @include breakpoint-l {
      font-size: convert-to-rem(50px);
    }
  }

  button {
    @include button-style-reset;
    @include responsive-font-size(13px, 18px);
    background-color: $color--slate;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0;
    margin-left: $spacing--s;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.0625em;

    @include breakpoint-s {
      width: 70px;
      height: 70px;
    }
  }

  &--menu {
    width: 100%;
    max-width: 543px;
    border: none;
    margin: 0 auto;
    padding: 0;

    #{$self}__input {
      background-color: $color--yellow;
      border-radius: 50px 0px 0px 50px;
      font-size: 1rem;
      width: 100%;
      height: 100%;
      padding: 10px 2px 10px 24px;
    }

    #{$self}__button {
      background-color: $color--slate;
      border-radius: 0px 50px 50px 0px;
      width: auto;
      height: 100%;
      padding: 8px 12px 9px 12px;
      margin: 0;

      &:hover {
        cursor: pointer;

        #{$self}__button-text {
          border-bottom: 1px solid $color--yellow;
        }
      }
    }

    #{$self}__button-text {
      color: $color--yellow;
      font-size: 1rem;
      text-transform: none;
      letter-spacing: normal;
      border-bottom: 1px solid transparent;
    }
  }
}
