@keyframes check-mark-circle-animation {
  0% {
    stroke-dashoffset: 150px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.button {
  $self: &;

  border: none;
  border-radius: 20px;
  padding: 0 20px;
  min-height: 40px;
  min-width: 100px;
  font-size: convert-to-rem(16px);
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: $font-family;
  cursor: pointer;
  @include underline-animation(expand, '.button__text');
  display: inline-flex;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;

  @include breakpoint-s {
    padding: 0 30px;
  }

  &:disabled {
    pointer-events: none;
    background-color: $color--disabled;
  }

  &--in-add-credit-card {
    &:hover {
      text-decoration: underline;
    }

    margin-top: $spacing--s;
    margin-bottom: $spacing--sm;
    width: 100%;

    @include breakpoint-s {
      width: auto;
      margin-top: $spacing--s;
    }
  }

  &--disabled {
    color: #6c6c6c !important;
    background-color: #e7e5df !important;
  }

  &--has-spinner {
    display: flex;
    align-items: center;

    &:disabled {
      background-color: $color--green;
    }
  }

  &_alignment {
    &--right {
      float: right;
      margin-left: $spacing--xs;
    }

    &--full-width {
      width: 100%;
    }
  }

  &_theme {
    &--green {
      background: $color--green;
      color: white;
    }

    &--green-bordered {
      background: $color--green;
      color: white;
      border: 1px solid white;
    }

    &--slate {
      background: white;
      color: $color--slate;
      border: 1px solid $color--slate;
    }

    &--yellow-bordered {
      background: transparent;
      border: 1px solid $color--yellow;
      color: $color--yellow;
    }

    &--orange {
      background: $color--orange;
      color: $color--black;
    }

    &--slate-bg {
      background: $color--slate;
      color: white;
    }

    &--black {
      color: white;
      background-color: $color--black;

      &:hover {
        text-decoration: none;
      }
    }

    &--transparent-with-green-border {
      background: transparent;
      border: 1px solid $color--slate;
      color: $color--slate;
    }

    &--vipps {
      background: #ff5b24;
      color: white;
      flex-direction: row-reverse;
    }

    &--dark-primary {
      background-color: $color--beige-dark !important;
      color: $color--black;

      &-green {
        background-color: $color--housing-project-green !important;
      }
    }

    &--dark-secondary {
      background: none;
      color: $color--beige;
      border: 1px solid $color--grey-green;
    }

    &--light-primary {
      background-color: $color--black !important;
      color: white;
    }

    &--light-secondary {
      background: white;
      color: $color--black;
      border: 1px solid $color--grey;
    }

    &--close,
    &--toggle {
      align-self: flex-start;
      background: none;
      border-radius: 0;
      padding-right: 30px;
      padding-left: 20px;
      color: $color--green-dark;
      letter-spacing: 1px;
      text-transform: uppercase;
      position: relative;
      float: right;
      margin: 0 5px 8px auto;
      min-height: auto;

      @include breakpoint-s {
        margin: 0 3px 20px auto;
      }

      &:before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border: 2px solid $color--green-dark;
        transform: rotate(-135deg);
        border-top: none;
        border-left: none;
        transition: all 0.3s linear;
        position: absolute;
        top: 50%;
        right: 0;
        margin-left: -8px;
        margin-top: -4px;

        @include breakpoint-s {
          width: 15px;
          height: 15px;
          margin-left: -8px;
          margin-top: -4px;
        }
      }
    }

    &--toggle {
      @include responsive-font-size(14px, 16px);
      padding-left: 0;
      padding-right: 20px;
      letter-spacing: 0;
      margin: 0 5px 0px auto;

      @include breakpoint-s {
        padding-left: 20px;
        padding-right: 30px;
        letter-spacing: 1px;
      }

      &:before {
        transform: rotate(45deg);
        margin-top: -8px;

        @include breakpoint-s {
          margin-top: -12px;
        }
      }
    }

    &--add {
      @include underline-animation(expand, '.button__text');
      @include font-size(17px);
      display: flex;
      align-items: center;
      background-color: transparent;
      text-align: left;
      width: auto;
      border: none;
      padding: 0;
      margin-top: 10px;
      text-transform: none;
      font-weight: 500;
      line-height: 1.25;
      letter-spacing: 0 !important;
      color: $color--green;
    }

    &--warningbanner {
      @include font-size(20px);
      padding: 0;
      background-color: transparent;
      color: $color--black;
      border: none;
      text-transform: none;
      font-weight: 400;
      justify-content: flex-end !important;

      #{$self}__text {
        display: none;
      }

      @include breakpoint-ms {
        #{$self}__text {
          display: block;
        }
      }
    }
  }

  &--with-icon {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
  }

  &__text {
    align-self: center;

    #{$self}--has-spinner & {
      background-size: 0% 1px !important;
    }

    #{$self}--in-add-credit-card & {
      background-image: none;
    }

    #{$self}_theme--add & {
      margin-left: $spacing--xs;
      margin-top: 1px;
    }
  }

  &__icon {
    flex: 0 0 25px;
    height: 25px;
    margin-right: 10px;
    align-self: center;
    margin-top: -1px;
    margin-bottom: -1px;
    position: relative;

    &--right {
      margin-right: 0;
      margin-left: 10px;
    }

    &--check-circle {
      circle {
        stroke-dasharray: 150px;
        stroke-dashoffset: 150px;
        animation: check-mark-circle-animation 1s linear forwards;
        animation-delay: 0.5s;
      }
    }

    &--vipps {
      flex: none;
      height: 35px;
      margin-right: 0;
    }
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  &__spinner {
    margin-right: $spacing--xs;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border-top: 2px solid white;
    border-right: 2px solid transparent;
    animation: spinner 1s linear infinite;
    flex: 0 0 25px;

    #{$self}_theme--dark-primary-green &,
    #{$self}_theme--dark-primary & {
      border-top: 2px solid $color--black;
    }
  }

  @keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }

  &__checkmark {
    margin-right: $spacing--xs;

    circle {
      stroke: white;

      #{$self}_theme--dark-primary-green &,
      #{$self}_theme--dark-primary & {
        stroke: $color--black;
      }
    }

    path {
      fill: none;
      stroke: white;
      stroke-width: 2;
      stroke-dasharray: 23;
      stroke-dashoffset: 23;
      animation: draw 0.8s ease-out forwards;

      #{$self}_theme--dark-primary-green &,
      #{$self}_theme--dark-primary & {
        stroke: $color--black;
      }
    }
  }

  &--in-booking-time-slot {
    min-height: 30px;
    padding-left: 15px;
    padding-right: 15px;
  }

  &__external-link {
    margin-left: $spacing--xs;
    height: 100%;
    display: flex;
    align-self: center;

    &--green {
      fill: #fff;
    }

    &--slate {
      fill: $color--slate;
    }
  }
}