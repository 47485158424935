.image-with-text-list-wrapper {
  background-color: $color--beige;
  padding: 0;

  .content-wrapper & {
    @include full-width-block;
  }
}

.image-with-text-list {
  @include content-wrapper-padding;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: $spacing--m;
  padding-bottom: $spacing--m;
  gap: $spacing--sm;
  max-width: 1360px;
  margin: auto;

  @include breakpoint-ms {
    padding-top: $spacing--xxl;
    padding-bottom: $spacing--xxl;
  }
}
