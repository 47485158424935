.frontpage-header-confirmation {
  background: white;
  color: $color--green-dark;
  padding: 20px;
  width: calc(100vw - 40px);
  left: 20px;
  z-index: 100;
  position: absolute;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.5);
  display: none;
  margin-left: 20px;

  &--shown {
    display: block;
  }

  @include breakpoint-s {
    width: calc(100vw - 140px);
    margin-left: 60px;
  }

  @media all and (max-width: $breakpoint-ms - 1px) {
    bottom: 100%;
    margin-bottom: 14px;
  }

  @include breakpoint-ms {
    top: 100%;
    margin-top: 28px;
    width: calc(50vw - 100px);
    left: 0;
    margin-left: 1px !important;
    box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.3);
  }

  @include breakpoint-l {
    padding: 30px;
  }

  &__arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 15px 15px;
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    left: 20px;

    @media all and (max-width: $breakpoint-ms - 1px) {
      top: 100%;
      transform: rotate(180deg);
    }

    @include breakpoint-ms {
      bottom: 100%;
      left: 110px;
    }
  }

  &__close-button {
    @include button-style-reset;
    background: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  &__icon {
    width: 13px;
  }
}
