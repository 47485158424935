@import 'functions';
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  @include responsive-font-size(16px, 18px);
  @include scrollbar($color--grey-green, white, $color--slate);

  &::-webkit-scrollbar {
    border-left: 1px solid $color--grey-light;
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color--black;
  font-family: $font-family;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}

.mouse-user * {
  outline: none;
}
