.social-media-link {
  width: $spacing--l;
  color: $color--yellow;

  &:hover circle,
  &:focus circle {
    stroke-dashoffset: 153.86px; //circumference of the svg circle
  }

  &__icon {
    width: 45px;
    & circle {
      stroke-dasharray: 153.86px;
      stroke-dashoffset: 0;
      transition: stroke-dashoffset 0.5s;
    }
  }
}
