.event-promo-block {
  &__cta {
    text-align: right;
    margin-bottom: $spacing--s;
  }
  &__content {
    @include underline-animation(shrink, ".event-promo-block__cta-button");
    color: $color--black;
    font-weight: 200;
    text-decoration: none;
    background-color: $color--pink;
    padding: $spacing--sm;
    display: block;
    width: 100%;

    @include breakpoint-m {
      display: flex;
      padding: $spacing--xl $spacing--xl $spacing--xl 0;
    }
  }

  &__date {
    width: 100%;
    text-align: center;
    margin-bottom: $spacing--sm;
    @include breakpoint-m {
      width: 50%;
    }
  }

  &__day {
    @include responsive-font-size(150px, 200px);
    line-height: 1;
  }

  &__month {
    @include responsive-font-size(40px, 50px);
  }

  &__text-container {
    width: 100%;
    @include breakpoint-m {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__text {
    @include responsive-font-size(25px, 30px);
  }
  &__cta-button-container {
    display: block;
    background-color: $color--slate;
    color: white;
    text-align: center;
    text-decoration: none;
    background-image: none;
    padding: convert-to-rem(25px);
    font-size: convert-to-rem(16px);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: $spacing--m;

    @include breakpoint-s-max {
      &:active {
        background-color: $color--green-dark;
      }
    }
  }
}
