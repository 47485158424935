.inspo-article-filters {
  display: flex;
  flex-wrap: wrap;
  padding: $spacing--m 0 7px 0;
  background-color: $color--beige-light;

  @include breakpoint-s {
    background-color: transparent;
  }
}
