.link {
  $self: &;
  color: $color--red;
  font-weight: 200;
  line-height: 1.3;

  &__icon-container {
    flex-shrink: 0;
    display: inline;
    margin-left: $spacing--xs;
    color: $color--orange;
  }

  &--color-black {
    color: $color--black;

    #{$self}__external-link-icon,
    #{$self}__anchor-link-icon {
      & > * {
        fill: $color--black;
      }
    }
  }

  &--color-yellow {
    color: $color--yellow;
    #{$self}__external-link-icon,
    #{$self}__anchor-link-icon {
      & > * {
        color: $color--yellow;
      }
    }
  }

  &--color-slate {
    color: $color--slate;
    #{$self}__external-link-icon,
    #{$self}__anchor-link-icon {
      & > * {
        color: $color--slate;
      }
    }
  }

  &--color-green {
    color: $color--green;
    #{$self}__external-link-icon,
    #{$self}__anchor-link-icon {
      & > * {
        color: $color--green;
      }
    }
  }

  &--color-green-dark {
    color: $color--green-dark;
    #{$self}__external-link-icon,
    #{$self}__anchor-link-icon {
      & > * {
        color: $color--green-dark;
      }
    }
  }

  &--color-white {
    color: white;
    #{$self}__external-link-icon,
    #{$self}__anchor-link-icon {
      & > * {
        fill: white;
      }
    }
  }

  &--color-yellow-on-mobile {
    color: $color--yellow;
    @include breakpoint-ms {
      color: $color--red-dark;
    }

    .frontpage-header--color-green-dark & {
      color: $color--yellow;
    }
    .frontpage-header--color-pink &,
    .frontpage-header--color-orange &,
    .frontpage-header--color-yellow & {
      color: $color--yellow;
      @include breakpoint-ms {
        color: $color--green-dark;
      }

      .link__text {
        background-image: linear-gradient(
          $color--grey-green,
          $color--grey-green
        );
      }
    }

    .frontpage-header--color-orange & {
      .link__text {
        background-image: linear-gradient(#6a6a6a, #6a6a6a);
      }
    }
  }

  &--color-red-dark {
    color: $color--red-dark;
    #{$self}__external-link-icon,
    #{$self}__anchor-link-icon {
      & > * {
        color: $color--red-dark;
      }
    }
  }

  &--font-theme-regular {
    font-weight: normal;
  }

  &--font-theme-regular-small {
    font-size: convert-to-rem(20px);
    font-weight: 400;
  }

  &--has-icon {
    display: flex;
    text-decoration: none;
  }

  &--font-theme-uppercase,
  &--theme-button-small,
  &--theme-button-big {
    font-size: convert-to-rem(16px);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  &--font-theme-uppercase-condensed {
    font-size: convert-to-rem(16px);
    font-family: $font-family-secondary;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  &--font-theme-bold {
    font-size: convert-to-rem(16px);
    font-weight: 500;
  }

  &--animation-expand,
  &--animation-shrink {
    text-decoration: none;
  }

  &--animation-expand {
    @include underline-animation(expand, '.link__text');
  }

  &--animation-shrink {
    @include underline-animation(shrink, '.link__text');
  }

  &--theme-button-small {
    display: block;
    background-color: $color--slate;
    color: white;
    text-align: center;
    text-decoration: none;
    background-image: none;
    padding: convert-to-rem(25px);

    @include breakpoint-s-max {
      &:active {
        background-color: $color--green-dark;
      }
    }
  }

  &--theme-menu {
    font-size: 1rem;
    font-weight: 400;
    display: flex;
    font-family: $font-family;

    #{$self}__icon-container {
      display: none;
      color: $color--black;
      margin-left: 0;
      margin-right: $spacing--xs;

      @include breakpoint-mm {
        display: block;
      }
    }

    #{$self}__icon {
      rotate: -90deg;
    }
  }

  &--theme-login-link-yellow {
    display: flex;

    #{$self}__icon-container {
      display: block;
      height: 19px;
      width: 15px;
      fill: $color--yellow;
      margin-right: $spacing--xs;
      margin-left: 0;

      @include breakpoint-mm {
        margin-left: 0.625rem;
      }
    }
  }

  &--theme-login-link-black {
    display: flex;

    #{$self}__icon-container {
      display: block;
      height: 19px;
      width: 15px;
      fill: $color--black;
      margin-right: $spacing--xs;
    }
  }

  &--theme-inspo-header {
    color: #fff;
    font-size: convert-to-rem(40px);
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    text-decoration: none;

    &:hover {
      opacity: 0.75;
    }
  }

  &--theme-frontpage-header {
    font-size: convert-to-rem(16px);
    line-height: 1.25;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  &--in-related-pages {
    color: $color--green-dark;
    word-break: break-word;

    #{$self}__text {
      background-image: linear-gradient($color--grey-green, $color--grey-green);
    }
  }

  &--is-external {
    display: flex;
    align-items: center;
  }

  &--theme-contact-card {
    #{$self}__icon-container {
      display: block;
      height: 24px;
      width: 24px;
      padding: 3px;
      margin: 0 $spacing--xs 0 0;
      color: $color--grey;
    }
  }

  &__external-link-icon {
    margin-left: 7px;
  }

  &__anchor-link-icon {
    margin-left: 7px;
  }
}
