@font-face {
  font-family: "National2";
  src: url("../assets/fonts/National2Web-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "National2";
  src: url("../assets/fonts/National2Web-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "National2";
  src: url("../assets/fonts/National2Web-Light.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "National2";
  src: url("../assets/fonts/National2Web-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "National2Condensed";
  src: url("../assets/fonts/National2CondensedWeb-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
