.media-block {
  $padding-for-colored-box: 10%;

  &__content {
    padding: 0;
    margin: 0;

    @include breakpoint-s {
      margin-top: convert-to-rem(30px);
    }
  }

  &__iframe {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;

    & iframe {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__image {
    position: relative;
  }
  &__heading {
    margin-top: $spacing--sm;
    @include breakpoint-m {
      margin-top: $spacing--m;
      width: 50%;
    }
  }

  &__link {
    margin-top: $spacing--sm;
    @include breakpoint-s {
      margin-top: $spacing--m;
    }
  }
}
