.housing-project-status {
  $self: &;
  margin-bottom: $spacing--xs;

  @include breakpoint-xm {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
  }

  &__content {
    width: 100%;
    max-width: 450px;
    padding: $spacing--sm;
    @include breakpoint-s {
      padding: $spacing--sm;
    }

    @include breakpoint-xm {
      max-width: 350px;
    }

    @include breakpoint-l {
      width: 70%;
    }

    #{$self}--dark & {
      background: black;
    }
    #{$self}--light & {
      background: $color--beige;
    }
  }

  &__text {
    @include responsive-font-size(16px, 18px);
    margin-top: $spacing--xs;
    line-height: 1.4;
    max-width: 90%;
  }

  &__link {
    width: fit-content;
    margin-top: $spacing--s;
  }
}
