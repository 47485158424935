.housing-project-image-gallery-item {
  padding: $spacing--m 0 0 0;
  @include breakpoint-m {
    padding: $spacing--l 0 0 0;
  }
  &__title {
    color: $color--grey-green;
    text-align: center;
    margin-bottom: $spacing--s;
    @include breakpoint-m {
      margin-bottom: $spacing--sm;
    }
  }

  &__images {
    display: grid;
    grid-template-columns: calc(50% - 15px) calc(50% - 15px);
    grid-column-gap: $spacing--sm;
    grid-row-gap: $spacing--sm;

    @include breakpoint-m {
      grid-template-columns:
        calc(25% - 22.5px) calc(25% - 22.5px) calc(25% - 22.5px)
        calc(25% - 22.5px);
      grid-column-gap: $spacing--sm;
      grid-row-gap: $spacing--sm;
    }
    @include breakpoint-l {
      grid-template-columns: calc(25% - 45px) calc(25% - 45px) calc(25% - 45px) calc(
          25% - 45px
        );

      grid-column-gap: $spacing--l;
      grid-row-gap: $spacing--l;
    }

    @include breakpoint-m-max {
      > .housing-project-image-gallery-item__image-container:first-child {
        grid-column: span 2;
      }
    }
  }

  &__image-container {
    @include button-style-reset;
    display: flex;
    background: transparent;
    overflow: hidden;
  }

  &__image {
    margin: auto;
    max-height: 275px;

    //2 px stroke
    // cursor: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI1IDUwQzM4LjgwNzEgNTAgNTAgMzguODA3MSA1MCAyNUM1MCAxMS4xOTI5IDM4LjgwNzEgMCAyNSAwQzExLjE5MjkgMCAwIDExLjE5MjkgMCAyNUMwIDM4LjgwNzEgMTEuMTkyOSA1MCAyNSA1MFoiIGZpbGw9ImJsYWNrIi8+CjxsaW5lIHgxPSIyNS4wMTgyIiB5MT0iMTAiIHgyPSIyNS4wMTgyIiB5Mj0iNDEiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIvPgo8bGluZSB4MT0iNDAuNSIgeTE9IjI0Ljk3NjEiIHgyPSI5LjUiIHkyPSIyNC45NzYxIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==')
    //   15 15, move;

    //1.5px stroke
    cursor: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI1IDUwQzM4LjgwNzEgNTAgNTAgMzguODA3MSA1MCAyNUM1MCAxMS4xOTI5IDM4LjgwNzEgMCAyNSAwQzExLjE5MjkgMCAwIDExLjE5MjkgMCAyNUMwIDM4LjgwNzEgMTEuMTkyOSA1MCAyNSA1MFoiIGZpbGw9ImJsYWNrIi8+CjxsaW5lIHgxPSIyNC45ODExIiB5MT0iMTAiIHgyPSIyNC45ODExIiB5Mj0iNDEiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS41Ii8+CjxsaW5lIHgxPSI0MC41IiB5MT0iMjQuOTk5OCIgeDI9IjkuNSIgeTI9IjI0Ljk5OTgiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS41Ii8+Cjwvc3ZnPgo=')
      15 15, move;
      
    transition: all 0.3s;

    &:hover {
      transform: scale(1.05);
    }
  }

  &__modal {
    $self: &;
    align-self: center;
    background-color: $color--black;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;

    &:focus {
      outline: none;
    }
    &-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 100;
    }

    &-close-button-container {
      display: flex;
      justify-content: flex-end;
      margin: $spacing--s;

      @include breakpoint-sm {
        margin: $spacing--sm;
      }
    }

    &-close-button {
      @include button-style-reset;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: black;
      transition: all 0.3s linear;
      cursor: pointer;

      &-icon {
        color: $color--beige-dark;
      }

      &:hover {
        background-color: $color--beige-dark;
      }

      &:hover &-icon {
        color: $color--black;
      }
    }
  }
}
