.list-with-icons-block {
  $self: &;

  @include full-width-block;
  background: $color--pink-light;
  padding: 3.5rem 0;

  @include breakpoint-ms {
    padding: 5rem 0;
  }

  &__wrapper {
    max-width: $content-max-width;
    margin: 0 auto;

    @include content-wrapper-padding;
  }

  &__title {
    text-align: center;
    margin-bottom: $spacing--m;

    @include breakpoint-ms {
      text-align: left;
    }
  }

  &__items {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 2.5rem;
    padding: 0;
    margin: 0;

    @include breakpoint-ms {
      flex-direction: row;
      align-items: flex-start;
      gap: 5rem;
    }
  }

  &__item {
    text-align: center;
    max-width: 314px;

    &-image {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 140px;
      .image {
        max-height: 100%;
      }

      img.image {
        object-fit: contain;
      }
    }

    &-text-content {
      flex: 1;
    }

    &-title {
      @include responsive-font-size(23px, 25px);
      margin-bottom: $spacing--xs;
      line-height: 1.4;
      font-weight: 500;
    }

    &-text {
      @include responsive-font-size(16px, 18px);
      font-weight: 400;
      line-height: 1.4;
    }
  }
}
