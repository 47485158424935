.text {
  line-height: 1.83;
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &--color-white {
    color: white;
  }

  &--color-black {
    color: $color--black;
  }

  &--color-red {
    color: $color--red;
  }

  &--color-slate {
    color: $color--slate;
  }

  &--color-green-dark {
    color: $color--green-dark;
  }

  &--theme-ingress {
    @include responsive-font-size(20px, 30px);
    font-weight: 200;
    line-height: 1.45;
  }

  &--theme-ingress-small {
    font-weight: 200;
    font-size: convert-to-rem(20px);
    line-height: 1.6;
  }

  &--theme-uppercase-condensed {
    font-size: convert-to-rem(16px);
    font-family: $font-family-secondary;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.125em;
  }
}
