.inspo-article-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: $spacing--m;

  @include breakpoint-m {
    flex-direction: row;
  }

  &__content {
    margin-top: $spacing--s;
    font-weight: 300;
    line-height: 1.45;
    @include responsive-font-size(20px, 30px);

    @include breakpoint-s {
      margin-top: $spacing--sm;
      flex: 0 0 50%;
    }
  }

  &__dropcap {
    background-color: $color--pink;
    color: $color--green-dark;
    display: flex;
    float: left;
    line-height: 1.25;
    justify-content: center;
    font-size: 40px;
    width: 50px;
    height: 50px;
    margin-right: 13px;

    @include breakpoint-s {
      font-size: 80px;
      width: 86px;
      height: 86px;
      line-height: 1.05;
    }
  }

  &__ingress {
    margin-top: 25px;

    @include breakpoint-s {
      margin-top: 45px;
    }
  }

  &__estimated-reading-time{
    margin-top: 25px;
  }

  &__date,
  &__byline {
    font-size: 16px;
    font-weight: 500;
    font-family: 'National2Condensed';
    color: $color--slate;
  }

  &__date {
    margin-top: $spacing--s;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 2px;

    @include breakpoint-s {
      margin-top: $spacing--sm;
    }
  }

  &__byline-container {
    margin-top: $spacing--xs;
  }

  &__byline {
    letter-spacing: 1px;
    display: flex;
    margin-right: $spacing--sm;

    &:last-child {
      margin-right: 0;
    }

    &-label {
      margin-right: 5px;
    }
  }
}
