.teasers-carousel-block {
  $self: &;
  width: 100%;
  position: relative;
  padding: 0 40px;

  &--one-item {
    #{$self}__button {
      display: none;
    }
  }

  &--two-items {
    #{$self}__button {
      @include breakpoint-sm {
        display: none;
      }
    }
  }

  &--three-items {
    #{$self}__button {
      @include breakpoint-m {
        display: none;
      }
    }
  }

  &__button {
    position: absolute;
    display: block;
    width: 18px;
    height: 18px;
    border: 2px solid $color--green-dark;
    background: none;
    border-top: none;
    border-left: none;
    transition: all 0.3s linear;
    top: 50%;
    margin-top: -9px;
    z-index: 10;
    cursor: pointer;
    scale: 1;
    translate: 0;

    @include breakpoint-s {
      width: 35px;
      height: 35px;
      margin-top: -14px;
    }

    &--next {
      transform: rotate(-45deg);
      right: -2px;

      @include breakpoint-s {
        right: -20px;
      }
    }

    &--prev {
      transform: rotate(135deg);
      left: -2px;

      @include breakpoint-s {
        left: -20px;
      }
    }

    &:hover,
    &:focus-visible {
      scale: 1.3;
    }
  }

  &__items {
  }

  &__item {
    margin: 0 10px;
    min-height: 100%;

    @media all and (min-width: 1024px) {
      position: relative;

      &:before,
      &:after {
        position: absolute;
        z-index: 10;
        top: 0;
        right: 0;
        content: '';
        opacity: 0;
        transition: opacity 0.3s;

        #{$self}--red & {
          background-color: $color--red-strong;
        }

        #{$self}--yellow & {
          background-color: $color--green-dark;
        }

        #{$self}--green & {
          background-color: $color--yellow;
        }
      }

      &:before {
        width: 87px;
        height: 32px;
      }

      &:after {
        width: 32px;
        height: 87px;
      }

      #{$self}--three-items .slick-slide.slick-current + .slick-slide & {
        &:before,
        &:after {
          opacity: 1;
        }
      }
    }
  }

  .slick-track {
    display: flex !important;
    margin: 0 auto;
  }

  .slick-slide {
    height: inherit !important;

    & > div {
      min-height: 100%;
      display: flex;
    }
  }
}
