.skip-nav-link {
  position: absolute;
  left: -999em;
  clip: rect(0, 0, 0, 0);
  opacity: 0;
  overflow: hidden;

  &:focus {
    left: auto;
    top: auto;
    width: 30%;
    z-index: 999;
    opacity: 1;
    clip: auto;
    margin: 10px 35%;
    text-align: center;
    font-size: 1.2em;
    color: #262626;
    padding: 0.9375rem;
    background-color: #ff8367;
    text-transform: uppercase;
    text-decoration: none;
  }
}
