.housing-project-iframe-block {
  @include housing-project-block-wrapper;
  &--dark {
    color: $color--beige-dark;
  }

  &__title,
  &__description {
    text-align: center;
  }

  &__title {
    margin-bottom: 5px;
  }

  &__description {
    margin-bottom: $spacing--m;
    font-size: 20px;
    font-weight: 500;

    @include breakpoint-sm {
      font-weight: 400;
    }
  }

  &__iframe {
    width: 100%;
    height: 350px;
    border: none;

    @include breakpoint-sm {
      height: 600px;
    }
  }
}
