.event-page {
  &__metadata {
    margin-top: $spacing--m;
  }

  &__ingress,
  &__link {
    margin-top: $spacing--xl;
  }

  &__link {
    margin-bottom: $spacing--xl;
  }

  &__header {
    margin-top: $spacing--s !important;

    @include breakpoint-m {
      margin-top: $spacing--page-top !important;
      margin-top: $spacing--xxl !important;
    }

    &-title {
      width: calc(100% - #{$corner-width-m} + #{$spacing--l});
      @include breakpoint-m {
        width: 100%;
      }
    }
  }

  &__date-wrapper {
    position: relative;
    margin-top: $spacing--xl;
    padding-right: $spacing--m;

    @include breakpoint-s {
      margin-top: $spacing--page-top;
      padding-right: 0;
    }

    @include breakpoint-m {
      margin-top: $spacing--xxl;
    }
  }

  &__date {
    width: 100%;
    margin-top: $spacing--xxl;

    @include breakpoint-m {
      position: absolute;
      margin-top: $spacing--m;
    }
  }
}
