

.related-articles-carousel {
  $self: &;

  overflow: hidden;
  padding: $spacing--m 0;
  margin-left: $spacing--s;
  margin-right: $spacing--s;

  @include breakpoint-s {
    margin-left: $spacing--l;
    margin-right: $spacing--l;
  }

  @include breakpoint-m {
    margin-left: $spacing--xxl;
    margin-right: $spacing--xxl;
    padding: $spacing--m 0 $spacing--l;
  }

  &__housing-project-article-wrapper {
    @include housing-project-block-wrapper;
  }

  &__slides-container {
    opacity: 0;
    transform: translateX(20vh);
    overflow: hidden;
    transition: opacity 1s ease-out, transform 1s ease-out;
    will-change: opacity;

    &--visible {
      opacity: 1;
      transform: none;
    }
  }

  &__slides {
    position: relative;
    padding-top: $spacing--m;
  }

  &__header {
    display: flex;

    justify-content: space-between;
    flex-direction: column;

    @include breakpoint-m {
      flex-direction: row;
    }
  }

  &__title-container {
    opacity: 0;
    transform: translateX(10vh);
    visibility: hidden;
    overflow: hidden;
    transition: opacity 0.6s ease-out, transform 0.8s ease-out;
    will-change: opacity, visibility;

    &--visible {
      opacity: 1;
      transform: none;
      visibility: visible;
    }
  }

  &__title {

    #{$self}--dark &,
    #{$self}--green & {
      color: $color--beige-light;
    }
  }

  &__link {
    text-decoration: none;

    font-size: convert-to-rem(18px);
    color: $color--slate;
    text-transform: uppercase;
    font-weight: 400;
    margin-top: $spacing--xs;
    cursor: pointer;
    display: inline-flex;
    align-items: center;

    @include underline-animation(
        expand,
        '.related-articles-carousel__link-text'
    );

    &-icon {
      rotate: 270deg;
      margin: 6px;
    }
  }

  &__navigation-wrapper {
    padding: 0.5rem 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
  }

  &__navigation-counter {
    font-family: $font-family-secondary;
    display: block;

    @include breakpoint-m {
      display: none;
    }
  }

  &__navigation-button {
    @include button-style-reset;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    top: 0px;
    height: 100%;
    z-index: 100;
    color: $color--slate;
    width: fit-content;
    cursor: pointer;
    background-color: white;
    margin: 0;
    padding: 0;

    &:after {
      content: none;
    }

    @include breakpoint-m {
      position: absolute;
    }

    &__icon-container {
      height: 100px;
      display: flex;
      justify-items: center;

      @include breakpoint-m {
        height: 200px;
      }
    }

    &__icon {
      width: 25px;
      @include breakpoint-ms {
        width: 45px;
      }

      &__prev {
        transform: rotate(180deg);
        margin-right: 10px;
        @include breakpoint-ms {
          margin-right: 20px;
        }
      }
      &__next {
        margin-left: 10px;
        @include breakpoint-ms {
          margin-left: 20px;
        }
      }
    }
  }

  &__slide-link {
    text-decoration: none;
    @include underline-animation(
      expand,
      '.related-articles-carousel__slide-title-text'
    );
  }

  &__slide-image-container {
    height: 200px;
    width: auto;
    position: relative;

    &::before {
      content: '';
      width: 100%;
      padding: 70% 0 0;
      display: block;
    }

    &--circle {
      margin: auto;
      width: 60%;
      .image__background-wrapper {
        border-radius: 50%;
      }
      &::before {
        content: '';
        width: 50%;
        padding: 50% 0;
        display: block;
      }
    }
  }

  &__slide-tag {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 2px;
    font-family: $font-family-secondary;
    font-size: convert-to-rem(16px);
    line-height: 1.428;
    color: $color--slate;
    margin: 24px 0 0;
  }

  &__slide-title {
    @include responsive-font-size(22px, 22px);
    margin: 24px 0 0;
    line-height: 1.4;
    &--circle {
      margin-top: 16%;

      @include breakpoint-m {
        margin-top: 14%;
      }
    }
    &-text {
      color: $color--black;
      font-size: convert-to-rem(25px);
      line-height: inherit;
      font-weight: 300;

      @include breakpoint-m {
        font-size: convert-to-rem(25px);
      }

      background-image: linear-gradient(
        $color--grey-green,
        $color--grey-green
      ) !important;

      #{$self}--dark & {
        color: $color--grey-green;
      }
    }
  }

  //Override default swiper styles

  .swiper-container {
  }

  .swiper-pagination {
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    @include breakpoint-m {
      opacity: 0;
    }
  }

  .swiper-slide-prev {
    margin-left: 55px;

    @include breakpoint-ms {
      margin-left: 70px;
    }
  }

  .swiper-slide-next {
    margin-right: 55px;

    @include breakpoint-ms {
      margin-right: 70px;
    }
  }
}

.swiper {
  overflow: hidden;
  transition: all 100ms ease-in-out;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 225px;
  transition: margin-left ease-in-out 100ms;

  @include breakpoint-ms {
    width: 250px;
  }
}
