.drawer {
  position: fixed;
  z-index: 100;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: white;
  box-shadow: 0 0 350px 0 rgba(black, 0.6);

  @include breakpoint-m {
    height: auto;
    max-height: 100%;
    width: calc($content-max-width / 2);
  }

  &__close {
    @include button-style-reset;
    position: absolute;
    top: $spacing--s;
    right: $spacing--s;
    padding: 0;
    background-color: transparent;
  }
}
